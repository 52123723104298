import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../firebase';

export function useUserData() {
  const [stats, setStats] = useState({
    invoicesCount: 0,
    agreementsCount: 0,
    clientsCount: 0
  });

  const [balance, setBalance] = useState({
    available: 0,
    pending: 0
  });

  const [documents, setDocuments] = useState({
    invoices: [],
    agreements: []
  });

  useEffect(() => {
    const user = auth.currentUser;
    if (!user?.uid) return;

    const unsubscribers = [];
    const collections = {
      invoices: query(collection(db, 'invoices'), where('userId', '==', user.uid)),
      clients: query(collection(db, `users/${user.uid}/clients`)),
      agreements: query(collection(db, 'agreements'), where('userId', '==', user.uid)),
      balance: query(collection(db, `users/${user.uid}/balance`))
    };

    Object.entries(collections).forEach(([key, query]) => {
      const unsubscribe = onSnapshot(query, snapshot => {
        switch (key) {
          case 'invoices':
            setStats(prev => ({ ...prev, invoicesCount: snapshot.size }));
            setDocuments(prev => ({
              ...prev, 
              invoices: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            }));
            break;
          case 'clients':
            setStats(prev => ({ ...prev, clientsCount: snapshot.size }));
            break;
          case 'agreements':
            setStats(prev => ({ ...prev, agreementsCount: snapshot.size }));
            setDocuments(prev => ({
              ...prev, 
              agreements: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            }));
            break;
          case 'balance':
            if (snapshot.docs.length > 0) {
              const balanceData = snapshot.docs[0].data();
              setBalance({
                available: balanceData.available || 0,
                pending: balanceData.pending || 0
              });
            }
            break;
        }
      }, error => {
        console.error(`Error in ${key} subscription:`, error);
      });
      unsubscribers.push(unsubscribe);
    });

    return () => unsubscribers.forEach(unsubscribe => unsubscribe());
  }, []);

  return { stats, documents, balance };
}
