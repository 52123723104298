import React, { useState } from 'react';
import { motion } from 'framer-motion';

export function AcceptModal({ booking, onClose, onAccept }) {
  const [sendEmail, setSendEmail] = useState(true);
  const [emailMessage, setEmailMessage] = useState(
    `Hi ${booking.name},\n\nThank you for your booking! I'm excited to work with you on this project.\n\nI'll be in touch shortly with next steps.\n\nBest regards`
  );
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center p-4 z-50"
    >
      <div className="bg-[#111111] rounded-3xl p-6 w-full max-w-md border border-[#1A1A1A]">
        <h3 className="text-2xl text-white mb-4">Accept Booking</h3>
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="sendEmail"
              checked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
              className="rounded text-[#FFB27D]"
            />
            <label htmlFor="sendEmail" className="text-[#999999]">
              Send confirmation email
            </label>
          </div>
          {sendEmail && (
            <textarea
              value={emailMessage}
              onChange={(e) => setEmailMessage(e.target.value)}
              rows={6}
              className="w-full rounded-xl bg-[#1A1A1A] border-0 p-4 text-white placeholder-[#999999] focus:ring-2 focus:ring-[#FFB27D]"
            />
          )}
        </div>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 text-[#999999] hover:bg-[#1A1A1A] rounded-xl"
          >
            Cancel
          </button>
          <motion.button
            onClick={() => onAccept(sendEmail, emailMessage)}
            className="px-4 py-2 bg-[#FFB27D] text-black rounded-xl hover:bg-[#FFB27D]/90 transition-colors shadow-lg shadow-[#FFB27D]/20"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Accept Booking
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
}

export function DeclineModal({ booking, onClose, onDecline }) {
  const [sendEmail, setSendEmail] = useState(true);
  const [reason, setReason] = useState('');
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center p-4 z-50"
    >
      <div className="bg-[#111111] rounded-3xl p-6 w-full max-w-md border border-[#1A1A1A]">
        <h3 className="text-2xl text-white mb-4">Decline Booking</h3>
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="sendEmail"
              checked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
              className="rounded text-[#FFB27D]"
            />
            <label htmlFor="sendEmail" className="text-[#999999]">
              Send decline notification
            </label>
          </div>
          {sendEmail && (
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Optional: Provide a reason for declining"
              rows={6}
              className="w-full rounded-xl bg-[#1A1A1A] border-0 p-4 text-white placeholder-[#999999] focus:ring-2 focus:ring-[#FFB27D]"
            />
          )}
        </div>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 text-[#999999] hover:bg-[#1A1A1A] rounded-xl"
          >
            Cancel
          </button>
          <motion.button
            onClick={() => onDecline(sendEmail, reason)}
            className="px-4 py-2 bg-[#FFB27D] text-black rounded-xl hover:bg-[#FFB27D]/90 transition-colors shadow-lg shadow-[#FFB27D]/20"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Decline Booking
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
} 