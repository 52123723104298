import { useState, useRef, useEffect, useContext } from 'react';
import { motion, useAnimation, useScroll, useTransform, useSpring, useInView } from 'framer-motion';
import Footer from '../components/Footer';
import { 
  auth, 
  GoogleAuthProvider, 
  signInWithPopup, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword 
} from '../firebase';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../App';

const DemoSection = ({ children, title, subtitle, reverse }) => (
  <div className={`flex flex-col ${reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center gap-8 lg:gap-12`}>
    <div className="flex-1 w-full lg:w-auto">
      <motion.h2 
        className="font-inter text-3xl sm:text-4xl lg:text-5xl text-gray-900 dark:text-brown-900 leading-tight mb-4 text-center lg:text-left"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        {title}
      </motion.h2>
      <motion.p 
        className="font-sans text-lg sm:text-xl text-gray-600 dark:text-slate-600 leading-relaxed text-center lg:text-left"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.2 }}
      >
        {subtitle}
      </motion.p>
    </div>
    <motion.div 
      className="flex-1 w-full lg:w-auto"
      initial={{ opacity: 0, x: reverse ? -20 : 20 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ delay: 0.3 }}
    >
      {children}
    </motion.div>
  </div>
);

const HeroText = ({ children }) => (
  <motion.span
    className="relative inline-block"
    whileHover={{ scale: 1.02 }}
  >
    <span className="relative z-10">{children}</span>
    <motion.div
      className="absolute bottom-2 left-0 right-0 h-3 bg-[#E6A15E]/30 -skew-x-6"
      initial={{ width: 0 }}
      whileInView={{ width: '100%' }}
      viewport={{ once: true }}
    />
  </motion.span>
);

const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    className="relative group w-full"
    whileHover={{ y: -8 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-gray-100 to-gray-200/50 dark:from-[#1A1A1A] dark:to-black/50 rounded-3xl transform group-hover:scale-105 transition-transform duration-300" />
    <div className="relative bg-white dark:bg-[#1A1A1A] rounded-3xl p-6 sm:p-8 shadow-lg shadow-black/5 border border-gray-200 dark:border-[#4A4A4A]">
      <span className="text-3xl sm:text-4xl mb-4 block">{icon}</span>
      <h3 className="font-space-grotesk text-lg sm:text-xl text-gray-900 dark:text-white mb-2 uppercase">{title}</h3>
      <p className="font-playfair text-gray-600 dark:text-[#E6E6E6] text-sm sm:text-base">{description}</p>
    </div>
  </motion.div>
);

const ThemeToggle = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeChange = () => {
    if (theme === 'dark') {
      setTheme('light');
    } else if (theme === 'light') {
      setTheme('system');
    } else {
      setTheme('dark');
    }
  };

  return (
    <button
      onClick={handleThemeChange}
      className="fixed bottom-4 left-4 z-50 bg-white/30 dark:bg-black/40 backdrop-blur-lg px-4 py-2.5 rounded-xl text-sm font-medium flex items-center gap-2 transition-all hover:bg-white/40 dark:hover:bg-white/20 border border-gray-200/20 dark:border-white/10 shadow-lg"
    >
      {theme === 'dark' && (
        <>
          <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
            <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
          </svg>
          <span className="text-white">Dark</span>
        </>
      )}
      {theme === 'light' && (
        <>
          <svg className="w-4 h-4 text-black" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
          </svg>
          <span className="text-black">Light</span>
        </>
      )}
      {theme === 'system' && (
        <>
          <svg className="w-4 h-4 text-gray-900 dark:text-white" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clipRule="evenodd" />
          </svg>
          <span className="text-gray-900 dark:text-white">System</span>
        </>
      )}
    </button>
  );
};

export default function LandingPage() {
  const [isAnnual, setIsAnnual] = useState(true);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [docType, setDocType] = useState('invoice');
  const [demoInvoice] = useState({
    number: '1234',
    client: 'TechVision Studios',
    project: 'Website Redesign',
    amount: 5000,
    dueDate: '2024-03-15'
  });

  const [suggestions, setSuggestions] = useState([
    "Add milestone payment schedule",
    "Include project deliverables", 
    "Specify payment terms"
  ]);

  // Animation controls and refs
  const controls = useAnimation();
  const containerRef = useRef(null);
  const demoRef = useRef(null);
  
  // Setup scroll progress
  const { scrollYProgress } = useScroll({
    target: demoRef,
    offset: ["start end", "end start"]
  });

  // Scroll-based animations
  const bgOpacity = useTransform(scrollYProgress, [0, 0.2, 0.4, 0.6, 0.8], [0.2, 0.4, 0.6, 0.8, 1]);
  const scale = useTransform(scrollYProgress, [0, 0.2, 0.4, 0.6, 0.8], [1, 1.05, 1.1, 1.15, 1.2]);
  const y = useTransform(scrollYProgress, [0, 1], [0, -50]);

  // Spring animations for smoother transitions
  const scaleSpring = useSpring(scale, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });
   
  // Setup intersection observer for each section
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.2, triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.2, triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.2, triggerOnce: true });
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 0.2, triggerOnce: true });

  // Text animation variants
  const textVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const letterVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const handleDocTypeChange = (type) => {
    setDocType(type);
    if (type === 'agreement') {
      setSuggestions([
        "Add non-disclosure clause",
        "Specify revision rounds",
        "Include cancellation terms"
      ]);
    } else {
      setSuggestions([
        "Add milestone payment schedule",
        "Include project deliverables",
        "Specify payment terms"
      ]);
    }
  };

  const [columns, setColumns] = useState({
    backlog: {
      title: 'Backlog',
      items: [
        { id: '1', title: 'Website Redesign', tasks: 3 },
        { id: '2', title: 'Brand Identity', tasks: 5 }
      ]
    },
    inProgress: {
      title: 'In Progress',
      items: []
    },
    completed: {
      title: 'Completed',
      items: []
    }
  });

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const { source, destination } = result;
    
    if (source.droppableId === destination.droppableId) {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    } else {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    }
  };

  // Add state for mobile menu and demo view
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeMobileView, setActiveMobileView] = useState('list'); // 'list' or 'content'

  // Add font imports
  useEffect(() => {
    // Load Google Fonts
    const link1 = document.createElement('link');
    link1.href = 'https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap';
    link1.rel = 'stylesheet';
    
    const link2 = document.createElement('link');
    link2.href = 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap';
    link2.rel = 'stylesheet';
    
    document.head.appendChild(link1);
    document.head.appendChild(link2);
  }, []);

  const [activeDemo, setActiveDemo] = useState('video');
  
  // Add demo email data
  const demoEmails = [
    {
      id: 1,
      sender: 'elivnatwine@gmail.com',
      tag: null,
      time: '2025-02-22',
      subject: 'Inquiry About Your Wedding Photography – Absolutely Blown Away',
      preview: 'Hey Elvin, I hope you\'re doing amazing! I just wanted to take...',
      starred: true
    },
    {
      id: 2,
      sender: 'Stripe Support <support@stripe.com>',
      tag: null,
      time: '2024-12-12',
      subject: 'Re: Request-a-call from Elvin Atwine (klorahcreative@gmail.com)',
      preview: 'Hi Elvin, Thanks for reaching back. Apologies for the confusi...',
      starred: false
    },
    {
      id: 3,
      sender: 'Stripe Support <support@stripe.com>',
      tag: null,
      time: '2024-12-09',
      subject: 'Re: Request-a-call from Elvin Atwine (klorahcreative@gmail.com)',
      preview: 'Hi Elvin, Thanks for your patience. Your platform account ac...',
      starred: false
    },
    {
      id: 4,
      sender: 'Adobe Creative Cloud <mail@adobe.com>',
      tag: null,
      time: '2024-12-08',
      subject: 'Your Adobe Creative Cloud subscription is due for renewal',
      preview: 'Your Creative Cloud subscription will renew on December 15...',
      starred: false
    },
    {
      id: 5,
      sender: 'Squarespace <no-reply@squarespace.com>',
      tag: null,
      time: '2024-12-07',
      subject: 'Your website analytics for December',
      preview: 'Here\'s how your photography portfolio performed this mont...',
      starred: true
    },
    {
      id: 6,
      sender: 'Wedding Client <sarah.j@gmail.com>',
      tag: null,
      time: '2024-12-06',
      subject: 'Re: Wedding Photo Selection',
      preview: 'Hi Elvin, We\'ve gone through the photos and they\'re absolut...',
      starred: true
    }
  ];

  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError('');
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/app');
    } catch (error) {
      setError('Failed to sign in with Google: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      navigate('/app');
    } catch (error) {
      setError(isSignUp ? 'Failed to create account: ' : 'Failed to sign in: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetStarted = () => {
    setShowAuthModal(true);
  };

  return (
    <div className="w-full bg-[#FFFAF2] dark:bg-black text-gray-900 dark:text-white min-h-screen overflow-x-hidden" ref={containerRef}>
      {/* Video Modal */}
      {isVideoModalOpen && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/95 dark:bg-black/95 z-50 flex items-center justify-center p-4"
        >
          <div className="relative w-full max-w-4xl mx-auto">
            <button 
              onClick={() => setIsVideoModalOpen(false)}
              className="absolute -top-12 right-0 text-white/80 hover:text-white"
            >
              <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="relative pt-[56.25%] w-full bg-black rounded-lg overflow-hidden">
              <iframe
                className="absolute inset-0 w-full h-full"
                src="https://www.youtube.com/embed/0etlJDNV3_M?autoplay=1"
                title="Demo Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </motion.div>
      )}

      {/* Auth Modal */}
      {showAuthModal && (
        <div className="fixed inset-0 backdrop-blur-xl flex items-center justify-center p-4 z-50">
          <div className="bg-[#FDFBF7]/90 dark:bg-[#0A0A0A]/90 backdrop-blur-sm rounded-[32px] p-10 max-w-md w-full border border-gray-200 dark:border-white/5 shadow-2xl">
            <div className="flex justify-between items-center mb-10">
              <h2 className="font-space-grotesk text-3xl text-gray-900 dark:text-white font-light tracking-tight">{isSignUp ? 'Join Klorah' : 'Welcome Back'}</h2>
              <button onClick={() => setShowAuthModal(false)} className="text-gray-500 dark:text-white/40 hover:text-gray-900 dark:hover:text-white transition-all">
                <svg className="w-7 h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <button
              onClick={handleGoogleSignIn}
              disabled={loading}
              className="w-full bg-gray-200 dark:bg-white/0 hover:bg-gray-300 dark:hover:bg-white/10 text-gray-900 dark:text-white py-4 rounded-2xl mb-8 flex items-center justify-center gap-3 transition-all duration-300 border border-gray-200 dark:border-white/10 hover:border-gray-300 dark:hover:border-white/20"
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"/>
              </svg>
              Continue with Google
            </button>

            <div className="relative mb-8">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200 dark:border-white/10"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-6 bg-gray-200 dark:bg-black text-gray-500 dark:text-white/40 tracking-wide">or continue with email</span>
              </div>
            </div>

            <form onSubmit={handleEmailAuth} className="space-y-5">
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-6 py-4 bg-gray-200 dark:bg-black text-gray-900 placeholder-gray-500 focus:border-[#E6A15E] focus:ring-1 focus:ring-[#E6A15E]/25 transition-all duration-300"
                  required
                />
              </div>
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}s
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-6 py-4 bg-gray-200 dark:bg-black text-gray-900 placeholder-gray-500 focus:border-[#E6A15E] focus:ring-1 focus:ring-[#E6A15E]/25 transition-all duration-300"
                  required
                />
              </div>
              {error && <p className="text-red-400/90 text-sm font-light">{error}</p>}
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-[#E6A15E] text-black py-4 rounded-2xl font-medium transition-all duration-300 mt-2"
              >
                {loading ? 'Processing...' : isSignUp ? 'Create Account' : 'Sign In'}
              </button>
            </form>

            <p className="mt-8 text-center text-sm text-gray-500 dark:text-white/40">
              {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
              <button
                onClick={() => setIsSignUp(!isSignUp)}
                className="text-[#E6A15E] hover:text-[#E6A15E]/80 transition-colors"
              >
                {isSignUp ? 'Sign In' : 'Sign Up'}
              </button>
            </p>
          </div>
        </div>
      )}

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-gray-900/90 dark:bg-black/90 z-50 lg:hidden"
        >
          <div className="p-4">
            <div className="flex justify-between items-center mb-8">
              <h1 className="font-space-grotesk text-2xl text-gray-100 dark:text-[#E6E6E6] tracking-tight">K L O R A H</h1>
              <button 
                onClick={() => setIsMobileMenuOpen(false)}
                className="text-gray-100 dark:text-[#E6E6E6]"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <nav className="space-y-4">
              <button 
                onClick={handleGetStarted}
                className="w-full bg-[#E6A15E] text-black px-6 py-3 rounded-lg font-space-grotesk font-medium mt-4"
              >
                GET STARTED
              </button>
            </nav>
          </div>
        </motion.div>
      )}

      {/* Header - Mobile Responsive */}
      <header className="fixed top-0 left-0 right-0 z-40 backdrop-blur-md bg-[#FDFBF7]/20 dark:bg-black/20">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="font-space-grotesk text-2xl text-gray-900 dark:text-[#E6E6E6] tracking-tight">K L O R A H</h1>
          <div className="hidden md:flex items-center gap-8">
            {/* <button 
              onClick={() => document.getElementById('features').scrollIntoView({ behavior: 'smooth' })}
              className="text-[#E6E6E6] hover:text-[#E6A15E] transition-colors"
            >
              Features
            </button>
            <button 
              onClick={() => document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' })}
              className="text-[#E6E6E6] hover:text-[#E6A15E] transition-colors"
            >
              Pricing
            </button> */}
          </div>
          <div className="flex items-center gap-4">
            <motion.button 
              onClick={() => setShowAuthModal(true)}
              className="hidden md:block bg-[#E6A15E] text-black px-6 py-2 rounded-lg font-space-grotesk font-medium"
              whileHover={{ scale: 1.05 }}
            >
              GET STARTED
            </motion.button>
            <button 
              onClick={() => setIsMobileMenuOpen(true)}
              className="lg:hidden text-[#E6E6E6]"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="pt-24 md:pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4">
          {/* Hero Section - Mobile Responsive */}
          <motion.div className="mb-16 md:mb-32">
            <div className="flex flex-col items-center mb-8">
              <img 
                src="/klorah-icon.png"
                alt="Klorah Icon"
                className="w-6 h-6 md:w-12 md:h-12 mb-2"
              />
              <motion.h2 
                className="font-space-grotesk text-5xl sm:text-6xl md:text-7xl lg:text-8xl text-center mb-4 md:mb-6 tracking-tighter leading-[1.1] text-gray-900 dark:text-white"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                No More
                <br />
                Email <span className="text-[#E6A15E] italic font-light">Chaos</span>
              </motion.h2>
            </div>

            <motion.p
              className="text-[#E6A15E]/90 text-base sm:text-xl md:text-2xl text-center max-w-3xl mx-auto mb-2 md:mb-4 leading-relaxed font-light tracking-wide"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              Take control of your business workflow
            </motion.p>

            <motion.p
              className="text-gray-600 dark:text-[#E6E6E6]/80 text-sm sm:text-base md:text-lg text-center max-w-2xl mx-auto mb-6 md:mb-10 leading-relaxed font-extralight tracking-wide"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              with AI that automates client communications, invoicing, and agreements. 
              It's time to work smarter—not harder.
            </motion.p>

            <motion.div
              className="flex flex-col sm:flex-row items-center justify-center gap-3 sm:gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <button
                onClick={handleGetStarted}
                className="w-full sm:w-auto bg-[#E6A15E] text-black px-5 sm:px-8 py-2 sm:py-3 rounded-lg sm:rounded-xl font-space-grotesk text-base tracking-wide min-w-[180px] sm:min-w-[200px] hover:bg-[#E6A15E]/90 transition-all duration-300 transform hover:translate-y-[-2px] hover:shadow-lg"
              >
                CONNECT GMAIL
              </button>
              <button
                onClick={() => setIsVideoModalOpen(true)}
                className="w-full sm:w-auto bg-[#1A1A1A] text-[#E6E6E6] px-5 sm:px-8 py-2 sm:py-3 rounded-lg sm:rounded-xl font-space-grotesk text-base tracking-wide min-w-[180px] sm:min-w-[200px] hover:bg-[#1A1A1A]/80 transition-all duration-300 transform hover:translate-y-[-2px] border border-[#4A4A4A] flex items-center justify-center gap-3 hover:border-[#E6A15E]/30"
              >
                <svg className="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Watch Demo
              </button>
            </motion.div>
          </motion.div>

          {/* Enhanced Demo Section - Mobile Responsive */}
          <motion.div 
            id="demo"
            ref={demoRef}
            className="relative mt-4 md:mt-8 mx-2 sm:mx-4 md:mx-6"
            style={{ 
              scale: scaleSpring,
              y
            }}
          >
            <div className="bg-[#FDFBF7] dark:bg-[#0A0A0A] rounded-3xl overflow-hidden border border-gray-200 dark:border-[#2A2A2A] relative z-10 shadow-[0_20px_100px_20px_rgba(230,161,94,0.3)]">
              {/* App Header - Enhanced Mobile Design */}
              <div className="bg-[#F5F3EF] dark:bg-black px-4 md:px-6 py-3 md:py-4 border-b border-gray-200 dark:border-[#2A2A2A]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2 md:gap-3">
                    <span className="text-gray-900 dark:text-[#E6E6E6] font-space-grotesk font-medium tracking-wider text-sm md:text-base">K L O R A H</span>
                    <span className="px-2 py-0.5 text-xs bg-[#E6A15E]/10 dark:bg-[#333333] text-[#E6A15E] rounded">PRO</span>
                  </div>
                  <div className="flex items-center gap-3 md:gap-3">
                    <p className="hidden md:block text-gray-500 dark:text-[#E6E6E6]/40 text-xs italic">Your hustle today builds tomorrow's success</p>
                    <div className="flex items-center gap-2">
                      <div className="w-7 h-7 md:w-8 md:h-8 rounded-full bg-[#E6A15E]/10 dark:bg-[#333333] flex items-center justify-center text-[#E6A15E] font-medium text-sm">E</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* App Content - Enhanced Mobile Layout */}
              <div className="flex flex-col lg:flex-row">
                {/* Sidebar - Hidden on Mobile */}
                <div className="hidden lg:block w-64 bg-[#F5F3EF]/50 dark:bg-black/80 border-r border-gray-200 dark:border-[#2A2A2A] p-4">
                  <div className="mb-8">
                    <div className="bg-white/50 dark:bg-black/60 rounded-xl px-4 py-2.5">
                      <input
                        type="text"
                        placeholder="Search emails..."
                        className="w-full bg-transparent text-gray-900 dark:text-[#E6E6E6] placeholder-gray-400 dark:placeholder-[#E6E6E6]/30 outline-none text-sm"
                      />
                    </div>
                  </div>
                  <div className="space-y-1.5">
                    <div className="flex items-center justify-between px-4 py-2.5 bg-gradient-to-r from-[#E6A15E]/10 to-transparent rounded-xl">
                      <span className="text-[#E6A15E] tracking-wide">WORKSPACE</span>
                    </div>
                    <div className="flex items-center justify-between px-4 py-2.5 text-gray-500 dark:text-[#E6E6E6]/40 hover:bg-white/50 dark:hover:bg-black/40 rounded-xl transition-colors">
                      <span>INVOICES</span>
                      <span className="text-sm">5</span>
                    </div>
                    <div className="flex items-center justify-between px-4 py-2.5 text-gray-500 dark:text-[#E6E6E6]/40 hover:bg-white/50 dark:hover:bg-black/40 rounded-xl transition-colors">
                      <span>AGREEMENTS</span>
                      <span className="text-sm">3</span>
                    </div>
                    <div className="flex items-center justify-between px-4 py-2.5 text-gray-500 dark:text-[#E6E6E6]/40 hover:bg-white/50 dark:hover:bg-black/40 rounded-xl transition-colors">
                      <span>CLIENTS</span>
                      <span className="text-sm">1</span>
                    </div>
                  </div>
                </div>

                {/* Mobile Navigation - Enhanced Design */}
                <div className="lg:hidden flex items-center justify-between p-2 border-b border-gray-200 dark:border-[#2A2A2A] bg-[#F5F3EF] dark:bg-black sticky top-0 z-20">
                  <button 
                    onClick={() => setActiveMobileView('list')}
                    className={`flex-1 px-3 py-2 rounded-md text-xs font-medium transition-all ${activeMobileView === 'list' ? 'bg-[#E6A15E]/10 dark:bg-[#333333] text-[#E6A15E]' : 'bg-transparent dark:bg-black text-gray-500 dark:text-[#E6E6E6]/40'}`}
                  >
                    Inbox
                  </button>
                  <div className="w-2"></div>
                  <button 
                    onClick={() => setActiveMobileView('content')}
                    className={`flex-1 px-3 py-2 rounded-md text-xs font-medium transition-all ${activeMobileView === 'content' ? 'bg-[#E6A15E]/10 dark:bg-[#333333] text-[#E6A15E]' : 'bg-transparent dark:bg-black text-gray-500 dark:text-[#E6E6E6]/40'}`}
                  >
                    Email
                  </button>
                </div>

                {/* Email List - Enhanced Mobile View */}
                <div className={`flex-1 border-r border-gray-200 dark:border-[#2A2A2A] bg-[#F5F3EF] dark:bg-black ${activeMobileView === 'list' ? 'block' : 'hidden'} lg:block`}>
                  {/* Email Filters - More Compact Mobile Design */}
                  <div className="flex items-center px-3 py-1.5 border-b border-gray-200 dark:border-[#2A2A2A] bg-[#F5F3EF] dark:bg-black">
                    <button className="text-gray-500 dark:text-[#E6E6E6]/60 text-xs mr-2">
                      <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                      </svg>
                    </button>
                    <div className="flex gap-2 overflow-x-auto scrollbar-hide py-0.5">
                      <button className="px-2 py-1 bg-[#E6A15E]/10 dark:bg-[#333333] text-[#E6A15E] rounded-sm text-[10px] font-medium whitespace-nowrap">FOCUSED</button>
                      <button className="px-2 py-1 bg-transparent dark:bg-black text-gray-500 dark:text-[#E6E6E6]/40 rounded-sm text-[10px] whitespace-nowrap hover:bg-gray-100 dark:hover:bg-[#1A1A1A] transition-colors">SOCIAL</button>
                      <button className="px-2 py-1 bg-transparent dark:bg-black text-gray-500 dark:text-[#E6E6E6]/40 rounded-sm text-[10px] whitespace-nowrap hover:bg-gray-100 dark:hover:bg-[#1A1A1A] transition-colors">WORK</button>
                      <button className="px-2 py-1 bg-transparent dark:bg-black text-gray-500 dark:text-[#E6E6E6]/40 rounded-sm text-[10px] whitespace-nowrap hover:bg-gray-100 dark:hover:bg-[#1A1A1A] transition-colors">UPDATES</button>
                    </div>
                  </div>

                  {/* Email Items - Enhanced Mobile Design */}
                  <div className="divide-y divide-gray-200 dark:divide-[#2A2A2A]">
                    {demoEmails.map((email) => (
                      <motion.div 
                        key={email.id}
                        className="px-4 py-3 hover:bg-gray-100 dark:hover:bg-[#161616] active:bg-gray-200 dark:active:bg-[#212121] cursor-pointer transition-colors"
                        onClick={() => setActiveMobileView('content')}
                        initial={{ opacity: 0, y: 10 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: email.id * 0.08 }}
                      >
                        <div className="flex items-start justify-between mb-2">
                          <div>
                            <div className="flex items-center mb-0.5">
                              {email.starred && (
                                <svg className="w-4 h-4 text-[#E6A15E] mr-1.5" fill="currentColor" viewBox="0 0 20 20">
                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                              )}
                              <h3 className="text-gray-900 dark:text-[#E6E6E6] font-medium text-sm">{email.subject}</h3>
                            </div>
                            <p className="text-xs text-gray-600 dark:text-[#E6E6E6]/60 mb-2 line-clamp-1">{email.sender}</p>
                            <p className="text-xs text-gray-500 dark:text-[#E6E6E6]/40 line-clamp-2">{email.preview}</p>
                          </div>
                          <div className="text-xs text-gray-500 dark:text-[#E6E6E6]/40 whitespace-nowrap ml-2 mt-0.5">
                            {email.time}
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>

                {/* Email Content - Enhanced Mobile View */}
                <div className={`w-full lg:w-[480px] p-4 md:p-6 bg-[#FDFBF7] dark:bg-black ${activeMobileView === 'content' ? 'block' : 'hidden'} lg:block min-h-screen`}>
                  <div className="flex items-center justify-between mb-6">
                    <h2 className="text-lg font-space-grotesk text-gray-900 dark:text-[#E6E6E6] leading-tight pr-4">Collaboration opportunity - Fintech app redesign</h2>
                    <button 
                      className="text-gray-500 dark:text-[#E6E6E6]/40 lg:hidden p-2 hover:bg-gray-100 dark:hover:bg-black/40 rounded-lg transition-colors"
                      onClick={() => setActiveMobileView('list')}
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                      </svg>
                    </button>
                  </div>

                  <div className="flex items-center gap-4 mb-6">
                    <div className="w-12 h-12 rounded-full bg-gradient-to-br from-[#E6A15E]/20 to-[#FFB27D]/10 flex items-center justify-center">
                      <span className="text-[#E6A15E] text-lg">E</span>
                    </div>
                    <div>
                      <h3 className="text-gray-900 dark:text-[#E6E6E6] font-medium text-base">Ethan Liu</h3>
                      <p className="text-sm text-gray-500 dark:text-[#E6E6E6]/40">ethan.liu@designfirm.co</p>
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-2 mb-8">
                    <button className="flex-1 py-3 bg-white/50 dark:bg-black/60 text-gray-900 dark:text-[#E6E6E6] rounded-xl text-sm flex items-center justify-center gap-2 hover:bg-gray-100 dark:hover:bg-black/40 transition-colors border border-gray-200 dark:border-[#2A2A2A]">
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                      </svg>
                      Reply
                    </button>
                    <button className="flex-1 py-3 bg-white/50 dark:bg-black/60 text-gray-900 dark:text-[#E6E6E6] rounded-xl text-sm flex items-center justify-center gap-2 hover:bg-gray-100 dark:hover:bg-black/40 transition-colors border border-gray-200 dark:border-[#2A2A2A]">
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                      </svg>
                      Forward
                    </button>
                    <button className="w-full py-3 bg-gradient-to-r from-[#E6A15E] to-[#FFB27D] text-black rounded-xl text-sm font-medium shadow-lg">
                      Summarize
                    </button>
                  </div>

                  <div className="prose max-w-none">
                    <p className="text-gray-700 dark:text-[#E6E6E6]/80 leading-relaxed text-base">
                      Hi there,<br /><br />
                      I've been following your work on Dribbble for a while now, and I'm really impressed with your UI/UX approach, especially for financial applications.<br /><br />
                      I'm currently leading a redesign project for a fintech startup that's looking to revamp their investment tracking app. The scope includes:<br /><br />
                      - Redesigning the dashboard with better data visualization<br />
                      - Streamlining the onboarding process<br />
                      - Creating a more intuitive portfolio management interface<br /><br />
                      Would you be interested in discussing this opportunity further?<br /><br />
                      Best regards,<br />
                      Ethan
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Features & Component Grid - Mobile Responsive */}
          <motion.div 
            id="features"
            ref={ref1}
            initial={{ opacity: 0, y: 50 }}
            animate={inView1 ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 md:gap-8 mt-8 mx-2 sm:mx-4 md:mx-6"
          >
            <div className="bg-gray-50 dark:bg-[#1A1A1A] p-6 md:p-8 rounded-2xl border border-gray-200 dark:border-[#4A4A4A]">
              <h3 className="font-space-grotesk text-xl md:text-2xl text-gray-900 dark:text-white mb-4">Features & Pricing</h3>
              <p className="text-gray-600 dark:text-[#E6E6E6] mb-6">Comprehensive solution for managing client responses</p>
              <ul className="space-y-4">
                {[
                  'AI-powered response generation',
                  'Smart templates',
                  'Client management tools',
                  'Professional documents'
                ].map((feature, i) => (
                  <li key={i} className="flex items-center gap-3">
                    <svg className="w-5 h-5 text-[#E6A15E]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-700 dark:text-[#E6E6E6]">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-gray-50 dark:bg-[#1A1A1A] p-6 md:p-8 rounded-2xl border border-gray-200 dark:border-[#4A4A4A]">
              <h3 className="font-space-grotesk text-xl md:text-2xl text-gray-900 dark:text-white mb-4">Component</h3>
              <p className="text-gray-600 dark:text-[#E6E6E6]">Interactive demo of Klorah's features</p>
            </div>
          </motion.div>

          {/* Invoicing Capabilities Section */}
          <motion.div 
            className="py-4 sm:py-6 md:py-8 mx-2 sm:mx-4 md:mx-6"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="text-center mb-6">
              <h2 className="font-space-grotesk text-3xl md:text-4xl lg:text-5xl mb-3 text-gray-900 dark:text-white">
                Professional <span className="text-[#E6A15E]">Invoicing</span>
              </h2>
              <p className="text-gray-600 dark:text-[#E6E6E6]/80 text-lg md:text-xl max-w-2xl mx-auto">
                Generate professional invoices in seconds. Track payments and manage your business finances all in one place.
              </p>
            </div>

            <div className="bg-[#FDFBF7] dark:bg-[#0A0A0A] rounded-3xl overflow-hidden border border-gray-200 dark:border-[#2A2A2A] p-6 md:p-8 shadow-[0_10px_30px_10px_rgba(230,161,94,0.15)]">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8">
                <div className="space-y-4">
                  <div className="bg-white/50 dark:bg-black/60 rounded-2xl p-6 border border-gray-200 dark:border-[#2A2A2A] hover:border-gray-300 dark:hover:border-[#4A4A4A] transition-colors">
                    <h3 className="font-space-grotesk text-xl mb-4 text-[#E6A15E]">Smart Templates</h3>
                    <p className="text-gray-600 dark:text-[#E6E6E6]/80 mb-4 text-sm md:text-base">Choose from professionally designed templates or create your own custom branded invoices.</p>
                    <ul className="space-y-3">
                      {[
                        'Customizable layouts',
                        'Brand color schemes',
                        'Multiple currency support',
                        'Automatic calculations'
                      ].map((feature, i) => (
                        <li key={i} className="flex items-center gap-2.5 text-gray-600 dark:text-[#E6E6E6]/60 text-sm">
                          <div className="w-5 h-5 rounded-full bg-[#E6A15E]/10 flex items-center justify-center">
                            <svg className="w-3.5 h-3.5 text-[#E6A15E]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="bg-white/50 dark:bg-black/60 rounded-2xl p-6 border border-gray-200 dark:border-[#2A2A2A] hover:border-gray-300 dark:hover:border-[#4A4A4A] transition-colors">
                    <h3 className="font-space-grotesk text-xl mb-4 text-[#E6A15E]">Payment Tracking</h3>
                    <p className="text-gray-600 dark:text-[#E6E6E6]/80 mb-4 text-sm md:text-base">Stay on top of your finances with automated payment tracking and reminders.</p>
                    <ul className="space-y-3">
                      {[
                        'Payment status tracking',
                        'Automated reminders',
                        'Payment history',
                        'Financial reports'
                      ].map((feature, i) => (
                        <li key={i} className="flex items-center gap-2.5 text-gray-600 dark:text-[#E6E6E6]/60 text-sm">
                          <div className="w-5 h-5 rounded-full bg-[#E6A15E]/10 flex items-center justify-center">
                            <svg className="w-3.5 h-3.5 text-[#E6A15E]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="relative">
                  <div className="bg-white/50 dark:bg-black/60 rounded-2xl p-6 border border-gray-200 dark:border-[#2A2A2A] h-full">
                    <div className="flex items-center justify-between mb-6">
                      <h3 className="font-space-grotesk text-xl text-[#E6A15E]">Create Invoice</h3>
                      <span className="px-3 py-1 bg-gradient-to-r from-[#E6A15E]/20 to-[#E6A15E]/10 text-[#E6A15E] rounded-full text-sm">Professional</span>
                    </div>
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm text-gray-600 dark:text-[#E6E6E6]/60 mb-2">Client</label>
                        <input type="text" value="TechVision Studios" readOnly className="w-full bg-white/80 dark:bg-black/40 border border-gray-200 dark:border-[#2A2A2A] rounded-xl px-4 py-3 text-gray-600 dark:text-[#E6E6E6] text-sm" />
                      </div>
                      <div>
                        <label className="block text-sm text-gray-600 dark:text-[#E6E6E6]/60 mb-2">Project</label>
                        <input type="text" value="Website Redesign" readOnly className="w-full bg-white/80 dark:bg-black/40 border border-gray-200 dark:border-[#2A2A2A] rounded-xl px-4 py-3 text-gray-600 dark:text-[#E6E6E6] text-sm" />
                      </div>
                      <div>
                        <label className="block text-sm text-gray-600 dark:text-[#E6E6E6]/60 mb-2">Amount</label>
                        <input type="text" value="$5,000.00" readOnly className="w-full bg-white/80 dark:bg-black/40 border border-gray-200 dark:border-[#2A2A2A] rounded-xl px-4 py-3 text-gray-600 dark:text-[#E6E6E6] text-sm" />
                      </div>
                      <div>
                        <label className="block text-sm text-gray-600 dark:text-[#E6E6E6]/60 mb-2">Due Date</label>
                        <input type="text" value="March 15, 2024" readOnly className="w-full bg-white/80 dark:bg-black/40 border border-gray-200 dark:border-[#2A2A2A] rounded-xl px-4 py-3 text-gray-600 dark:text-[#E6E6E6] text-sm" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* AI Agreement Generation Section */}
          <motion.div 
            className="py-4 sm:py-6 md:py-8 mx-2 sm:mx-4 md:mx-6"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div className="text-center mb-6">
              <h2 className="font-space-grotesk text-3xl md:text-4xl lg:text-5xl mb-3 text-gray-900 dark:text-white">
                AI-Powered <span className="text-[#E6A15E]">Agreements</span>
              </h2>
              <p className="text-gray-600 dark:text-[#E6E6E6]/80 text-lg md:text-xl max-w-2xl mx-auto">
                Generate legally-sound agreements tailored to your needs in minutes, not hours.
              </p>
            </div>

            <div className="bg-[#FDFBF7] dark:bg-[#0A0A0A] rounded-3xl overflow-hidden border border-gray-200 dark:border-[#2A2A2A] p-6 md:p-8 shadow-[0_10px_30px_10px_rgba(230,161,94,0.15)]">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className="space-y-6">
                  <div className="bg-white/50 dark:bg-black/60 rounded-xl p-6 border border-gray-200 dark:border-[#2A2A2A] hover:border-gray-300 dark:hover:border-[#4A4A4A] transition-colors">
                    <h3 className="font-space-grotesk text-xl mb-4 text-[#E6A15E]">Smart Contract Generation</h3>
                    <p className="text-gray-600 dark:text-[#E6E6E6]/80 mb-4">Let AI help you create comprehensive agreements that protect your interests.</p>
                    <ul className="space-y-3">
                      {[
                        'Contract templates',
                        'Custom clauses',
                        'Legal compliance',
                        'Instant generation'
                      ].map((feature, i) => (
                        <li key={i} className="flex items-center gap-2.5 text-gray-600 dark:text-[#E6E6E6]/60 text-sm">
                          <div className="w-5 h-5 rounded-full bg-[#E6A15E]/10 flex items-center justify-center">
                            <svg className="w-3.5 h-3.5 text-[#E6A15E]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="relative">
                  <div className="bg-white/50 dark:bg-black/60 rounded-xl p-6 border border-gray-200 dark:border-[#2A2A2A] h-full">
                    <div className="flex items-center justify-between mb-6">
                      <h3 className="font-space-grotesk text-xl text-[#E6A15E]">Agreement Preview</h3>
                      <span className="px-3 py-1 bg-gradient-to-r from-[#E6A15E]/20 to-[#E6A15E]/10 text-[#E6A15E] rounded-full text-sm">AI Generated</span>
                    </div>
                    <div className="prose prose-invert max-w-none">
                      <p className="text-gray-600 dark:text-[#E6E6E6]/80 text-sm leading-relaxed">
                        This Agreement is made between TechVision Studios ("Client") and [Your Company] ("Provider") for website redesign services...<br/><br/>
                        1. Scope of Work<br/>
                        - Complete website redesign<br/>
                        - UI/UX improvements<br/>
                        - Mobile responsiveness<br/><br/>
                        2. Timeline<br/>
                        - Project start: March 1, 2024<br/>
                        - Expected completion: April 15, 2024
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Updated Pricing Section */}
          {/* <div id="pricing">
            <PricingSection />
          </div> */}

          {/* Additional Info - Mobile Responsive */}
          <motion.div 
            ref={ref3}
            initial={{ opacity: 0, y: 50 }}
            animate={inView3 ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <p className="text-gray-700 dark:text-[#E6E6E6] text-base md:text-lg">SPEND LESS TIME...</p>
          </motion.div>

          {/* Final CTA Section */}
          <motion.div 
            className="py-8 sm:py-12 text-center mx-2 sm:mx-4 md:mx-6"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h2 className="font-space-grotesk text-3xl sm:text-4xl md:text-5xl mb-4 text-gray-900 dark:text-white">
              Ready to <span className="text-[#E6A15E]">Transform</span> Your Business?
            </h2>
            <p className="text-gray-600 dark:text-[#E6E6E6]/80 text-lg md:text-xl max-w-2xl mx-auto mb-8">
              Join thousands of professionals who've already streamlined their client communications.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <motion.button
                onClick={handleGetStarted}
                className="bg-[#E6A15E] text-black px-8 py-3 rounded-lg font-space-grotesk font-medium min-w-[200px] hover:bg-[#E6A15E]/90 transition-colors"
                whileHover={{ scale: 1.05 }}
              >
                Start Your Free Trial
              </motion.button>
              <button
                onClick={() => setIsVideoModalOpen(true)}
                className="bg-[#1A1A1A] text-[#E6E6E6] px-8 py-3 rounded-lg font-space-grotesk font-medium min-w-[200px] hover:bg-[#1A1A1A]/80 transition-colors border border-[#4A4A4A] flex items-center justify-center gap-2"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                See How It Works
              </button>
            </div>
          </motion.div>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}