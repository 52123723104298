import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default function PaymentConfirmation() {
  const { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const firestore = getFirestore();
        const invoiceRef = doc(firestore, 'invoices', invoiceId);
        const snap = await getDoc(invoiceRef);

        if (!snap.exists()) {
          setError('Invoice not found');
        } else {
          setInvoiceData(snap.data());
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInvoice();
  }, [invoiceId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen text-white">
        <p>Loading confirmation...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen text-red-500">
        <p>{error}</p>
      </div>
    );
  }
  if (!invoiceData) {
    return (
      <div className="flex items-center justify-center min-h-screen text-[#999999]">
        <p>No invoice data found.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#111111] text-white flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-[#1A1A1A] border border-[#333] rounded-2xl p-6 text-center">
        {invoiceData.status === 'paid' ? (
          <>
            <h1 className="text-2xl font-bold text-[#FFB27D] mb-4">Payment Successful</h1>
            <p className="mb-4">Thank you for your payment.</p>
            <p className="mb-1">Invoice ID: {invoiceId}</p>
            <p className="mb-1">
              Amount Paid: {invoiceData.currency} {invoiceData.amount || invoiceData.price}
            </p>
            <p className="text-green-400 font-semibold mt-6">Your payment has been received!</p>
          </>
        ) : (
          <p className="text-red-500">Payment was not completed. Please contact support.</p>
        )}
      </div>
    </div>
  );
}
