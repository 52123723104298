import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserData } from '../hooks/useUserData';
import { auth } from '../firebase';
import CreateOptionsModal from './CreateOptionsModal';
import DocumentsList from './DocumentsList';
import InvoiceModal from './InvoiceModal';
import AgreementModal from './AgreementModal';

// Main navigation routes
const ROUTES = {
  EMAILS: {
    path: '/app/emails',
    label: 'EMAILS'
  },
  PROJECTS: {
    path: '/app/projects',
    label: 'PROJECTS'
  }
};

function NavigationBar({ setShowComposeModal, isMenuOpen, setIsMenuOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showCreateOptions, setShowCreateOptions] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [showDocuments, setShowDocuments] = useState({
    invoices: false,
    agreements: false
  });

  const { stats, documents, balance } = useUserData();

  const handleDocumentClick = (type) => {
    if (type === 'CLIENTS') {
      navigate('/app/clients');
      return;
    }
    setShowDocuments(prev => ({ 
      ...prev, 
      [type.toLowerCase()]: true 
    }));
  };

  const handleCloseDocument = (type) => {
    setShowDocuments(prev => ({ ...prev, [type]: false }));
  };

  const handleCreateOption = (type) => {
    setShowCreateOptions(false);
    setShowDocuments({
      invoices: false,
      agreements: false
    });
    
    switch (type) {
      case 'email':
        setShowComposeModal(true);
        break;
      case 'invoice':
        setShowInvoiceModal(true);
        break;
      case 'agreement':
        setShowAgreementModal(true);
        break;
      default:
        break;
    }
  };

  // Get current route for active state
  const getCurrentRoute = (path) => {
    return location.pathname === path;
  };

  // Render the actual sidebar nav
  const renderNavContent = () => (
    <div className="flex flex-col h-full">
      <div className="p-4 sm:p-6 flex-1">
        {/* Header */}
        <div className="flex items-center space-x-2 mb-4">
          <h1 className="text-2xl tracking-wider sm:text-xl text-gray-900 dark:text-white">K L O R A H</h1>
          <span className="px-2 py-0.5 text-[10px] bg-[#FFB27D]/10 rounded text-gray-500 dark:text-[#999999] font-medium">
            PRO
          </span>
        </div>

        {/* Balance Section */}
        <div className="mb-6 p-3 sm:p-4 bg-gray-100 dark:bg-[#111111] rounded-lg">
          <div className="text-xs text-gray-500 dark:text-[#666666] mb-1">BALANCE</div>
          <div className="flex items-baseline flex-wrap">
            <span className="ml-2 text-sm text-gray-500 dark:text-[#666666]">
              ${balance.pending.toFixed(2)} pending
            </span>
          </div>
        </div>

        <div className="space-y-6">
          {/* Documents Section */}
          <div className="space-y-1">
            {['INVOICES', 'AGREEMENTS', 'CLIENTS'].map(type => (
              <div
                key={type}
                onClick={() => handleDocumentClick(type)}
                className="flex items-center justify-between py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-[#1A1A1A] px-3 rounded-lg transition-colors group"
              >
                <div className="flex items-center space-x-2">
                  <span className="text-sm font-medium text-gray-500 dark:text-[#999999] group-hover:text-[#FFB27D] transition-colors">
                    {type}
                  </span>
                </div>
                <span className="text-xs text-gray-500 dark:text-[#999999] group-hover:text-[#FFB27D] transition-colors">
                  {stats[`${type.toLowerCase()}Count`]}
                </span>
              </div>
            ))}
          </div>

          {/* Navigation Section */}
          <div>
            <div className="text-sm text-gray-500 dark:text-[#666666] mb-2">NAVIGATION</div>
            <div className="space-y-1">
              {Object.entries(ROUTES).map(([key, route]) => (
                <button
                  key={key}
                  onClick={() => navigate(route.path)}
                  className={`flex items-center space-x-3 w-full py-2 px-3 rounded-lg text-sm font-medium ${
                    getCurrentRoute(route.path)
                      ? 'bg-gray-100 dark:bg-[#1A1A1A] text-[#FFB27D]'
                      : 'text-gray-500 dark:text-[#666666] hover:text-[#FFB27D] transition-colors'
                  }`}
                >
                  <span>{route.label}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Create Button - Moved up with margin-bottom */}
      <div className="p-4 sm:p-6 mb-12">
        <button 
          onClick={() => setShowCreateOptions(true)}
          className="w-full py-2.5 bg-[#FFB27D] text-black text-sm font-medium rounded-full hover:bg-[#FFA164] transition-colors"
        >
          + CREATE
        </button>
      </div>
    </div>
  );

  return (
    <>
      {/* --- Large Screen Sidebar (always visible) --- */}
      <div className="hidden lg:block lg:w-64 bg-white dark:bg-black border-r border-gray-200 dark:border-[#1A1A1A] h-screen">
        {renderNavContent()}
      </div>

      {/* --- Mobile Overlay and Sidebar (only if isMenuOpen) --- */}
      {isMenuOpen && (
        <div 
          className="fixed inset-0 z-50 flex lg:hidden"
          onClick={() => setIsMenuOpen(false)}
        >
          {/* Dark overlay behind the nav */}
          <div className="absolute inset-0 bg-black/50" />

          {/* The actual nav; stop clicks from closing it */}
          <nav
            className={`
              relative w-64 bg-white dark:bg-black border-r border-gray-200 dark:border-[#1A1A1A] 
              h-full transition-transform duration-300 
              ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}
            `}
            onClick={(e) => e.stopPropagation()}
          >
            {renderNavContent()}
          </nav>
        </div>
      )}

      {/* Modals */}
      <CreateOptionsModal
        isOpen={showCreateOptions}
        onClose={() => setShowCreateOptions(false)}
        onCreateEmail={() => {
          setShowCreateOptions(false);
          setShowComposeModal(true);
        }}
        onCreateInvoice={() => {
          setShowCreateOptions(false);
          setShowInvoiceModal(true);
        }}
        onCreateAgreement={() => {
          setShowCreateOptions(false);
          setShowAgreementModal(true);
        }}
      />

      {showDocuments.invoices && (
        <DocumentsList 
          documents={documents.invoices}
          type="invoices"
          onClose={() => handleCloseDocument('invoices')}
          onCreateNew={() => {
            handleCloseDocument('invoices');
            setShowInvoiceModal(true);
          }}
        />
      )}
      
      {showDocuments.agreements && (
        <DocumentsList 
          documents={documents.agreements}
          type="agreements"
          onClose={() => handleCloseDocument('agreements')}
          onCreateNew={() => {
            handleCloseDocument('agreements');
            setShowAgreementModal(true);
          }}
        />
      )}

      <InvoiceModal 
        isOpen={showInvoiceModal}
        onClose={() => {
          setShowInvoiceModal(false);
          setShowDocuments(prev => ({ ...prev, invoices: false }));
        }}
        user={auth.currentUser}
      />
      
      <AgreementModal 
        isOpen={showAgreementModal}
        onClose={() => {
          setShowAgreementModal(false);
          setShowDocuments(prev => ({ ...prev, agreements: false }));
        }}
      />
    </>
  );
}

export default NavigationBar;