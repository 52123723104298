import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useEmail } from '../contexts/EmailContext';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const GmailCallback = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('processing');
  const [message, setMessage] = useState('');
  const { handleGmailCallback } = useEmail();
  const [authChecked, setAuthChecked] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 2;

  useEffect(() => {
    // Set up auth state listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthChecked(true);
      if (!user) {
        setStatus('error');
        setMessage('You must be logged in to connect a Gmail account');
        toast.error('Please log in to continue');
        // Redirect to login page after a short delay
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const processCallback = async () => {
      // Don't proceed until we've checked auth state
      if (!authChecked) return;
      
      const urlParams = new URLSearchParams(window.location.search);
      const error = urlParams.get('error');
      const code = urlParams.get('code');

      if (error) {
        console.error('Error in Gmail authorization:', error);
        setStatus('error');
        setMessage('Authorization was denied or an error occurred.');
        toast.error('Failed to connect Gmail account');
        return;
      }

      if (!code) {
        setStatus('error');
        setMessage('No authorization code received. Please try again.');
        toast.error('Failed to connect Gmail account');
        return;
      }

      try {
        // Get current user
        const user = auth.currentUser;
        if (!user) {
          throw new Error('You must be logged in to connect a Gmail account');
        }

        // Process the authorization
        const result = await handleGmailCallback(code);
        
        if (result.success && result.accountAdded) {
          setStatus('success');
          setMessage('Gmail account successfully connected!');
          toast.success('Gmail account connected!');
        } else if (result.success && !result.accountAdded) {
          // This case handles when authorization succeeded but no new account was added
          setStatus('error');
          setMessage(result.error || 'This Gmail account is already connected to your Klorah account.');
          toast.error('Account already connected');
        } else if (result.error?.includes('Authorization expired') && retryCount < maxRetries) {
          // Handle expired authorization by redirecting to start the process again
          setRetryCount(prev => prev + 1);
          toast.error('Authorization expired, retrying...');
          setTimeout(() => {
            window.location.href = '/app/emails';
          }, 2000);
        } else {
          throw new Error(result.error || 'Failed to connect Gmail account');
        }
      } catch (error) {
        console.error('Error processing Gmail callback:', error);
        
        // If we get the DatetimeWithNanoseconds error and haven't exceeded retries
        if (error.message?.includes('DatetimeWithNanoseconds') && retryCount < maxRetries) {
          setRetryCount(prev => prev + 1);
          // Wait a moment and try again
          setTimeout(() => processCallback(), 2000);
          return;
        }
        
        setStatus('error');
        setMessage(error.message || 'Failed to connect Gmail account');
        toast.error(error.message || 'Failed to connect Gmail account');
      }
    };

    processCallback();
  }, [handleGmailCallback, authChecked, retryCount]);

  useEffect(() => {
    // Redirect user to /app/emails after 2s if success
    if (status === 'success') {
      const timer = setTimeout(() => {
        navigate('/app/emails');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [status, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-white dark:bg-black">
      <div className="max-w-md w-full p-8 bg-gray-50 dark:bg-[#1A1A1A] rounded-xl shadow-xl">
        <div className="text-center">
          {status === 'processing' && (
            <>
              <div className="mx-auto w-16 h-16 border-4 border-[#FFB27D] border-t-transparent rounded-full animate-spin mb-6"></div>
              <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">Connecting Gmail Account</h2>
              <p className="text-gray-600 dark:text-gray-400">Please wait while we complete the connection process...</p>
            </>
          )}

          {status === 'success' && (
            <>
              <div className="mx-auto w-16 h-16 bg-[#FFB27D]/20 rounded-full flex items-center justify-center mb-6">
                <svg className="w-10 h-10 text-[#FFB27D]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">Gmail Account Connected!</h2>
              <p className="text-gray-600 dark:text-gray-400">{message || 'You will be redirected to your inbox shortly...'}</p>
            </>
          )}

          {status === 'error' && (
            <>
              <div className="mx-auto w-16 h-16 bg-red-100 dark:bg-red-500/20 rounded-full flex items-center justify-center mb-6">
                <svg className="w-10 h-10 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">Connection Failed</h2>
              <p className="text-red-600 dark:text-red-400 mb-6">{message}</p>
              <button
                onClick={() => navigate('/app/emails')}
                className="px-6 py-2 bg-gray-200 dark:bg-[#333] text-gray-900 dark:text-white rounded-lg hover:bg-gray-300 dark:hover:bg-[#444] transition-colors"
              >
                Return to Inbox
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GmailCallback;