import React from 'react';

export default function ServiceList({ services, onSelect }) {
  if (!services || services.length === 0) {
    return null;
  }

  return (
    <ul className="mt-2 divide-y divide-[#333] border border-[#333] rounded-md">
      {services.map(service => (
        <li
          key={service.name}
          className="px-4 py-2 hover:bg-[#222] cursor-pointer"
          onClick={() => onSelect(service)}
        >
          <div className="flex justify-between items-center">
            <span>{service.name}</span>
            <span className="text-sm text-[#999999]">
              {service.currency} {service.price}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
}