import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  getDocs
} from 'firebase/firestore';
import PendingBookings from '../../components/bookings/PendingBookings';
import ProjectCard from '../../components/projects/ProjectCard';

const PROJECTS_PER_PAGE = 9;

export default function ProjectDashboard() {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    let unsubscribeSnapshot = () => {};
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
        return;
      }
      const projectsRef = collection(db, 'projects');
      const q = query(projectsRef, where('userId', '==', user.uid));
      unsubscribeSnapshot = onSnapshot(
        q,
        (snapshot) => {
          setLoading(false);
          if (snapshot.empty) {
            setProjects([]);
            return;
          }
          const projectsData = snapshot.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
          setProjects(projectsData);
        },
        (error) => {
          console.error('Error loading projects:', error);
          setLoading(false);
        }
      );
    });
    return () => {
      unsubscribeAuth();
      unsubscribeSnapshot();
    };
  }, [auth, db, navigate]);

  const loadMore = async () => {
    if (!lastDoc || !hasMore) return;
    const user = auth.currentUser;
    if (!user) return;
    try {
      const nextQuery = query(
        collection(db, 'projects'),
        where('userId', '==', user.uid),
        orderBy('createdAt', 'desc'),
        startAfter(lastDoc),
        limit(PROJECTS_PER_PAGE)
      );
      const snapshot = await getDocs(nextQuery);
      if (snapshot.empty) {
        setHasMore(false);
        return;
      }
      const newProjects = snapshot.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      }));
      setProjects((prev) => [...prev, ...newProjects]);
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      setLastDoc(lastVisible);
      setHasMore(snapshot.docs.length === PROJECTS_PER_PAGE);
    } catch (err) {
      console.error('Error loading more projects:', err);
    }
  };

  const handleNavigate = (projectId) => {
    navigate(`/app/projects/${projectId}`);
  };

  return (
    <div className="min-h-screen bg-white dark:bg-black p-6">
      <PendingBookings />
      <div className="max-w-6xl mx-auto mt-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl text-gray-900 dark:text-white font-medium">Projects</h1>
          <button
            onClick={() => navigate('/projects/new')}
            className="px-4 py-2 bg-[#FFB27D] text-black rounded-lg hover:bg-[#FFB27D]/90 transition-colors"
          >
            New Project
          </button>
        </div>
        {loading ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#FFB27D]" />
          </div>
        ) : projects.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {projects.map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  onNavigate={handleNavigate}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="text-center text-gray-500 dark:text-[#999999] mt-16">
            No projects found.
          </div>
        )}
      </div>
    </div>
  );
}
