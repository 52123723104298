import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import ProfileDropdown from './ProfileDropdown';
import ComposeEmail from './ComposeEmail';
import StripeOnboardingBanner from './StripeOnboardingBanner';
import { EmailProvider } from '../contexts/EmailContext';
import { AuthProvider } from '../contexts/AuthContext';
import { StripeProvider } from '../contexts/StripeContext';
import { auth } from '../firebase';

const AppLayout = () => {
  const user = auth.currentUser;
  const [showComposeModal, setShowComposeModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <AuthProvider currentUser={user}>
      <EmailProvider user={user}>
        <StripeProvider user={user}>
          <div className="flex h-screen bg-gray-50 dark:bg-[#0A0A0A] text-gray-900 dark:text-white overflow-hidden min-w-[280px]">
            <NavigationBar 
              setShowComposeModal={setShowComposeModal} 
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
            />

            {/* Main Content */}
            <div 
              className="flex-1 flex flex-col min-w-[280px]"
              onClick={() => isMenuOpen && setIsMenuOpen(false)}
            >
              {/* Top Bar */}
              <div className="flex items-center justify-between px-4 py-3 md:py-4 bg-white dark:bg-black border-b border-gray-200 dark:border-[#1A1A1A]">
                <div className="flex items-center space-x-3">
                  {!isMenuOpen && (
                    <button 
                      onClick={() => setIsMenuOpen(true)}
                      className="lg:hidden p-1.5 rounded-lg hover:bg-gray-100 dark:hover:bg-[#1A1A1A] transition-colors"
                    >
                      <svg className="w-6 h-6 text-gray-900 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  )}
                  <div className="md:text-sm text-gray-500 dark:text-[#666666]">
                    <span className="md:hidden text-gray-900 dark:text-white text-base tracking-wider font-medium">
                      K L O R A H <span className="text-[10px] ml-1 px-1.5 py-0.5 bg-[#FFB27D]/10 rounded text-gray-500 dark:text-[#999999]">PRO</span>
                    </span>
                  </div>
                </div>
                <div className="flex items-center">
                  <ProfileDropdown user={user} />
                </div>
              </div>

              {/* Main Content Area */}
              <div className="flex-1 overflow-auto">
                <Outlet />
              </div>
            </div>

            {/* Modals */}
            <ComposeEmail
              isOpen={showComposeModal}
              onClose={() => setShowComposeModal(false)}
              user={user}
            />

            {/* Stripe Onboarding Banner */}
            <StripeOnboardingBanner />
          </div>
        </StripeProvider>
      </EmailProvider>
    </AuthProvider>
  );
};

export default AppLayout;
