import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  deleteDoc,
  addDoc,
  updateDoc
} from 'firebase/firestore';
import { motion, AnimatePresence } from 'framer-motion';
import toast from 'react-hot-toast';
import { AcceptModal, DeclineModal } from './BookingModals';

export default function PendingBookings() {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    let unsubscribeSnapshot = () => {};
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (!user) return;
      const bookingsQuery = query(
        collection(db, 'bookings'),
        where('freelancerId', '==', user.uid),
        where('status', '==', 'pending')
      );
      unsubscribeSnapshot = onSnapshot(
        bookingsQuery,
        (snapshot) => {
          const bookingsData = snapshot.docs.map((docSnap) => ({
            id: docSnap.id,
            ...docSnap.data(),
          }));
          setBookings(bookingsData);
        },
        (error) => {
          console.error('Error fetching pending bookings:', error);
        }
      );
    });
    return () => {
      unsubscribeAuth();
      unsubscribeSnapshot();
    };
  }, [auth, db]);

  const handleAccept = async (booking, shouldSendEmail, emailMessage) => {
    try {
      await updateDoc(doc(db, 'bookings', booking.id), {
        status: 'accepted',
        acceptedAt: new Date(),
      });
      const projectsRef = collection(db, 'projects');
      const projectData = {
        projectName: `${booking.serviceName} for ${booking.name}`,
        client: booking.name,
        description: booking.message,
        status: 'Backlog',
        createdAt: new Date(),
        dueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        userId: auth.currentUser.uid,
        tasks: [],
        bookingId: booking.id,
        serviceType: booking.serviceName,
        clientEmail: booking.email,
      };
      const projectDoc = await addDoc(projectsRef, projectData);
      await updateDoc(doc(db, 'bookings', booking.id), {
        projectId: projectDoc.id,
      });
      if (shouldSendEmail) {
        toast.success('Confirmation email sent to client!');
      }
      toast.success('Booking accepted & project created!');
    } catch (error) {
      console.error('Error accepting booking:', error);
      toast.error('Error accepting booking');
    }
  };

  const handleDecline = async (booking, shouldSendEmail, reason) => {
    try {
      await deleteDoc(doc(db, 'bookings', booking.id));
      if (shouldSendEmail) {
        toast.success('Decline notification sent to client!');
      }
      toast.success('Booking declined');
    } catch (error) {
      console.error('Error declining booking:', error);
      toast.error('Error declining booking');
    }
  };

  if (bookings.length === 0) {
    return null;
  }

  return (
    <div className="mb-16">
      <h2 className="text-3xl text-gray-900 dark:text-white leading-tight mb-2">Pending Bookings</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {bookings.map((booking) => (
          <motion.div
            key={booking.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-50 dark:bg-[#111111] rounded-xl p-6 shadow-lg border border-gray-100 dark:border-[#1A1A1A]"
          >
            <div className="mb-4">
              <h3 className="text-lg text-gray-900 dark:text-white mb-1">{booking.serviceName}</h3>
              <p className="text-gray-500 dark:text-[#999999]">from {booking.name}</p>
            </div>
            <div className="mb-4">
              <p className="text-xl font-medium text-gray-900 dark:text-white">
                {booking.serviceCurrency} {booking.servicePrice}
              </p>
            </div>
            <p className="text-sm text-gray-500 dark:text-[#999999] mb-6">{booking.message}</p>
            <div className="flex gap-4">
              <motion.button
                onClick={() => {
                  setSelectedBooking(booking);
                  setShowAcceptModal(true);
                }}
                className="flex-1 px-4 py-2 bg-[#FFB27D] text-black rounded-xl hover:bg-[#FFB27D]/90 transition-colors shadow-lg shadow-[#FFB27D]/20"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Accept
              </motion.button>
              <button
                onClick={() => {
                  setSelectedBooking(booking);
                  setShowDeclineModal(true);
                }}
                className="flex-1 px-4 py-2 border border-[#FFB27D] text-[#FFB27D] rounded-xl hover:bg-[#FFB27D]/10 transition-colors"
              >
                Decline
              </button>
            </div>
          </motion.div>
        ))}
      </div>
      <AnimatePresence>
        {showAcceptModal && selectedBooking && (
          <AcceptModal
            booking={selectedBooking}
            onClose={() => {
              setSelectedBooking(null);
              setShowAcceptModal(false);
            }}
            onAccept={(sendEmail, message) => {
              handleAccept(selectedBooking, sendEmail, message);
              setShowAcceptModal(false);
              setSelectedBooking(null);
            }}
          />
        )}
        {showDeclineModal && selectedBooking && (
          <DeclineModal
            booking={selectedBooking}
            onClose={() => {
              setSelectedBooking(null);
              setShowDeclineModal(false);
            }}
            onDecline={(sendEmail, reason) => {
              handleDecline(selectedBooking, sendEmail, reason);
              setShowDeclineModal(false);
              setSelectedBooking(null);
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
} 