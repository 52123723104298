import React, { useState, useEffect } from 'react';
import EmailService from '../services/EmailService';

const ComposeEmail = ({
  isOpen,
  onClose,
  user,
  onEmailSent,
  replyToEmail
}) => {
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);

  // Reset form when modal opens/closes or replyToEmail changes
  useEffect(() => {
    if (isOpen && replyToEmail) {
      console.log('Reply To Email Object:', replyToEmail);
      
      let emailAddress = '';
      let cleanSubject = replyToEmail.subject || '';

      // First try to get the sender's email
      if (replyToEmail.sender) {
        // Try to extract from angle brackets first
        const angleEmailMatch = replyToEmail.sender.match(/<([^>]+)>/);
        if (angleEmailMatch) {
          emailAddress = angleEmailMatch[1];
        } else {
          // If no angle brackets, try to find a plain email
          const plainEmailMatch = replyToEmail.sender.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/);
          if (plainEmailMatch) {
            emailAddress = plainEmailMatch[1];
          } else {
            emailAddress = replyToEmail.sender;
          }
        }
      }
      
      // If no sender email, try to get it from the 'to' field
      if (!emailAddress && replyToEmail.to) {
        const toEmailMatch = replyToEmail.to.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/);
        if (toEmailMatch) {
          emailAddress = toEmailMatch[1];
        } else {
          emailAddress = replyToEmail.to;
        }
      }
      
      // Then check if there's an email in subject parentheses - this takes precedence
      if (replyToEmail.subject) {
        const emailInSubject = replyToEmail.subject.match(/\((.*?@.*?)\)/);
        if (emailInSubject) {
          emailAddress = emailInSubject[1];
          // Remove the email part from subject
          cleanSubject = replyToEmail.subject
            .replace(/\s*\(.*?\)\s*$/, '') // Remove email in parentheses
            .replace(/^Re:\s+/i, ''); // Remove Re: prefix
        } else {
          cleanSubject = cleanSubject.replace(/^Re:\s+/i, '');
        }
      }

      console.log('Extracted email:', emailAddress);
      console.log('Clean subject:', cleanSubject);
      
      setTo(emailAddress);
      setSubject(`Re: ${cleanSubject}`);
      setBody('');
    } else if (isOpen) {
      // Handle new email case
      setTo('');
      setSubject('');
      setBody('');
    }
  }, [isOpen, replyToEmail]);

  const handleSend = async () => {
    if (!to || !subject || !body) return;
    if (!user) return;
    setLoading(true);
    const messageId = replyToEmail && replyToEmail.id ? replyToEmail.id : null;
    const threadId = replyToEmail && replyToEmail.threadId ? replyToEmail.threadId : null;
    try {
      const result = await EmailService.sendEmail({
        user_id: user.uid,
        to,
        subject,
        body,
        account_id: replyToEmail ? replyToEmail.accountId : '',
        thread_id: threadId || undefined,
        message_id: messageId || undefined
      });
      onEmailSent(result);
      onClose();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
      <div className="bg-white dark:bg-black w-full max-w-xl mx-auto rounded-3xl shadow-2xl border border-gray-200 dark:border-[#333] overflow-hidden">
        <div className="px-8 py-6 border-b border-gray-200 dark:border-[#333] backdrop-blur-xl bg-gray-50/40 dark:bg-black/40">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-medium tracking-tight text-gray-900 dark:text-white">
              {replyToEmail ? 'Reply' : 'New Message'}
            </h2>
            <button 
              onClick={onClose}
              className="text-gray-400 dark:text-white/40 hover:text-gray-900 dark:hover:text-white transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        
        <div className="p-8 space-y-8">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-600 dark:text-white/60">To</label>
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="w-full px-4 py-3 bg-gray-50 dark:bg-black border border-gray-200 dark:border-[#333] rounded-xl focus:outline-none focus:border-[#FFB27D] focus:ring-2 focus:ring-[#FFB27D]/10 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-400 dark:placeholder:text-white/20"
              placeholder={replyToEmail ? '' : 'recipient@email.com'}
              readOnly={Boolean(replyToEmail)}
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-600 dark:text-white/60">Subject</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full px-4 py-3 bg-gray-50 dark:bg-black border border-gray-200 dark:border-[#333] rounded-xl focus:outline-none focus:border-[#FFB27D] focus:ring-2 focus:ring-[#FFB27D]/10 transition-all duration-200 text-gray-900 dark:text-white placeholder:text-gray-400 dark:placeholder:text-white/20"
              placeholder={replyToEmail ? '' : "What's this about?"}
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-600 dark:text-white/60">Message</label>
            <textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              className="w-full px-4 py-3 bg-gray-50 dark:bg-black border border-gray-200 dark:border-[#333] rounded-xl focus:outline-none focus:border-[#FFB27D] focus:ring-2 focus:ring-[#FFB27D]/10 transition-all duration-200 min-h-[240px] resize-none text-gray-900 dark:text-white placeholder:text-gray-400 dark:placeholder:text-white/20"
              placeholder={replyToEmail ? 'Write your reply here...' : 'Write your message here...'}
            />
          </div>
        </div>

        <div className="px-8 py-6 border-t border-gray-200 dark:border-[#333] backdrop-blur-xl bg-gray-50/40 dark:bg-black/40 flex items-center justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-6 py-3 text-sm font-medium text-gray-500 dark:text-white/60 hover:text-gray-900 dark:hover:text-white transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSend}
            disabled={loading || !to || !subject || !body}
            className="px-8 py-3 bg-[#FFB27D] text-black text-sm font-medium rounded-xl hover:bg-[#FFB27D]/90 transform active:scale-95 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg shadow-[#FFB27D]/10"
          >
            {loading ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </span>
            ) : replyToEmail ? 'Send Reply' : 'Send Message'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComposeEmail;