import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
  serverTimestamp
} from 'firebase/firestore';

export class InvoiceService {
  constructor(user) {
    this.user = user;
    this.firestore = getFirestore();
  }

  async createInvoice(invoiceData) {
    try {
      console.log("Creating invoice for user:", this.user.uid);
      
      // Check if user has Stripe account ID
      const userRef = doc(this.firestore, 'users', this.user.uid);
      const userDoc = await getDoc(userRef);
      
      // Get the Stripe account ID from either the users or profiles collection
      let stripeAccountId = null;
      if (userDoc.exists() && userDoc.data().stripeAccountId) {
        stripeAccountId = userDoc.data().stripeAccountId;
        console.log("Found stripeAccountId in users collection:", stripeAccountId);
      } else {
        const profileRef = doc(this.firestore, 'profiles', this.user.uid);
        const profileDoc = await getDoc(profileRef);
        if (profileDoc.exists() && profileDoc.data().stripeConnectedAccountId) {
          stripeAccountId = profileDoc.data().stripeConnectedAccountId;
          console.log("Found stripeConnectedAccountId in profiles collection:", stripeAccountId);
        } else {
          console.log("No Stripe account ID found for user:", this.user.uid);
          console.log("User document exists:", userDoc.exists());
          if (userDoc.exists()) {
            console.log("User document data:", userDoc.data());
          }
        }
      }
      
      // 1) Create the invoice in the top-level "invoices" collection
      //    so the payment flow can find it by invoiceId:
      const invoicesRef = collection(this.firestore, 'invoices');
      const invoiceToCreate = {
        ...invoiceData,
        userId: this.user.uid,
        status: 'PENDING',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        connectedAccountId: stripeAccountId // Add Stripe account ID to invoice
      };
      
      console.log("Saving invoice with data:", { ...invoiceToCreate, createdAt: "serverTimestamp", updatedAt: "serverTimestamp" });
      
      const docRef = await addDoc(invoicesRef, invoiceToCreate);

      const invoiceId = docRef.id; // We'll return this for routing

      // 2) Update user's invoice stats
      if (userDoc.exists()) {
        const userData = userDoc.data();
        await updateDoc(userRef, {
          'stats.invoices': (userData.stats?.invoices || 0) + 1,
          'stats.pendingAmount':
            (userData.stats?.pendingAmount || 0) + parseFloat(invoiceData.amount)
        });
      }

      // 3) If a matching client exists, update that client's stats
      if (invoiceData.email) {
        const clientsRef = collection(this.firestore, 'users', this.user.uid, 'clients');
        const q = query(clientsRef, where('email', '==', invoiceData.email));
        const clientSnapshot = await getDocs(q);

        if (!clientSnapshot.empty) {
          const clientDoc = clientSnapshot.docs[0];
          await updateDoc(clientDoc.ref, {
            'stats.invoices': (clientDoc.data().stats?.invoices || 0) + 1,
            lastInvoiceDate: new Date()
          });
        }
      }

      // Return the newly created invoice's ID so you can navigate to /preview-invoice/:invoiceId
      return invoiceId;
    } catch (error) {
      console.error('Error in createInvoice:', error);
      throw error;
    }
  }
}