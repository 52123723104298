import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import EmailService from '../services/EmailService';

const EmailContext = createContext();

export const useEmail = () => {
  const context = useContext(EmailContext);
  if (!context) {
    throw new Error('useEmail must be used within EmailProvider');
  }
  return context;
};

export const EmailProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [isGmailAuthorized, setIsGmailAuthorized] = useState(false);
  const [activeAccountId, setActiveAccountId] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [emails, setEmails] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [view, setView] = useState('FOCUSED');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUserId(user.uid);
        const status = await EmailService.checkGmailAuth(user.uid);
        setIsGmailAuthorized(status.authorized);
        if (status.authorized) {
          fetchAccounts(user.uid);
        }
      } else {
        setUserId(null);
        setIsGmailAuthorized(false);
        setActiveAccountId(null);
        setEmails([]);
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchAccounts = useCallback(async (uid) => {
    const data = await EmailService.getAccounts(uid);
    const accs = data.accounts || [];
    setAccounts(accs);
    if (accs.length > 0 && !activeAccountId) {
      setActiveAccountId(accs[0].id);
    }
  }, [activeAccountId]);

  const handleGmailAuth = useCallback(async () => {
    if (!userId) {
      console.error('No user logged in');
      setError('You must be logged in to connect your Gmail account');
      return;
    }
    
    setIsAddingAccount(true);
    setError(null);
    
    try {
      console.log('Initiating Gmail authentication');
      await EmailService.initiateGmailAuth(userId);
    } catch (error) {
      console.error('Failed to initiate Gmail auth:', error);
      setError('Failed to connect Gmail account. Please try again.');
    } finally {
      setIsAddingAccount(false);
    }
  }, [userId]);

  const handleGmailCallback = useCallback(async (code) => {
    if (!userId) {
      console.error('No user logged in');
      return { success: false, error: 'You must be logged in to connect your Gmail account' };
    }

    setIsAddingAccount(true);
    setError(null);

    try {
      console.log('Processing Gmail callback with code');
      const response = await EmailService.authorizeGmail(userId, code);
      console.log('Gmail authorization response:', response);

      if (response.success) {
        setIsGmailAuthorized(true);
        
        // Always update accounts list with the latest data
        if (response.data?.accounts) {
          setAccounts(response.data.accounts);
          
          // If this is the first account, set it as active
          if (response.data.accounts.length > 0 && !activeAccountId) {
            setActiveAccountId(response.data.accounts[0].id);
          }
          
          // If a new account was added, try to set it as active
          if (response.accountAdded && response.email) {
            const newAccount = response.data.accounts.find(acc => acc.email === response.email);
            if (newAccount) {
              setActiveAccountId(newAccount.id);
            }
          }
        }

        // Return appropriate response based on whether account was added
        if (response.accountAdded) {
          return { 
            success: true, 
            accountAdded: true,
            email: response.email 
          };
        } else {
          return { 
            success: true, 
            accountAdded: false, 
            error: response.error || 'This Gmail account is already connected.' 
          };
        }
      } else {
        const errorMsg = response.error || 'Failed to connect Gmail account';
        setError(errorMsg);
        return { success: false, error: errorMsg };
      }
    } catch (error) {
      console.error('Error in Gmail callback:', error);
      const errorMsg = error.message || 'An unexpected error occurred';
      setError(errorMsg);
      return { success: false, error: errorMsg };
    } finally {
      setIsAddingAccount(false);
    }
  }, [userId, activeAccountId]);

  const handleSwitchAccount = useCallback((accountId, onSwitchCallback) => {
    if (accountId && accountId !== activeAccountId) {
      setActiveAccountId(accountId);
      // Clear emails when switching accounts to avoid stale data
      setEmails([]);
      // Reset pagination
      setNextPageToken(null);
      setHasMore(false);
      
      // If a callback was provided, execute it
      if (typeof onSwitchCallback === 'function') {
        onSwitchCallback();
      }
      // Emails will be fetched automatically by the useEffect below
      // when activeAccountId changes
    }
  }, [activeAccountId]);

  const fetchEmails = useCallback(async (reset = false) => {
    if (!userId || !activeAccountId || !isGmailAuthorized) return;
    setLoading(true);
    setError(null);
    if (reset) {
      setEmails([]);
      setNextPageToken(null);
      setHasMore(false);
    }
    const options = {
      pageToken: reset ? null : nextPageToken,
      view,
      limit: 10
    };
    const result = await EmailService.getEmails(userId, activeAccountId, options);
    if (result.error) {
      setError(result.error);
      setLoading(false);
      return;
    }
    const newEmails = result.emails || [];
    if (reset) {
      setEmails(newEmails);
    } else {
      setEmails((prev) => [...prev, ...newEmails]);
    }
    setNextPageToken(result.nextPageToken || null);
    setHasMore(!!result.nextPageToken);
    setLoading(false);
  }, [userId, activeAccountId, isGmailAuthorized, nextPageToken, view]);

  useEffect(() => {
    if (isGmailAuthorized && userId && activeAccountId) {
      fetchEmails(true);
    }
  }, [isGmailAuthorized, userId, activeAccountId, view]);

  const handleLoadMore = useCallback(async () => {
    if (!loading && hasMore) {
      await fetchEmails(false);
    }
  }, [loading, hasMore, fetchEmails]);

  // Get the active email address from the active account
  const activeEmail = accounts.find(account => account.id === activeAccountId)?.email || '';

  const handleDeleteAccount = useCallback(async (accountId) => {
    if (!userId || !accountId) return;
    
    try {
      setIsDeletingAccount(true);
      setError(null);
      
      await EmailService.disconnectAccount(userId, accountId);
      
      setAccounts(prevAccounts => prevAccounts.filter(account => account.id !== accountId));
      
      if (accountId === activeAccountId) {
        const remainingAccounts = accounts.filter(account => account.id !== accountId);
        if (remainingAccounts.length > 0) {
          handleSwitchAccount(remainingAccounts[0].id);
        } else {
          setActiveAccountId(null);
          setEmails([]);
        }
      }
      
      await fetchAccounts(userId);
      
      return { success: true };
    } catch (error) {
      console.error('Failed to disconnect account:', error);
      setError('Failed to disconnect account. Please try again.');
      return { success: false, error };
    } finally {
      setIsDeletingAccount(false);
    }
  }, [userId, activeAccountId, accounts, fetchAccounts, handleSwitchAccount]);

  const contextValue = {
    userId,
    accounts,
    activeAccountId,
    activeEmail,
    isGmailAuthorized,
    loading,
    error,
    emails,
    nextPageToken,
    hasMore,
    view,
    setView,
    setActiveAccountId,
    handleGmailAuth,
    handleGmailCallback,
    fetchAccounts,
    fetchEmails,
    handleLoadMore,
    handleSwitchAccount,
    handleDeleteAccount,
    isAddingAccount,
    isDeletingAccount
  };

  return <EmailContext.Provider value={contextValue}>{children}</EmailContext.Provider>;
};

export default EmailContext;