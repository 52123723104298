import React from 'react';

const CreateOptionsModal = ({ isOpen, onClose, onCreateInvoice, onCreateAgreement, onCreateEmail }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 fade-in z-[90]">
      <div className="bg-[#111111] rounded-2xl shadow-2xl w-full max-w-xs border border-[#1A1A1A]">
        <div className="p-6 border-b border-[#1A1A1A] flex items-center justify-between">
          <h2 className="text-lg font-medium tracking-wide text-white">Create New</h2>
          <button 
            onClick={onClose}
            className="text-[#666666] hover:text-white transition-colors"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-4">
          <div className="space-y-2">
            <button
              onClick={() => {
                onCreateEmail();
                onClose();
              }}
              className="w-full flex items-center space-x-3 p-3 rounded-lg text-left hover:bg-[#1A1A1A] transition-colors group"
            >
              <svg className="h-5 w-5 text-[#666666] group-hover:text-white transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <span className="text-[#999999] group-hover:text-white transition-colors">New Email</span>
            </button>
            
            <button
              onClick={() => {
                onCreateInvoice();
                onClose();
              }}
              className="w-full flex items-center space-x-3 p-3 rounded-lg text-left hover:bg-[#1A1A1A] transition-colors group"
            >
              <svg className="h-5 w-5 text-[#666666] group-hover:text-white transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <span className="text-[#999999] group-hover:text-white transition-colors">New Invoice</span>
            </button>

            <button
              onClick={() => {
                onCreateAgreement();
                onClose();
              }}
              className="w-full flex items-center space-x-3 p-3 rounded-lg text-left hover:bg-[#1A1A1A] transition-colors group"
            >
              <svg className="h-5 w-5 text-[#666666] group-hover:text-white transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <span className="text-[#999999] group-hover:text-white transition-colors">New Agreement</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOptionsModal;
