import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const StripeContext = createContext();

export function StripeProvider({ children }) {
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
        checkStripeStatus(user.uid);
      } else {
        setUserId(null);
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const checkStripeStatus = async (uid) => {
    try {
      const firestore = getFirestore();
      const userRef = doc(firestore, 'users', uid);
      const profileRef = doc(firestore, 'profiles', uid);
      
      const [userDoc, profileDoc] = await Promise.all([
        getDoc(userRef),
        getDoc(profileRef)
      ]);
      
      const hasStripeAccount = 
        (userDoc.exists() && userDoc.data().stripeAccountId) || 
        (profileDoc.exists() && profileDoc.data().stripeConnectedAccountId);

      setStripeAccountId(
        userDoc.exists() ? userDoc.data().stripeAccountId : 
        profileDoc.exists() ? profileDoc.data().stripeConnectedAccountId : 
        null
      );
      setIsOnboarded(!!hasStripeAccount);
    } catch (error) {
      console.error('Error checking Stripe status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const startOnboarding = async () => {
    if (!userId) throw new Error('User must be logged in to start onboarding');
    
    if (!auth.currentUser.emailVerified) {
      throw new Error('Please verify your email before proceeding.');
    }
    
    try {
      const response = await fetch('https://klorah-fast-server.onrender.com/api/create-onboarding-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'An error occurred during onboarding');
      }
      
      if (!data.url) {
        throw new Error('No URL returned from the server');
      }

      window.location.href = data.url;
    } catch (error) {
      console.error('Detailed onboarding error:', error);
      throw new Error(error.message || 'Error during onboarding. Please try again later.');
    }
  };

  const value = {
    isOnboarded,
    stripeAccountId,
    isLoading,
    startOnboarding
  };

  return (
    <StripeContext.Provider value={value}>
      {children}
    </StripeContext.Provider>
  );
}

export function useStripe() {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error('useStripe must be used within a StripeProvider');
  }
  return context;
}
