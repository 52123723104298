import React, { useState, useRef, useCallback } from 'react';
import { useEmail } from '../contexts/EmailContext';

const EmailList = ({
  emails = [],
  selectedEmail,
  onSelectEmail,
  isCompact = false,
  isLoading = false,
  error = null,
  onLoadMore,
  hasMore = false
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const {
    view,
    setView,
    setSearchQuery,
    filteredEmails: contextFilteredEmails = [],
    activeAccountId
  } = useEmail();
  const observer = useRef();
  const listRef = useRef(null);

  const emailsWithAccountId = emails.map(email => ({
    ...email,
    accountId: email.accountId || activeAccountId
  }));

  const lastEmailElementRef = useCallback(
    (node) => {
      if (isLoading || !onLoadMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            onLoadMore();
          }
        },
        {
          root: listRef.current,
          rootMargin: '0px',
          threshold: 0.1
        }
      );
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, onLoadMore]
  );

  const categories = [
    { id: 'FOCUSED', label: 'FOCUSED', icon: 'M13 10V3L4 14h7v7l9-11h-7z' },
    {
      id: 'CATEGORY_SOCIAL',
      label: 'SOCIAL',
      icon: 'M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
    },
    {
      id: 'CATEGORY_PROMOTIONS',
      label: 'PROMOTIONS',
      icon: 'M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
    },
    {
      id: 'UPDATES',
      label: 'UPDATES',
      icon: 'M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
    }
  ];

  const handleCategoryChange = (categoryId) => {
    if (view !== categoryId) {
      if (selectedEmail && onSelectEmail) {
        onSelectEmail(null);
      }
      const listContainer = document.querySelector('.email-list-container');
      if (listContainer) {
        listContainer.classList.add('fade-transition');
        setTimeout(() => {
          setView(categoryId);
          setTimeout(() => {
            listContainer.classList.remove('fade-transition');
          }, 150);
        }, 150);
      } else {
        setView(categoryId);
      }
    }
  };

  const getEmailCategory = (email) => {
    const sender = (email.sender || '').toLowerCase();
    const subject = (email.subject || '').toLowerCase();
    const preview = (email.preview || '').toLowerCase();
    const socialKeywords = [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'social',
      'invitation',
      'friend',
      'connection',
      'follow',
      'network',
      'comment',
      'like',
      'share',
      'post'
    ];
    const promoKeywords = [
      'newsletter',
      'offer',
      'promotion',
      'sale',
      'discount',
      'deal',
      'coupon',
      'off',
      'limited time',
      'exclusive',
      'save',
      'buy',
      'purchase',
      'shop',
      'store'
    ];
    const updateKeywords = [
      'notification',
      'update',
      'alert',
      'security',
      'password',
      'confirm',
      'verification',
      'verify',
      'account',
      'subscription',
      'billing',
      'receipt'
    ];
    if (
      socialKeywords.some(
        (keyword) => sender.includes(keyword) || subject.includes(keyword) || preview.includes(keyword)
      )
    ) {
      return 'social';
    }
    if (
      promoKeywords.some(
        (keyword) => sender.includes(keyword) || subject.includes(keyword) || preview.includes(keyword)
      )
    ) {
      return 'promotions';
    }
    if (
      updateKeywords.some(
        (keyword) => sender.includes(keyword) || subject.includes(keyword) || preview.includes(keyword)
      )
    ) {
      return 'updates';
    }
    return 'focused';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));
    if (diffInHours < 24) {
      return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
    } else if (diffInHours < 48) {
      return 'Yesterday ' + date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
    } else {
      return (
        date.toLocaleDateString([], { month: 'short', day: 'numeric' }) +
        ' ' +
        date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })
      );
    }
  };

  const decodeHtmlEntities = (text) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  };

  const cleanAndTruncatePreview = (text, maxLength = 120) => {
    if (!text) return '';
    
    // Decode HTML entities
    let cleanText = decodeHtmlEntities(text);
    
    // Remove HTML tags
    cleanText = cleanText.replace(/<[^>]+>/g, ' ');
    
    // Remove extra whitespace
    cleanText = cleanText.replace(/\s+/g, ' ').trim();
    
    // Handle special characters
    cleanText = cleanText
      .replace(/&nbsp;/g, ' ')
      .replace(/[\u200B-\u200D\uFEFF]/g, ''); // Remove zero-width spaces
    
    // Smart truncation - try to end at a natural break point
    if (cleanText.length <= maxLength) return cleanText;
    
    // Find the last space within maxLength
    const lastSpace = cleanText.lastIndexOf(' ', maxLength - 3);
    if (lastSpace > maxLength * 0.7) { // Only use space if it's far enough into the text
      return cleanText.slice(0, lastSpace) + '...';
    }
    
    // If no good space found, do a hard truncate
    return cleanText.slice(0, maxLength - 3) + '...';
  };

  return (
    <div className="flex flex-col h-full bg-white dark:bg-black w-full md:w-[380px] min-w-[280px] pt-4 md:pt-0">
      <div className="flex items-center justify-between sticky top-0 z-10 bg-white dark:bg-black px-3 sm:px-6 py-3 sm:py-4 border-b border-gray-200 dark:border-[#1A1A1A]">
        <div className="text-xs sm:text-sm text-[#FFB27D] truncate">
          Your hustle today builds tomorrow's success
        </div>
      </div>
      <div
        className={`flex items-center justify-between sticky top-[3.25rem] md:top-[4rem] z-10 bg-white dark:bg-black ${
          selectedEmail ? 'px-2 py-1' : 'px-2 md:px-3 py-1 md:py-1.5'
        } border-b border-gray-200 dark:border-[#1A1A1A] h-[40px] flex items-center`}
      >
        <div className="flex items-center space-x-2 overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:none] h-full">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => handleCategoryChange(category.id)}
              className={`flex items-center space-x-1 text-[10px] font-medium ${
                view === category.id ? 'text-gray-900 dark:text-white' : 'text-gray-500 dark:text-[#666666]'
              } whitespace-nowrap px-1 py-0.5 rounded transition-colors ${
                view === category.id ? 'bg-gray-100 dark:bg-[#1A1A1A]' : 'hover:bg-gray-100 dark:hover:bg-[#1A1A1A]/50'
              } h-[28px] flex items-center`}
            >
              <svg className="h-2.5 w-2.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d={category.icon} />
              </svg>
              <span>{category.label}</span>
            </button>
          ))}
        </div>
        <button
          onClick={() => setShowSearch(!showSearch)}
          className="ml-2 text-gray-500 dark:text-[#666666] hover:text-gray-900 dark:hover:text-white/90 transition-colors duration-300"
        >
          <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </button>
      </div>
      {showSearch && (
        <div
          className={`sticky top-[5rem] md:top-[6rem] z-10 bg-white dark:bg-black ${
            selectedEmail ? 'px-3 py-2' : 'p-3 md:p-6'
          } border-b border-gray-200 dark:border-[#1A1A1A] transition-all duration-300 transform ${
            showSearch ? 'translate-y-0' : '-translate-y-full'
          }`}
        >
          <div className="relative">
            <input
              type="text"
              placeholder="Search emails..."
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`w-full bg-gray-100 dark:bg-[#111111] text-gray-900 dark:text-white pl-7 sm:pl-8 pr-3 sm:pr-4 ${
                selectedEmail ? 'py-2' : 'py-2.5 md:py-3'
              } rounded-lg border-0 text-sm placeholder-gray-500 dark:placeholder-[#666666]`}
            />
            <svg
              className={`absolute ${
                selectedEmail ? 'left-2.5 top-2.5' : 'left-2.5 md:left-3 top-2.5 md:top-3.5'
              } h-4 w-4 text-gray-500 dark:text-[#666666]`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
      )}
      <div ref={listRef} className="flex-1 overflow-y-auto p-2 sm:p-4 hide-scrollbar email-list-container">
        {isLoading ? (
          <div className="flex-1 flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-[#FFB27D]" />
          </div>
        ) : error ? (
          <div className="flex-1 flex items-center justify-center text-gray-500 dark:text-[#666666]">
            <p>{error}</p>
          </div>
        ) : emailsWithAccountId.length === 0 ? (
          <div className="flex-1 flex items-center justify-center">
            <div className="text-center">
              <svg className="h-12 w-12 mx-auto text-gray-300 dark:text-[#333333] mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <p className="text-gray-500 dark:text-[#666666]">
                {view === 'CATEGORY_SOCIAL'
                  ? 'No social network emails found'
                  : view === 'CATEGORY_PROMOTIONS'
                  ? 'No promotional emails found'
                  : view === 'UPDATES'
                  ? 'No updates or notifications found'
                  : 'No emails in your focused inbox'}
              </p>
              <p className="text-gray-500 dark:text-[#666666] text-xs mt-1">Currently viewing: {view} category</p>
              <button
                onClick={() => console.log('Email debug info:', { view, emails: emailsWithAccountId })}
                className="mt-2 px-3 py-1 text-[10px] bg-gray-100 dark:bg-[#1A1A1A] text-gray-600 dark:text-[#999999] hover:bg-gray-200 dark:hover:bg-[#2A2A2A] hover:text-gray-900 dark:hover:text-white rounded-lg transition-colors"
              >
                Debug Info
              </button>
              <button
                onClick={() => {
                  const debugInfo = {
                    view,
                    emailCount: emailsWithAccountId.length,
                    params: {
                      folder: view === 'FOCUSED' ? 'INBOX' : view,
                      isCategory: view.startsWith('CATEGORY_')
                    },
                    currentTime: new Date().toLocaleString()
                  };
                  console.log('Advanced debug info:', debugInfo);
                }}
                className="mt-2 ml-2 px-3 py-1 text-[10px] bg-gray-100 dark:bg-[#1A1A1A] text-gray-600 dark:text-[#999999] hover:bg-gray-200 dark:hover:bg-[#2A2A2A] hover:text-gray-900 dark:hover:text-white rounded-lg transition-colors"
              >
                Advanced Debug
              </button>
            </div>
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto">
            {emailsWithAccountId.map((email, index) => (
              <div
                key={email.id}
                ref={index === emailsWithAccountId.length - 1 ? lastEmailElementRef : null}
                onClick={() => onSelectEmail(email)}
                className={`flex items-start ${
                  isCompact ? 'p-2' : 'p-2 sm:p-3'
                } cursor-pointer border border-gray-200 dark:border-[#1A1A1A] rounded-lg mb-2 hover:shadow-[0_0_15px_rgba(255,178,125,0.15)] ${
                  selectedEmail?.id === email.id ? 'bg-gray-100 dark:bg-[#111111]' : ''
                }`}
              >
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between mb-1 gap-2">
                    <div className="flex items-center space-x-2 min-w-0 flex-shrink">
                      {email.sender === 'STRIPE PAYMENTS' ? (
                        <div className={`${isCompact ? 'w-6 h-6 text-[10px]' : 'w-7 h-7 text-xs'} bg-[#635BFF]/90 rounded-full flex items-center justify-center font-light text-white/90 transition-all duration-300 shrink-0`}>
                          S
                        </div>
                      ) : email.sender === 'UPWORK TEAM' ? (
                        <div className={`${isCompact ? 'w-6 h-6 text-[10px]' : 'w-7 h-7 text-xs'} bg-[#14A800]/90 rounded-full flex items-center justify-center font-light text-white/90 transition-all duration-300 shrink-0`}>
                          U
                        </div>
                      ) : email.sender === 'FIVERR' ? (
                        <div className={`${isCompact ? 'w-6 h-6 text-[10px]' : 'w-7 h-7 text-xs'} bg-[#1DBF73]/90 rounded-full flex items-center justify-center font-light text-white/90 transition-all duration-300 shrink-0`}>
                          F
                        </div>
                      ) : (
                        <div className={`${isCompact ? 'w-6 h-6 text-[10px]' : 'w-7 h-7 text-xs'} bg-gradient-to-b from-[#FFB27D]/80 to-[#FF9D5C]/80 rounded-full flex items-center justify-center font-light text-black/80 transition-all duration-300 shrink-0`}>
                          {email.sender.charAt(0)}
                        </div>
                      )}
                      <div className="flex flex-col min-w-0 flex-shrink">
                        <span
                          className={`font-normal ${
                            isCompact ? 'text-[10px]' : 'text-xs'
                          } text-gray-900 dark:text-white/90 transition-all duration-300 truncate max-w-[120px] md:max-w-none`}
                        >
                          {email.sender}
                        </span>
                        {!isCompact && (
                          <span className="text-[10px] text-gray-500 dark:text-[#666666]/90 truncate">{formatDate(email.date)}</span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 shrink-0">
                      {view === 'FOCUSED' && getEmailCategory(email) !== 'focused' && (
                        <span
                          className={`text-[8px] px-1.5 py-0.5 sm:text-[9px] sm:px-2 sm:py-0.5 rounded-full uppercase transition-all duration-300 whitespace-nowrap ${
                            getEmailCategory(email) === 'social'
                              ? 'bg-blue-500/10 text-blue-400/90'
                              : getEmailCategory(email) === 'promotions'
                              ? 'bg-green-500/10 text-green-400/90'
                              : 'bg-yellow-500/10 text-yellow-400/90'
                          }`}
                        >
                          {getEmailCategory(email) === 'social'
                            ? 'Social'
                            : getEmailCategory(email) === 'promotions'
                            ? 'Promo'
                            : 'Update'}
                        </span>
                      )}
                      {email.priority && (
                        <span className="text-[8px] px-1.5 py-0.5 sm:text-[9px] sm:px-2 sm:py-0.5 bg-[#FFB27D]/10 text-[#FFB27D]/90 rounded-full uppercase transition-all duration-300 whitespace-nowrap">
                          Priority
                        </span>
                      )}
                      {isCompact && (
                        <span className="text-[9px] text-gray-500 dark:text-[#666666]/80 whitespace-nowrap">{formatDate(email.date)}</span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-start justify-between gap-2">
                    <div className="flex-1 min-w-0">
                      <h3
                        className={`font-normal truncate ${
                          isCompact ? 'text-[10px]' : 'text-xs'
                        } text-gray-900 dark:text-white/90 transition-all duration-300`}
                      >
                        {email.subject}
                      </h3>
                      <p
                        className={`text-gray-500 dark:text-[#666666]/80 truncate ${
                          isCompact ? 'text-[9px]' : 'text-[10px]'
                        } transition-all duration-300`}
                      >
                        {cleanAndTruncatePreview(email.preview || email.snippet)}
                      </p>
                    </div>
                    <div className="flex items-center gap-2 shrink-0 mt-0.5">
                      {!email.read && (
                        <div
                          className={`${
                            isCompact ? 'w-1 h-1' : 'w-1.5 h-1.5'
                          } rounded-full bg-[#FFB27D]/80 transition-all duration-300`}
                        />
                      )}
                      <button className="text-gray-400 dark:text-[#666666]/70 hover:text-gray-900 dark:hover:text-white/90 transition-colors duration-300">
                        <svg
                          className={`${isCompact ? 'w-3 h-3' : 'w-3.5 h-3.5'} transition-all duration-300`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.25}
                            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailList;