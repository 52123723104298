import { auth } from '../firebase';

const BASE_URL = process.env.REACT_APP_API_URL || 'https://klorah-fast-server.onrender.com/api';
// const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

class EmailService {
  static BASE_URL = BASE_URL;

  static async checkGmailAuth(userId) {
    console.log('🔍 Checking Gmail auth status...');
    const currentUser = auth.currentUser;
    if (!currentUser || !userId) {
      console.log('❌ No current user or userId found');
      return { authorized: false, email: null };
    }
    const idToken = await currentUser.getIdToken();
    const response = await fetch(`${BASE_URL}/users/${userId}/profile`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!response.ok) {
      console.log('❌ Failed to check auth status');
      return { authorized: false, email: null };
    }
    const data = await response.json();
    if (!data.success || !data.email) {
      console.log('❌ Auth check unsuccessful');
      return { authorized: false, email: null };
    }
    console.log('✅ Gmail auth check successful');
    return { authorized: true, email: data.email };
  }

  static async initiateGmailAuth(userId) {
    console.log('🔑 Initiating Gmail authorization...');
    const response = await fetch(`${BASE_URL}/oauth2/gmail/authorize?user_id=${encodeURIComponent(userId)}`, {
      method: 'POST',
      headers: defaultHeaders
    });
    if (!response.ok) {
      console.log('❌ Failed to initiate Gmail auth');
      throw new Error('Failed to initiate Gmail auth');
    }
    const data = await response.json();
    if (!data.authUrl) {
      console.log('❌ No auth URL received');
      throw new Error('No authUrl in response');
    }
    console.log('🔄 Redirecting to Gmail auth page...');
    window.location.href = data.authUrl;
  }

  static async getAccounts(userId) {
    console.log('📧 Fetching email accounts...');
    const currentUser = auth.currentUser;
    if (!currentUser || !userId) {
      console.log('❌ No current user or userId found');
      return { accounts: [] };
    }
    const idToken = await currentUser.getIdToken();
    const response = await fetch(`${BASE_URL}/users/${userId}/accounts`, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!response.ok) {
      console.log('❌ Failed to fetch accounts');
      return { accounts: [] };
    }
    console.log('✅ Successfully fetched accounts');
    return await response.json();
  }

  static async getEmails(userId, accountId, options = {}) {
    console.log('📨 Fetching emails...');
    const currentUser = auth.currentUser;
    if (!currentUser || !userId) {
      console.log('❌ No current user or userId found');
      return { emails: [], nextPageToken: null, hasMore: false };
    }
    const idToken = await currentUser.getIdToken();
    const queryParams = new URLSearchParams();
    if (options.pageToken) {
      queryParams.append('page_token', options.pageToken);
    }
    if (accountId) {
      queryParams.append('account_id', accountId);
    }
    if (options.view) {
      queryParams.append('folder', options.view);
    }
    if (options.q) {
      queryParams.append('q', options.q);
    }
    const limit = options.limit || 10;
    queryParams.append('limit', String(limit));
    const url = `${BASE_URL}/emails/${userId}?${queryParams.toString()}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${idToken}`
      }
    });
    if (!res.ok) {
      const errText = await res.text();
      console.log('❌ Failed to fetch emails');
      return { error: `Failed to fetch emails: ${res.status} - ${errText}` };
    }
    console.log('✅ Successfully fetched emails');
    return await res.json();
  }

  // ----------------------------------------------
  // NEW METHOD: Fetch the full content of an email
  // ----------------------------------------------
  static async getEmailContent(userId, messageId, accountId) {
    console.log('📜 Fetching full email content...');
    
    // Validate accountId first
    if (!accountId) {
      console.error('❌ No account ID provided when fetching email content');
      throw new Error('Missing account ID. Please select an account and try again.');
    }
    
    // Validate userId and current user
    const currentUser = auth.currentUser;
    if (!currentUser || !userId) {
      console.error('❌ No current user or userId found');
      throw new Error('Authentication required. Please sign in and try again.');
    }
    
    // Validate messageId
    if (!messageId) {
      console.error('❌ No message ID provided when fetching email content');
      throw new Error('Missing email ID. Please select an email and try again.');
    }
    
    try {
      const idToken = await currentUser.getIdToken();
      
      console.log(`Fetching email with ID ${messageId} from account ${accountId}`);
      const url = `${BASE_URL}/emails/${userId}/${messageId}?account_id=${accountId}`;
      
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${idToken}`
        }
      });
      
      if (!res.ok) {
        const errText = await res.text();
        console.log('❌ Failed to fetch email content:', res.status, errText);
        
        // Provide more specific error message for common cases
        if (res.status === 404) {
          throw new Error('Email not found. It may have been deleted or may not be accessible in this account.');
        } else if (res.status === 401 || res.status === 403) {
          throw new Error('Not authorized to view this email. Please check your account permissions.');
        } else {
          throw new Error(`Failed to fetch email content: ${res.status} - ${errText}`);
        }
      }
      
      const data = await res.json();
      console.log('✅ Full email content retrieved');
      return data; // {id, snippet, payload: { html, text }, ...}
    } catch (error) {
      console.error('❌ Error in getEmailContent:', error);
      throw error;
    }
  }

  // ----------------------------------------------
  // NEW METHOD: Summarize email content using AI
  // ----------------------------------------------
  static async summarizeEmail(emailContent) {
    console.log('🤖 Generating AI summary for email...');
    
    const response = await fetch(`${BASE_URL}/ai-summarize-email`, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({ email_content: emailContent })
    });
    
    if (!response.ok) {
      const errText = await response.text();
      console.log('❌ Failed to generate email summary');
      throw new Error(`Failed to generate email summary: ${response.status} - ${errText}`);
    }
    
    const data = await response.json();
    console.log('✅ Email summary generated successfully');
    return data; // { summary: "..." }
  }

  // ----------------------------------------------
  // NEW METHOD: Mark an email as read
  // ----------------------------------------------
  static async markEmailAsRead(userId, messageId, accountId) {
    console.log('✓ Marking email as read...');
    const currentUser = auth.currentUser;
    if (!currentUser || !userId) {
      throw new Error('No current user or userId found');
    }
    const idToken = await currentUser.getIdToken();
    
    const url = `${BASE_URL}/emails/${userId}/${messageId}/read`;
    const queryParams = new URLSearchParams();
    if (accountId) {
      queryParams.append('account_id', accountId);
    }
    
    const response = await fetch(`${url}?${queryParams.toString()}`, {
      method: 'POST',
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${idToken}`
      }
    });
    
    if (!response.ok) {
      const errText = await response.text();
      console.log('❌ Failed to mark email as read');
      throw new Error(`Failed to mark email as read: ${response.status} - ${errText}`);
    }
    
    console.log('✅ Email marked as read successfully');
    return await response.json();
  }

  // ----------------------------------------------
  // Disconnect an email account (without deleting from Google)
  // ----------------------------------------------
  static async disconnectAccount(userId, accountId) {
    console.log(`Disconnecting account: ${accountId}`);
    
    if (!auth.currentUser || auth.currentUser.uid !== userId) {
      throw new Error('User not authorized');
    }
    
    try {
      const disconnectUrl = `${BASE_URL}/users/${userId}/accounts/${accountId}/disconnect`;
      
      const response = await fetch(disconnectUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to disconnect account');
      }
      
      const data = await response.json();
      console.log('Account disconnected successfully:', data);
      return { success: true, message: data.message };
      
    } catch (error) {
      console.error('Error disconnecting account:', error);
      throw new Error(error.message || 'Failed to disconnect account. Please try again.');
    }
  }

  // ----------------------------------------------
  // Complete Gmail authorization after user grants access
  // ----------------------------------------------
  static async authorizeGmail(userId, code) {
    console.log('🔄 Completing Gmail authorization...');
    
    if (!userId || !code) {
      console.error('❌ Missing userId or authorization code');
      return { success: false, error: 'Missing required parameters' };
    }

    try {
      const url = `${BASE_URL}/oauth2/gmail/callback`;
      
      // Process the authorization code
      const response = await fetch(url, {
        method: 'POST',
        headers: defaultHeaders,
        body: JSON.stringify({
          user_id: userId,
          code: code
        })
      });
      
      let responseData;
      try {
        const text = await response.text();
        responseData = JSON.parse(text);
      } catch (e) {
        console.error('Failed to parse response:', e);
        responseData = { detail: 'Invalid server response' };
      }

      if (!response.ok) {
        console.error(`❌ Gmail authorization failed: ${response.status} - ${JSON.stringify(responseData)}`);
        
        // Handle specific error cases
        if (responseData.detail?.includes('invalid_grant')) {
          return { 
            success: false, 
            error: 'Authorization expired. Please try connecting your account again.' 
          };
        }
        
        if (responseData.detail?.includes('DatetimeWithNanoseconds')) {
          // This is a server error, we can retry getting the accounts
          const accountsData = await this.getAccounts(userId);
          if (accountsData.accounts?.length > 0) {
            // Account might have been added despite the error
            return {
              success: true,
              data: accountsData,
              accountAdded: true
            };
          }
        }

        return { 
          success: false, 
          error: 'Failed to connect Gmail account. Please try again.' 
        };
      }
      
      // Get the updated accounts list
      const accountsData = await this.getAccounts(userId);
      console.log('Current accounts:', accountsData);

      if (responseData.success) {
        const email = responseData.email;
        // Check if this email was actually added to the accounts list
        const isNewAccount = accountsData.accounts?.some(account => account.email === email);
        
        if (isNewAccount) {
          console.log('✅ New Gmail account added successfully:', email);
          return { 
            success: true, 
            data: accountsData,
            accountAdded: true,
            email: email
          };
        } else {
          console.log('⚠️ Email already connected:', email);
          return {
            success: true,
            accountAdded: false,
            error: `The Gmail account ${email} is already connected.`,
            data: accountsData
          };
        }
      } else {
        console.log('❌ Authorization response indicated failure');
        return {
          success: false,
          error: responseData.detail || 'Failed to connect Gmail account',
          data: accountsData
        };
      }
    } catch (error) {
      console.error('❌ Error in authorizeGmail:', error);
      return { 
        success: false, 
        error: 'An unexpected error occurred. Please try again.' 
      };
    }
  }
}

export default EmailService;