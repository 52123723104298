import React, { useState, useEffect, createContext } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './App.css';
import { auth, onAuthStateChanged } from './firebase';
import { EmailProvider } from './contexts/EmailContext';
import { StripeProvider } from './contexts/StripeContext';
import { AuthProvider } from './contexts/AuthContext';
import { Analytics } from '@vercel/analytics/react';
import GmailCallback from './components/GmailCallback';
import AppLayout from './components/AppLayout';
import LandingPage from './pages/LandingPage';
import EmailSection from './components/EmailSection';
import ProjectDashboard from './pages/Projects/ProjectDashboard';
import ProjectDetails from './pages/Projects/ProjectDetails';
import Clients from './components/Clients';
import ProfileView from './pages/Profile/ProfileView';
import ProfileEditor from './pages/Profile/ProfileEditor';
import WelcomeProfile from './pages/Profile/WelcomeProfile';
import Agreement from './pages/Agreement';
import ErrorBoundary from './components/ErrorBoundary';
import PreviewInvoice from './pages/Invoice/PreviewInvoice';
import PaymentPage from './pages/Invoice/PaymentPage';
import PaymentConfirmation from './pages/Invoice/PaymentConfirmation';

export const ThemeContext = createContext();

const ThemeToggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);

  const handleThemeChange = () => {
    if (theme === 'dark') {
      setTheme('light');
    } else if (theme === 'light') {
      setTheme('system');
    } else {
      setTheme('dark');
    }
  };

  return (
    <button
      onClick={handleThemeChange}
      className="fixed bottom-4 left-4 z-50 bg-white/30 dark:bg-black/40 backdrop-blur-lg px-4 py-2.5 rounded-xl text-sm font-medium flex items-center gap-2 transition-all hover:bg-white/40 dark:hover:bg-white/20 border border-gray-200/20 dark:border-white/10 shadow-lg"
    >
      {theme === 'dark' && (
        <>
          <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
            <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
          </svg>
          <span className="text-white">Dark</span>
        </>
      )}
      {theme === 'light' && (
        <>
          <svg className="w-4 h-4 text-black" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
          </svg>
          <span className="text-black">Light</span>
        </>
      )}
      {theme === 'system' && (
        <>
          <svg className="w-4 h-4 text-gray-900 dark:text-white" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clipRule="evenodd" />
          </svg>
          <span className="text-gray-900 dark:text-white">System</span>
        </>
      )}
    </button>
  );
};

const ProtectedRoute = ({ children, currentUser }) => {
  if (!currentUser) {
    return <Navigate to="/" />;
  }
  return children;
};

const PublicRoute = ({ children, currentUser }) => {
  if (currentUser) {
    return <Navigate to="/app/emails" />;
  }
  return children;
};

const RootLayout = ({ children, currentUser }) => {
  const [theme, setTheme] = useState(() => {
    if (typeof window !== 'undefined') {
      const savedTheme = localStorage.getItem('theme');
      if (savedTheme) {
        return savedTheme;
      }
      return 'system';
    }
    return 'system';
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleThemeChange = () => {
      if (theme === 'system') {
        const root = window.document.documentElement;
        root.classList.remove('light', 'dark');
        root.classList.add(mediaQuery.matches ? 'dark' : 'light');
      }
    };

    mediaQuery.addEventListener('change', handleThemeChange);
    
    // Initial setup
    if (theme === 'system') {
      const root = window.document.documentElement;
      root.classList.remove('light', 'dark');
      root.classList.add(mediaQuery.matches ? 'dark' : 'light');
    } else {
      const root = window.document.documentElement;
      root.classList.remove('light', 'dark');
      root.classList.add(theme);
    }

    localStorage.setItem('theme', theme);

    return () => {
      mediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <AuthProvider currentUser={currentUser}>
        <EmailProvider user={currentUser}>
          <StripeProvider user={currentUser}>
            {children}
            <ThemeToggle />
            <Toaster position="top-right" />
            <Analytics />
          </StripeProvider>
        </EmailProvider>
      </AuthProvider>
    </ThemeContext.Provider>
  );
};

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <RootLayout currentUser={currentUser}>
          <PublicRoute currentUser={currentUser}>
            <LandingPage />
          </PublicRoute>
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />
    },
    {
      path: '/profile/:username',
      element: (
        <RootLayout currentUser={currentUser}>
          <ProfileView />
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />
    },
    {
      path: '/app',
      element: (
        <RootLayout currentUser={currentUser}>
          <ProtectedRoute currentUser={currentUser}>
            <AppLayout />
          </ProtectedRoute>
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: 'emails',
          element: <EmailSection user={currentUser} />
        },
        {
          path: 'projects',
          children: [
            {
              index: true,
              element: <ProjectDashboard />
            },
            {
              path: ':projectId',
              element: <ProjectDetails />
            }
          ]
        },
        {
          path: 'clients',
          element: <Clients />
        },
        {
          path: 'profile',
          element: <ProfileView />
        },
        {
          path: 'profile/edit',
          element: <ProfileEditor />
        },
        {
          path: 'profile/welcome',
          element: <WelcomeProfile />
        },
        {
          index: true,
          element: <Navigate to="/app/emails" replace />
        }
      ]
    },
    {
      path: '/agreement/:agreementId',
      element: (
        <RootLayout currentUser={currentUser}>
          <Agreement />
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />
    },
    {
      path: '/preview-invoice/:invoiceId',
      element: (
        <RootLayout currentUser={currentUser}>
          <PreviewInvoice />
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />
    },
    {
      path: '/payment/:invoiceId',
      element: (
        <RootLayout currentUser={currentUser}>
          <PaymentPage />
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />
    },
    {
      path: '/payment-confirmation/:invoiceId',
      element: (
        <RootLayout currentUser={currentUser}>
          <PaymentConfirmation />
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />
    },
    {
      path: '/gmail/callback',
      element: (
        <RootLayout currentUser={currentUser}>
          <GmailCallback />
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />
    },
    {
      path: '*',
      element: (
        <RootLayout currentUser={currentUser}>
          {currentUser ? <Navigate to="/app/emails" /> : <Navigate to="/" />}
        </RootLayout>
      ),
      errorElement: <ErrorBoundary />
    }
  ]);

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen text-white">Loading...</div>;
  }

  return <RouterProvider router={router} />;
}

export default App;