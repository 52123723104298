import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../../firebase';
import { toast } from 'react-hot-toast';

// Footer Component
const Footer = () => (
  <div className="mt-12 pt-6 border-t border-gray-200 dark:border-[#333333] text-center text-sm text-gray-500">
    Powered by{" "}
    <a 
      href="https://klorah.com" 
      target="_blank" 
      rel="noopener noreferrer"
      className="font-medium hover:underline"
    >
      Klorah
    </a>
  </div>
);

const ProfileEditor = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [profile, setProfile] = useState({
    name: '',
    username: '',
    professionalTitle: '',
    bio: '',
    profilePicture: '',
    services: [],
    portfolio: [],
    contactInfo: {
      email: '',
      phone: '',
      location: ''
    }
  });

  useEffect(() => {
    const fetchProfile = async () => {
      if (!auth.currentUser) {
        navigate('/');
        return;
      }

      try {
        const profileRef = doc(db, 'profiles', auth.currentUser.uid);
        const profileSnap = await getDoc(profileRef);
        
        if (profileSnap.exists()) {
          setProfile(profileSnap.data());
        } else {
          navigate('/app/profile/welcome');
          return;
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast.error('Error loading profile');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const storageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setProfile(prev => ({ ...prev, profilePicture: url }));
      toast.success('Profile picture uploaded');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading profile picture');
    }
  };

  const handleServiceImageUpload = async (file, index) => {
    if (!file) return null;

    try {
      const storageRef = ref(storage, `services/${auth.currentUser.uid}/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error('Error uploading service image:', error);
      toast.error('Error uploading service image');
      return null;
    }
  };

  const handlePortfolioImageUpload = async (file, index) => {
    if (!file) return null;

    try {
      const storageRef = ref(storage, `portfolio/${auth.currentUser.uid}/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error('Error uploading portfolio image:', error);
      toast.error('Error uploading portfolio image');
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      toast.error('Please sign in to update your profile');
      return;
    }

    setSaving(true);
    try {
      await setDoc(doc(db, 'profiles', auth.currentUser.uid), {
        ...profile,
        username: profile.username.toLowerCase(),
        updatedAt: new Date().toISOString()
      });
      
      toast.success('Profile updated successfully!');
      navigate('/profile');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error updating profile');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-gray-300 border-t-gray-800"></div>
      </div>
    );
  }

  return (
    <div className="py-12 px-6 min-h-screen">
      <div className="max-w-3xl mx-auto">
        <div className="mb-16 space-y-2">
          <h1 className="text-4xl font-light">Edit Profile</h1>
          <p className="text-gray-600 dark:text-gray-400">Update your professional information and settings</p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-12">
          {/* Basic Info Section */}
          <section className="space-y-8">
            <h2 className="text-2xl font-light">Basic Information</h2>

            <div className="space-y-8">
              <div>
                <label className="block text-sm mb-3">Profile Picture</label>
                <div className="flex items-center gap-8">
                  <div className="w-28 h-28 rounded-full bg-gray-100 dark:bg-gray-900 flex items-center justify-center text-3xl font-light">
                    {profile.profilePicture ? (
                      <img
                        src={profile.profilePicture}
                        alt="Profile"
                        className="w-full h-full rounded-full object-cover"
                      />
                    ) : (
                      profile.name?.[0]?.toUpperCase() || '?'
                    )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="text-sm"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-8">
                <div>
                  <label className="block text-sm mb-3">Full Name</label>
                  <input
                    type="text"
                    value={profile.name}
                    onChange={(e) => setProfile(prev => ({ ...prev, name: e.target.value }))}
                    className="w-full px-4 py-3 bg-transparent border border-gray-200 dark:border-gray-800 rounded-lg focus:outline-none focus:border-gray-400"
                    placeholder="John Doe"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm mb-3">Username</label>
                  <input
                    type="text"
                    value={profile.username}
                    onChange={(e) => setProfile(prev => ({ ...prev, username: e.target.value }))}
                    className="w-full px-4 py-3 bg-transparent border border-gray-200 dark:border-gray-800 rounded-lg focus:outline-none focus:border-gray-400"
                    placeholder="johndoe"
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm mb-3">Professional Title</label>
                <input
                  type="text"
                  value={profile.professionalTitle}
                  onChange={(e) => setProfile(prev => ({ ...prev, professionalTitle: e.target.value }))}
                  className="w-full px-4 py-3 bg-transparent border border-gray-200 dark:border-gray-800 rounded-lg focus:outline-none focus:border-gray-400"
                  placeholder="Software Engineer"
                />
              </div>

              <div>
                <label className="block text-sm mb-3">Bio</label>
                <textarea
                  value={profile.bio}
                  onChange={(e) => setProfile(prev => ({ ...prev, bio: e.target.value }))}
                  className="w-full px-4 py-3 bg-transparent border border-gray-200 dark:border-gray-800 rounded-lg focus:outline-none focus:border-gray-400 h-32"
                  placeholder="Tell clients about yourself..."
                />
              </div>
            </div>
          </section>

          {/* Contact Info Section */}
          <section className="space-y-8">
            <h2 className="text-2xl font-light">Contact Information</h2>

            <div className="grid grid-cols-2 gap-8">
              <div>
                <label className="block text-sm mb-3">Email</label>
                <input
                  type="email"
                  value={profile.contactInfo?.email || ''}
                  onChange={(e) => setProfile(prev => ({
                    ...prev,
                    contactInfo: { ...prev.contactInfo, email: e.target.value }
                  }))}
                  className="w-full px-4 py-3 bg-transparent border border-gray-200 dark:border-gray-800 rounded-lg focus:outline-none focus:border-gray-400"
                  placeholder="email@example.com"
                />
              </div>

              <div>
                <label className="block text-sm mb-3">Phone</label>
                <input
                  type="tel"
                  value={profile.contactInfo?.phone || ''}
                  onChange={(e) => setProfile(prev => ({
                    ...prev,
                    contactInfo: { ...prev.contactInfo, phone: e.target.value }
                  }))}
                  className="w-full px-4 py-3 bg-transparent border border-gray-200 dark:border-gray-800 rounded-lg focus:outline-none focus:border-gray-400"
                  placeholder="+1 (123) 456-7890"
                />
              </div>

              <div className="col-span-2">
                <label className="block text-sm mb-3">Location</label>
                <input
                  type="text"
                  value={profile.contactInfo?.location || ''}
                  onChange={(e) => setProfile(prev => ({
                    ...prev,
                    contactInfo: { ...prev.contactInfo, location: e.target.value }
                  }))}
                  className="w-full px-4 py-3 bg-transparent border border-gray-200 dark:border-gray-800 rounded-lg focus:outline-none focus:border-gray-400"
                  placeholder="City, Country"
                />
              </div>
            </div>
          </section>

          <div className="flex justify-end gap-4 pt-8">
            <button
              type="button"
              onClick={() => navigate('/app/profile')}
              className="px-8 py-3 text-sm font-medium border border-gray-200 dark:border-gray-800 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={saving}
              className="px-8 py-3 text-sm font-medium bg-gray-900 dark:bg-white text-white dark:text-gray-900 rounded-lg hover:bg-gray-800 dark:hover:bg-gray-100"
            >
              {saving ? (
                <div className="flex items-center gap-2">
                  <div className="animate-spin rounded-full h-4 w-4 border-2 border-white dark:border-gray-900 border-t-transparent"></div>
                  Saving...
                </div>
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
          
          <Footer />
        </form>
      </div>
    </div>
  );
};

export default ProfileEditor;
