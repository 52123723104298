import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { IoMdClipboard } from 'react-icons/io';
import { toast } from 'react-hot-toast';
import InvoicePreview from '../../components/InvoicePreview';

export default function PreviewInvoice() {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPreview, setShowPreview] = useState(true);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      setIsLoading(true);
      try {
        const firestore = getFirestore();
        const invoiceRef = doc(firestore, "invoices", invoiceId);
        const docSnap = await getDoc(invoiceRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // Safely parse the dueDate if it exists
          if (data.dueDate) {
            try {
              // If it's a Firestore Timestamp
              if (data.dueDate.toDate) {
                data.dueDate = data.dueDate.toDate();
              } else {
                data.dueDate = new Date(data.dueDate);
              }
            } catch (err) {
              data.dueDate = null;
            }
          }
          setInvoiceDetails(data);
        } else {
          throw new Error("Invoice not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInvoiceDetails();
  }, [invoiceId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen text-white">
        <p>Loading invoice...</p>
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen text-red-500">
        <p>Error: {error}</p>
      </div>
    );
  }
  if (!invoiceDetails) {
    return (
      <div className="flex items-center justify-center min-h-screen text-[#999999]">
        <p>No invoice details found.</p>
      </div>
    );
  }

  const statusColor = invoiceDetails.status === 'paid' ? 'text-green-400' : 'text-red-400';

  return (
    <div className="min-h-screen bg-[#111111] text-white p-6">
      <div className="max-w-3xl mx-auto bg-[#1A1A1A] border border-[#333] rounded-lg p-6">
        <div className="flex flex-col md:flex-row items-center md:justify-between mb-6">
          <h1 className="text-xl font-semibold">Invoice Details</h1>
          <span className={`px-3 py-1 mt-3 md:mt-0 rounded-full text-sm font-medium ${statusColor}`}>
            {invoiceDetails.status ? invoiceDetails.status.toUpperCase() : 'PENDING'}
          </span>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
          <div>
            <p className="text-sm text-[#999999]">Invoice ID</p>
            <p className="font-mono">{invoiceId}</p>
          </div>
          <div>
            <p className="text-sm text-[#999999]">Amount</p>
            <p>{invoiceDetails.currency} {invoiceDetails.amount || invoiceDetails.price}</p>
          </div>
          <div>
            <p className="text-sm text-[#999999]">Project</p>
            <p>{invoiceDetails.project || invoiceDetails.gig}</p>
          </div>
          <div>
            <p className="text-sm text-[#999999]">Due Date</p>
            <p>
              {invoiceDetails.dueDate
                ? invoiceDetails.dueDate.toLocaleDateString()
                : 'Not specified'
              }
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-4">
          <button
            onClick={() => setShowPreview(!showPreview)}
            className="px-4 py-2 border border-[#333] rounded-lg text-sm hover:bg-[#2A2A2A]"
          >
            {showPreview ? 'Hide Preview' : 'Show Preview'}
          </button>
          {invoiceDetails.status !== 'paid' && (
            <button
              onClick={() => navigate(`/payment/${invoiceId}`)}
              className="px-4 py-2 bg-[#FFB27D] text-black rounded-lg text-sm hover:bg-[#FFA164]"
            >
              Proceed to Payment
            </button>
          )}
          <button
            onClick={() => {
              const link = `${window.location.origin}/payment/${invoiceId}`;
              navigator.clipboard.writeText(link).then(() => {
                toast.success("Payment link copied to clipboard");
              });
            }}
            className="px-4 py-2 border border-[#333] rounded-lg text-sm hover:bg-[#2A2A2A] flex items-center"
          >
            <IoMdClipboard className="mr-2" />
            Copy Payment Link
          </button>
        </div>

        {showPreview && (
          <div className="mt-6">
            <InvoicePreview
              invoiceData={invoiceDetails}
              invoiceId={invoiceId}
            />
          </div>
        )}
      </div>
    </div>
  );
}