import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, deleteDoc, getFirestore } from 'firebase/firestore';

const DocumentsList = ({ documents, type, onClose, onCreateNew }) => {
  const modalContentRef = useRef(null);
  const navigate = useNavigate();

  const [selectedDocs, setSelectedDocs] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showMobileActions, setShowMobileActions] = useState(false);

  // Close the modal if user clicks outside `modalContentRef`
  useEffect(() => {
    function handleClickOutside(e) {
      if (
        modalContentRef.current && 
        !modalContentRef.current.contains(e.target)
      ) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  // Toggle the selection of one document
  const toggleDocSelection = (e, docId) => {
    e.stopPropagation();
    setSelectedDocs(prev => ({
      ...prev,
      [docId]: !prev[docId]
    }));
  };

  // ==============================
  //    PROCESS / FORMAT DOCS
  // ==============================
  const formatDate = (dateString) => {
    if (!dateString) return null;
    try {
      const d = new Date(dateString);
      return isNaN(d.getTime())
        ? null
        : d.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          });
    } catch {
      return null;
    }
  };

  const extractClientName = (agreementName) => {
    if (!agreementName) return 'Client';
    const match = agreementName.match(/Agreement with\s+([^-]+)/i);
    return match && match[1] ? match[1].trim() : 'Client';
  };

  const processedDocuments = (documents || []).map(docItem => {
    const processed = { ...docItem };

    if (type === 'invoices') {
      processed.title = processed.gig || processed.title || processed.project || 'Client Photography';
      processed.client = processed.customer || processed.client || processed.clientName || 'Client';
      processed.clientEmail = processed.customerEmail || processed.email || '';
      processed.amount = processed.price || processed.amount || 0;
      processed.currency = processed.currency || 'USD';
      processed.status = processed.status || 'pending';
      processed.formattedDate = formatDate(processed.dueDate) || formatDate(processed.created);
    } else if (type === 'agreements') {
      processed.title = processed.name || processed.title || 'Agreement';
      processed.client = extractClientName(processed.title);
      processed.amount = processed.amount || '0';
      processed.formattedDate = (
        formatDate(processed.date) ||
        formatDate(processed.created) ||
        formatDate(processed.createdAt)
      );
      // Default status:
      processed.status = 'Draft';
      // If the agreement has text, mark it as 'Created'
      if (processed.text && processed.text.length > 0) {
        processed.status = 'Created';
      }
      // Check if it's signed
      if (
        processed.freelancerSignature ||
        processed.clientSignature ||
        processed.signed === true ||
        processed.signed === 'true' ||
        (processed.status && processed.status.toLowerCase() === 'signed')
      ) {
        processed.status = 'Signed';
      } else if (
        processed.signed === false ||
        processed.signed === 'false' ||
        (processed.status && processed.status.toLowerCase() === 'unsigned')
      ) {
        processed.status = 'Unsigned';
      }
    }
    // For projects or other doc types, adapt as needed
    return processed;
  });

  // ==============================
  //   SELECT / DESELECT ALL DOCS
  // ==============================
  const selectedCount = Object.values(selectedDocs).filter(Boolean).length;
  const toggleSelectAll = () => {
    const allSelectedNow = selectedCount === processedDocuments.length;
    if (allSelectedNow) {
      setSelectedDocs({});
    } else {
      const newSelections = {};
      processedDocuments.forEach(d => {
        if (d.id) newSelections[d.id] = true;
      });
      setSelectedDocs(newSelections);
    }
  };

  // ==============================
  //     DELETE SELECTED DOCS
  // ==============================
  const deleteSelectedDocuments = async () => {
    setIsDeleting(true);
    const firestore = getFirestore();
    const collection = 
      type === 'invoices' ? 'invoices' :
      type === 'agreements' ? 'agreements' : 
      'projects'; 

    try {
      const deletePromises = Object.entries(selectedDocs)
        .filter(([, isSelected]) => isSelected)
        .map(([docId]) => 
          deleteDoc(doc(firestore, collection, docId))
        );
      await Promise.all(deletePromises);

      // Reset state, but do NOT close the entire modal
      setSelectedDocs({});
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error('Error deleting documents:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  // ==============================
  //        STATUS STYLING
  // ==============================
  const getStatusClasses = (status = '') => {
    const s = status.toLowerCase();
    if (['paid', 'completed', 'signed'].includes(s)) {
      return 'bg-green-900/30 text-green-400';
    } else if (['pending', 'in progress', 'draft', 'unsigned', 'created'].includes(s)) {
      return 'bg-amber-900/30 text-amber-400';
    } else {
      return 'bg-red-900/30 text-red-400';
    }
  };

  // ==============================
  //        VIEW DOCUMENT
  // ==============================
  const handleViewDocument = (documentId) => {
    if (type === 'agreements') {
      navigate(`/agreement/${documentId}`);
    } else if (type === 'invoices') {
      navigate(`/preview-invoice/${documentId}`);
    }
    // Possibly others
    onClose(); // closes the main modal if user just wants to go see doc
  };

  // ==============================
  //       RENDER DOC CARDS
  // ==============================
  const renderDocuments = () => {
    if (type === 'invoices') {
      return processedDocuments.map((invoice, idx) => (
        <div 
          key={invoice.id || idx}
          className={`bg-gray-50 dark:bg-[#1A1A1A] rounded-xl p-4 md:p-6 hover:bg-gray-100 dark:hover:bg-[#252525] transition-all duration-300 shadow-lg
            ${selectedDocs[invoice.id] ? 'border-2 border-[#FFB27D]' : 'border-2 border-transparent'}`}
        >
          <div className="flex flex-col md:flex-row justify-between items-start gap-4 md:gap-6">
            {/* Left side: Title + client */}
            <div className="flex items-center gap-4 w-full md:w-auto">
              {invoice.id && (
                <div 
                  className={`w-6 h-6 rounded-lg flex items-center justify-center border-2 cursor-pointer transition-all duration-300
                    ${selectedDocs[invoice.id]
                      ? 'bg-[#FFB27D] border-[#FFB27D]'
                      : 'border-gray-400 dark:border-gray-600 hover:border-gray-600 dark:hover:border-gray-400'}`}
                  onClick={(e) => toggleDocSelection(e, invoice.id)}
                >
                  {selectedDocs[invoice.id] && (
                    <svg 
                      className="w-4 h-4 text-black"
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M5 13l4 4L19 7" 
                      />
                    </svg>
                  )}
                </div>
              )}
              <div className="flex-1">
                <h3 className="text-gray-900 dark:text-white text-lg font-medium tracking-tight mb-1">
                  {invoice.title}
                </h3>
                <p className="text-gray-500 dark:text-[#999999]">
                  {invoice.clientEmail || invoice.client}
                </p>
              </div>
            </div>
            {/* Right side: Amount + date + status */}
            <div className="text-left md:text-right w-full md:w-auto">
              <div className="text-gray-900 dark:text-white text-lg font-medium tracking-tight mb-1">
                {invoice.currency} {invoice.amount}
              </div>
              {invoice.formattedDate && (
                <div className="text-gray-500 dark:text-[#999999] mb-2">
                  {invoice.formattedDate}
                </div>
              )}
              <span
                className={`inline-block px-3 py-1.5 rounded-full text-xs font-medium tracking-wide ${getStatusClasses(invoice.status)}`}
              >
                {invoice.status}
              </span>
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button 
              onClick={() => handleViewDocument(invoice.id)} 
              className="text-sm text-[#FFB27D] font-medium hover:text-[#FFA164] transition-colors px-4 py-2 rounded-lg hover:bg-[#FFB27D]/10"
            >
              View Details
            </button>
          </div>
        </div>
      ));
    } 
    else if (type === 'agreements') {
      return processedDocuments.map((agreement, idx) => (
        <div 
          key={agreement.id || idx}
          className={`bg-gray-50 dark:bg-[#1A1A1A] rounded-xl p-4 md:p-6 hover:bg-gray-100 dark:hover:bg-[#252525] transition-all duration-300 shadow-lg
            ${selectedDocs[agreement.id] ? 'border-2 border-[#FFB27D]' : 'border-2 border-transparent'}`}
        >
          <div className="flex flex-col md:flex-row justify-between items-start gap-4 md:gap-6">
            {/* Left side */}
            <div className="flex items-center gap-4 w-full md:w-auto">
              {agreement.id && (
                <div 
                  className={`w-6 h-6 rounded-lg flex items-center justify-center border-2 cursor-pointer transition-all duration-300
                    ${selectedDocs[agreement.id]
                      ? 'bg-[#FFB27D] border-[#FFB27D]'
                      : 'border-gray-400 dark:border-gray-600 hover:border-gray-600 dark:hover:border-gray-400'}`}
                  onClick={(e) => toggleDocSelection(e, agreement.id)}
                >
                  {selectedDocs[agreement.id] && (
                    <svg 
                      className="w-4 h-4 text-black" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M5 13l4 4L19 7" 
                      />
                    </svg>
                  )}
                </div>
              )}
              <div className="flex-1">
                <h3 className="text-gray-900 dark:text-white text-lg font-medium tracking-tight mb-1">{agreement.title}</h3>
                <p className="text-gray-500 dark:text-[#999999]">{agreement.client}</p>
                {agreement.status?.toLowerCase() === 'unsigned' && (
                  <p className="text-red-500 mt-2 text-sm font-medium">
                    Pending signature
                  </p>
                )}
              </div>
            </div>
            {/* Right side */}
            <div className="text-left md:text-right w-full md:w-auto">
              {agreement.amount && (
                <div className="text-gray-900 dark:text-white text-lg font-medium tracking-tight mb-1">
                  USD {agreement.amount}
                </div>
              )}
              {agreement.formattedDate && (
                <div className="text-gray-500 dark:text-[#999999] mb-2">
                  {agreement.formattedDate}
                </div>
              )}
              <span 
                className={`inline-block px-3 py-1.5 rounded-full text-xs font-medium tracking-wide ${getStatusClasses(agreement.status)}`}
              >
                {agreement.status}
              </span>
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button 
              onClick={() => handleViewDocument(agreement.id)} 
              className="text-sm text-[#FFB27D] font-medium hover:text-[#FFA164] transition-colors px-4 py-2 rounded-lg hover:bg-[#FFB27D]/10"
            >
              View Details
            </button>
          </div>
        </div>
      ));
    } 
    else {
      // For 'projects' or other doc types
      return processedDocuments.map((p, idx) => (
        <div 
          key={p.id || idx}
          className={`bg-gray-50 dark:bg-[#1A1A1A] rounded-xl p-4 md:p-6 hover:bg-gray-100 dark:hover:bg-[#252525] transition-all duration-300 shadow-lg
            ${selectedDocs[p.id] ? 'border-2 border-[#FFB27D]' : 'border-2 border-transparent'}`}
        >
          <div className="flex flex-col md:flex-row justify-between items-start gap-4 md:gap-6">
            <div className="flex items-center gap-4 w-full md:w-auto">
              {p.id && (
                <div 
                  className={`w-6 h-6 rounded-lg flex items-center justify-center border-2 cursor-pointer transition-all duration-300
                    ${selectedDocs[p.id]
                      ? 'bg-[#FFB27D] border-[#FFB27D]'
                      : 'border-gray-400 dark:border-gray-600 hover:border-gray-600 dark:hover:border-gray-400'}`}
                  onClick={(e) => toggleDocSelection(e, p.id)}
                >
                  {selectedDocs[p.id] && (
                    <svg 
                      className="w-4 h-4 text-black" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M5 13l4 4L19 7" 
                      />
                    </svg>
                  )}
                </div>
              )}
              <div className="flex-1">
                <h3 className="text-gray-900 dark:text-white text-lg font-medium tracking-tight mb-1">
                  {p.name || p.projectName || 'Project'}
                </h3>
                <p className="text-gray-500 dark:text-[#999999]">{p.client}</p>
              </div>
            </div>
            <div className="text-left md:text-right w-full md:w-auto">
              {p.startDate && (
                <div className="text-gray-500 dark:text-[#999999] mb-2">
                  Started: {formatDate(p.startDate)}
                </div>
              )}
              <span 
                className={`inline-block px-3 py-1.5 rounded-full text-xs font-medium tracking-wide ${getStatusClasses(p.status)}`}
              >
                {p.status || 'In Progress'}
              </span>
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button 
              onClick={() => handleViewDocument(p.id)} 
              className="text-sm text-[#FFB27D] font-medium hover:text-[#FFA164] transition-colors px-4 py-2 rounded-lg hover:bg-[#FFB27D]/10"
            >
              View Details
            </button>
          </div>
        </div>
      ));
    }
  };

  // ==============================
  // RENDER COMPONENT
  // ==============================
  // If no documents at all
  if (!documents || documents.length === 0) {
    return (
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
        <div 
          ref={modalContentRef}
          className="bg-[#111111] border-2 border-[#1A1A1A] rounded-xl w-full max-w-3xl max-h-[90vh] overflow-hidden shadow-xl"
        >
          <div className="flex items-center justify-between p-4 md:p-6 border-b-2 border-[#1A1A1A]">
            <div className="flex items-center gap-4">
              <h2 className="text-lg md:text-xl font-medium text-white tracking-tight">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </h2>
              <button
                onClick={onCreateNew}
                className="text-sm font-medium text-white bg-[#1A1A1A] hover:bg-[#FFB27D]/10 px-3 md:px-4 py-2 rounded-lg flex items-center gap-2 transition-all duration-300"
              >
                <svg 
                  className="h-4 w-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={1.5} 
                    d="M12 4v16m8-8H4" 
                  />
                </svg>
                <span className="hidden md:inline">New {type === 'invoices' ? 'Invoice' : 'Agreement'}</span>
              </button>
            </div>
            <button onClick={onClose} className="text-[#666666] hover:text-white transition-colors p-2 hover:bg-[#252525] rounded-lg">
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={1.5} 
                  d="M6 18L18 6M6 6l12 12" 
                />
              </svg>
            </button>
          </div>
          <div className="p-6 md:p-12 text-center">
            <div className="mb-6 mx-auto w-16 md:w-20 h-16 md:h-20 bg-[#1A1A1A] rounded-2xl flex items-center justify-center">
              <svg 
                className="h-8 md:h-10 w-8 md:w-10 text-[#666666]" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={1.5} 
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
                />
              </svg>
            </div>
            <h3 className="text-xl md:text-2xl font-medium mb-3 text-white tracking-tight">
              No {type} found
            </h3>
            <p className="text-[#AAAAAA] text-base md:text-lg mb-6">
              You haven&apos;t created any {type} yet.
            </p>
            <button
              onClick={onCreateNew}
              className="text-base font-medium text-white bg-[#FFB27D] hover:bg-[#FFB27D]/90 px-6 py-3 rounded-xl transition-all duration-300 shadow-lg"
            >
              Create Your First {type === 'invoices' ? 'Invoice' : 'Agreement'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  // If we DO have docs
  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div 
        ref={modalContentRef}
        className="relative bg-white dark:bg-[#111111] border-2 border-gray-200 dark:border-[#1A1A1A] rounded-xl w-full max-w-3xl max-h-[90vh] overflow-hidden shadow-xl"
      >
        {/* Top Bar */}
        <div className="flex items-center justify-between p-4 md:p-6 border-b-2 border-gray-200 dark:border-[#1A1A1A]">
          <div className="flex items-center gap-4">
            <h2 className="text-lg md:text-xl font-medium text-gray-900 dark:text-white tracking-tight">
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </h2>
            
            {/* Desktop Actions */}
            <div className="hidden md:flex items-center gap-3">
              <button
                onClick={onCreateNew}
                className="text-sm font-medium text-gray-900 dark:text-white bg-gray-100 dark:bg-[#1A1A1A] hover:bg-gray-200 dark:hover:bg-[#FFB27D]/10 px-4 py-2 rounded-lg transition-all duration-300 flex items-center gap-2"
              >
                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 4v16m8-8H4" />
                </svg>
                <span>New {type === 'invoices' ? 'Invoice' : type === 'agreements' ? 'Agreement' : 'Project'}</span>
              </button>

              <button
                onClick={toggleSelectAll}
                className="text-sm font-medium text-gray-900 dark:text-white bg-gray-100 dark:bg-[#1A1A1A] hover:bg-gray-200 dark:hover:bg-[#2A2A2A] px-4 py-2 rounded-lg transition-all duration-300"
              >
                {selectedCount === processedDocuments.length && processedDocuments.length > 0 ? 'Deselect All' : 'Select All'}
              </button>

              {selectedCount > 0 && (
                <button
                  onClick={() => setShowDeleteConfirm(true)}
                  className="text-sm font-medium text-white bg-red-600/20 hover:bg-red-600/30 px-4 py-2 rounded-lg transition-all duration-300 flex items-center gap-2"
                >
                  <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                  <span>Delete ({selectedCount})</span>
                </button>
              )}
            </div>

            {/* Mobile Actions Button */}
            <button
              onClick={() => setShowMobileActions(!showMobileActions)}
              className="md:hidden text-white bg-[#1A1A1A] hover:bg-[#2A2A2A] p-2 rounded-lg"
            >
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={1.5} 
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>
          </div>

          <button 
            onClick={onClose}
            className="text-gray-500 dark:text-[#666666] hover:text-gray-900 dark:hover:text-white transition-colors p-2 hover:bg-gray-100 dark:hover:bg-[#252525] rounded-lg"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Mobile Actions Menu */}
        {showMobileActions && (
          <div className="md:hidden absolute top-[72px] right-4 bg-[#1A1A1A] rounded-xl shadow-lg border-2 border-[#252525] p-2 z-20">
            <button
              onClick={() => {
                onCreateNew();
                setShowMobileActions(false);
              }}
              className="w-full text-left text-sm font-medium text-white hover:bg-[#252525] px-4 py-2 rounded-lg transition-all duration-300 flex items-center gap-2"
            >
              <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={1.5} 
                  d="M12 4v16m8-8H4" 
                />
              </svg>
              <span>New {type === 'invoices' ? 'Invoice' : type === 'agreements' ? 'Agreement' : 'Project'}</span>
            </button>
            
            <button
              onClick={() => {
                toggleSelectAll();
                setShowMobileActions(false);
              }}
              className="w-full text-left text-sm font-medium text-white hover:bg-[#252525] px-4 py-2 rounded-lg transition-all duration-300"
            >
              {selectedCount === processedDocuments.length && processedDocuments.length > 0 
                ? 'Deselect All' 
                : 'Select All'}
            </button>

            {selectedCount > 0 && (
              <button
                onClick={() => {
                  setShowDeleteConfirm(true);
                  setShowMobileActions(false);
                }}
                className="w-full text-left text-sm font-medium text-red-400 hover:bg-[#252525] px-4 py-2 rounded-lg transition-all duration-300 flex items-center gap-2"
              >
                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={1.5} 
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                  />
                </svg>
                <span>Delete ({selectedCount})</span>
              </button>
            )}
          </div>
        )}

        {/* Document Cards */}
        <div className="p-4 md:p-6 overflow-y-auto max-h-[calc(90vh-90px)] space-y-4">
          {renderDocuments()}
        </div>

        {/* Delete confirmation modal */}
        {showDeleteConfirm && (
          <div className="absolute inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-10">
            <div className="bg-white dark:bg-[#1A1A1A] border-2 border-gray-200 dark:border-[#333] rounded-xl p-6 md:p-8 max-w-md w-full shadow-xl">
              <h3 className="text-xl md:text-2xl font-medium text-gray-900 dark:text-white mb-4 tracking-tight">
                Delete {selectedCount} {type}
              </h3>
              <p className="text-gray-600 dark:text-gray-300 text-base md:text-lg mb-6 md:mb-8">
                Are you sure you want to delete {selectedCount === 1 ? 'this' : 'these'} {selectedCount} {selectedCount === 1 ? type.slice(0, -1) : type}? This action cannot be undone.
              </p>
              <div className="flex gap-4 justify-end">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-6 py-3 rounded-xl bg-gray-100 dark:bg-[#333] text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-[#444] transition-all duration-300 text-base font-medium"
                  disabled={isDeleting}
                >
                  Cancel
                </button>
                <button
                  onClick={deleteSelectedDocuments}
                  className="px-6 py-3 rounded-xl bg-red-600 text-white hover:bg-red-700 transition-all duration-300 flex items-center gap-2 text-base font-medium"
                  disabled={isDeleting}
                >
                  {isDeleting ? (
                    <>
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                      </svg>
                      Deleting...
                    </>
                  ) : (
                    'Delete'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentsList;