import React, { useState } from 'react';
import { useStripe } from '../contexts/StripeContext';

export default function StripeOnboardingBanner() {
  const { isOnboarded, isLoading: contextLoading, startOnboarding } = useStripe();
  const [isDismissed, setIsDismissed] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  if (contextLoading || isOnboarded || isDismissed) return null;

  const handleOnboardingClick = async () => {
    setError(null);
    setIsLoading(true);
    try {
      await startOnboarding();
    } catch (err) {
      setError(err.message);
      console.error('Error starting onboarding:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const isServerStarting = error?.includes('server is currently starting up');

  return (
    <div className="fixed bottom-4 right-4 left-4 md:left-auto md:max-w-sm bg-black rounded-lg shadow-lg z-50 border border-gray-800">
      <div className="p-4">
        <button
          onClick={() => setIsDismissed(true)}
          className="absolute top-3 right-3 text-gray-400 hover:text-[#FFB27D]"
        >
          ✕
        </button>

        <div className="mb-3">
          <h3 className="text-base font-semibold text-white">Enable payments</h3>
          <p className="text-sm text-[#999999]">Connect Stripe to accept payments</p>
        </div>

        {error && (
          <div className="mb-3 p-2 bg-red-900/50 border border-red-700 rounded text-sm text-red-200 flex flex-col gap-2">
            <p>{error}</p>
            {isServerStarting && (
              <button
                onClick={handleOnboardingClick}
                className="text-left text-[#FFB27D] hover:text-[#FFB27D]/80 font-medium"
              >
                Try again →
              </button>
            )}
          </div>
        )}

        <div className="flex gap-2">
          <button
            onClick={handleOnboardingClick}
            disabled={isLoading}
            className={`flex-1 px-4 py-2 bg-[#FFB27D] text-white text-sm font-medium rounded-lg ${
              isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#FFB27D]/90'
            } transition-all duration-200 flex items-center justify-center`}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {isServerStarting ? 'Retrying...' : 'Connecting...'}
              </>
            ) : (
              'Connect Stripe'
            )}
          </button>
          <button
            onClick={() => setIsDismissed(true)}
            disabled={isLoading}
            className={`px-4 py-2 text-sm font-medium text-[#999999] ${
              isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:text-white'
            } transition-colors duration-200`}
          >
            Later
          </button>
        </div>
      </div>
    </div>
  );
}