import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import toast from 'react-hot-toast';

export default function BookingModal({ isOpen, onClose, service, profileId }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const bookingData = {
        ...formData,
        serviceId: service.id || `service_${Date.now()}`,
        serviceName: service.name,
        servicePrice: service.price,
        serviceCurrency: service.currency || 'USD',
        freelancerId: profileId,
        status: 'pending',
        createdAt: new Date(),
        clientName: formData.name,
        clientEmail: formData.email,
        message: formData.message || ''
      };

      if (!bookingData.serviceName || !bookingData.servicePrice || !bookingData.freelancerId) {
        throw new Error('Missing required booking information');
      }

      await addDoc(collection(db, 'bookings'), bookingData);
      toast.success('Booking request sent successfully!');
      onClose();
    } catch (error) {
      console.error('Error submitting booking:', error);
      toast.error(error.message || 'Failed to send booking request');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 fade-in z-[90]">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-[#111111] rounded-2xl shadow-2xl w-full max-w-md border border-[#1A1A1A] overflow-hidden"
      >
        <div className="p-6 border-b border-[#1A1A1A] flex items-center justify-between sticky top-0 bg-[#111111] z-10">
          <h3 className="text-lg font-medium tracking-wide text-white">
            Book {service.name}
          </h3>
          <button 
            onClick={onClose}
            className="text-[#666666] hover:text-white transition-colors"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(90vh-8rem)] hide-scrollbar">
          <div className="mb-6">
            <p className="text-[#999999] mb-2">{service.description}</p>
            <p className="text-2xl font-bold text-white">
              {service.currency} {service.price}
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-[#999999] mb-2">
                Your Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
                className="w-full px-4 py-3 bg-[#1A1A1A] rounded-lg border-0 text-white placeholder-[#666666] focus:ring-2 focus:ring-[#FFB27D]"
                placeholder="Enter your name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#999999] mb-2">
                Your Email
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
                className="w-full px-4 py-3 bg-[#1A1A1A] rounded-lg border-0 text-white placeholder-[#666666] focus:ring-2 focus:ring-[#FFB27D]"
                placeholder="Enter your email"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#999999] mb-2">
                Message (Optional)
              </label>
              <textarea
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                className="w-full px-4 py-3 bg-[#1A1A1A] rounded-lg border-0 text-white placeholder-[#666666] focus:ring-2 focus:ring-[#FFB27D] transition-colors h-32 resize-none"
                placeholder="Add any additional details..."
              />
            </div>

            <div className="flex gap-4 pt-4">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 px-6 py-3 bg-[#1A1A1A] text-white rounded-lg hover:bg-[#2A2A2A] transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex-1 px-6 py-3 bg-[#FFB27D] text-[#111111] rounded-lg hover:bg-[#FFA25D] disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                {isSubmitting ? 'Sending...' : 'Book Now'}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </div>
  );
}
