import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getFirestore, doc, updateDoc, arrayUnion, onSnapshot, getDoc } from 'firebase/firestore';
import { ArrowLeftIcon, CheckCircleIcon, PlusIcon, DocumentTextIcon, UserIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import InvoiceModal from '../../components/InvoiceModal';
import AgreementModal from '../../components/AgreementModal';

function ProjectDetails() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [newTask, setNewTask] = useState({
    taskName: '',
    relativeDeadline: '',
    status: 'incomplete'
  });

  useEffect(() => {
    const db = getFirestore();
    const projectRef = doc(db, 'projects', projectId);
    
    const unsubscribe = onSnapshot(projectRef, async (doc) => {
      if (doc.exists()) {
        const projectData = { id: doc.id, ...doc.data() };
        setProject(projectData);
        
        // Fetch client details if clientId exists
        if (projectData.clientId) {
          const clientRef = doc(db, 'users', projectData.userId, 'clients', projectData.clientId);
          const clientSnap = await getDoc(clientRef);
          if (clientSnap.exists()) {
            setClientDetails(clientSnap.data());
          }
        }
        
        setLoading(false);
      } else {
        toast.error('Project not found');
        navigate('/app/projects');
      }
    });

    return () => unsubscribe();
  }, [projectId, navigate]);

  const handleAddTask = async (e) => {
    e.preventDefault();
    if (!newTask.taskName.trim() || !newTask.relativeDeadline) return;

    const task = {
      taskName: newTask.taskName,
      relativeDeadline: parseInt(newTask.relativeDeadline),
      status: 'incomplete'
    };

    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'projects', projectId), {
        tasks: arrayUnion(task)
      });
      setNewTask({
        taskName: '',
        relativeDeadline: '',
        status: 'incomplete'
      });
      setShowAddTask(false);
      toast.success('Task added successfully');
    } catch (error) {
      console.error('Error adding task:', error);
      toast.error('Failed to add task');
    }
  };

  const handleToggleTask = async (taskIndex) => {
    const updatedTasks = [...(project.tasks || [])];
    updatedTasks[taskIndex] = {
      ...updatedTasks[taskIndex],
      status: updatedTasks[taskIndex].status === 'complete' ? 'incomplete' : 'complete'
    };

    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'projects', projectId), {
        tasks: updatedTasks
      });
    } catch (error) {
      console.error('Error updating task:', error);
      toast.error('Failed to update task');
    }
  };

  const calculateProgress = () => {
    if (!project?.tasks?.length) return 0;
    const completedTasks = project.tasks.filter(t => t.status === 'complete').length;
    return Math.round((completedTasks / project.tasks.length) * 100);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-white dark:bg-black p-6">
        <div className="max-w-4xl mx-auto">
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#FFB27D]" />
          </div>
        </div>
      </div>
    );
  }

  if (!project) return null;

  return (
    <div className="min-h-screen bg-white dark:bg-black p-6">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="mb-8">
          <button
            onClick={() => navigate('/app/projects')}
            className="flex items-center gap-2 text-gray-500 dark:text-[#999999] hover:text-[#FFB27D] mb-4"
          >
            <ArrowLeftIcon className="w-5 h-5" />
            <span>Back to Projects</span>
          </button>
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl text-gray-900 dark:text-white font-medium">{project.projectName}</h1>
              <p className="text-gray-500 dark:text-[#999999] mt-1">Client: {project.clientName}</p>
            </div>
            <span
              className={`px-3 py-1.5 rounded-full text-sm font-medium ${
                project.status === 'In Progress'
                  ? 'bg-[#FFB27D]/10 text-[#FFB27D]'
                  : project.status === 'Completed'
                  ? 'bg-green-500/10 text-green-500'
                  : project.status === 'Review'
                  ? 'bg-yellow-500/10 text-yellow-500'
                  : 'bg-gray-500/10 text-gray-500'
              }`}
            >
              {project.status}
            </span>
          </div>
        </div>

        {/* Project Overview */}
        <div className="bg-white dark:bg-[#111111] rounded-xl border border-gray-200 dark:border-[#1A1A1A] overflow-hidden">
          <div className="p-6 space-y-6">
            {/* Project Details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <h3 className="text-gray-900 dark:text-white font-medium">Project Details</h3>
                <div className="space-y-2 text-gray-500 dark:text-[#999999]">
                  <p>Status: {project.status}</p>
                  <p>Created: {project.createdAt?.toDate().toLocaleDateString()}</p>
                  <p>Due Date: {project.dueDate?.toDate().toLocaleDateString()}</p>
                </div>
              </div>
              <div className="space-y-4">
                <h3 className="text-gray-900 dark:text-white font-medium">Progress</h3>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500 dark:text-[#999999]">Tasks Completed</span>
                    <span className="text-gray-900 dark:text-white">
                      {project.tasks?.filter(t => t.status === 'complete').length || 0} / {project.tasks?.length || 0}
                    </span>
                  </div>
                  <div className="w-full bg-gray-100 dark:bg-[#1A1A1A] rounded-full h-2">
                    <div
                      className="bg-[#FFB27D] h-2 rounded-full transition-all"
                      style={{ width: `${calculateProgress()}%` }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Quick Actions */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <button
                onClick={() => setShowInvoiceModal(true)}
                className="flex items-center justify-center gap-2 p-4 bg-gray-50 dark:bg-[#1A1A1A] rounded-xl hover:bg-gray-100 dark:hover:bg-[#222222] transition-colors"
              >
                <CreditCardIcon className="w-5 h-5" />
                <span>Create Invoice</span>
              </button>
              <button
                onClick={() => setShowAgreementModal(true)}
                className="flex items-center justify-center gap-2 p-4 bg-gray-50 dark:bg-[#1A1A1A] rounded-xl hover:bg-gray-100 dark:hover:bg-[#222222] transition-colors"
              >
                <DocumentTextIcon className="w-5 h-5" />
                <span>Create Agreement</span>
              </button>
              <button
                onClick={() => navigate(`/app/clients/${project?.clientId}`)}
                className="flex items-center justify-center gap-2 p-4 bg-gray-50 dark:bg-[#1A1A1A] rounded-xl hover:bg-gray-100 dark:hover:bg-[#222222] transition-colors"
              >
                <UserIcon className="w-5 h-5" />
                <span>View Client</span>
              </button>
            </div>

            {/* Tasks Section */}
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-900 dark:text-white font-medium">Tasks</h3>
                <button
                  onClick={() => setShowAddTask(true)}
                  className="flex items-center gap-2 text-[#FFB27D] hover:text-[#FFB27D]/80"
                >
                  <PlusIcon className="w-5 h-5" />
                  <span>Add Task</span>
                </button>
              </div>

              {showAddTask && (
                <form onSubmit={handleAddTask} className="bg-gray-50 dark:bg-[#1A1A1A] rounded-xl p-4 space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm text-gray-500 dark:text-[#999999] mb-1">Task Name</label>
                      <input
                        type="text"
                        value={newTask.taskName}
                        onChange={(e) => setNewTask(prev => ({ ...prev, taskName: e.target.value }))}
                        placeholder="Enter task name"
                        className="w-full px-4 py-2 rounded-xl bg-white dark:bg-[#111111] border border-gray-200 dark:border-[#1A1A1A] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-[#666666]"
                      />
                    </div>
                    <div>
                      <label className="block text-sm text-gray-500 dark:text-[#999999] mb-1">Days to Complete</label>
                      <input
                        type="number"
                        value={newTask.relativeDeadline}
                        onChange={(e) => setNewTask(prev => ({ ...prev, relativeDeadline: e.target.value }))}
                        placeholder="Enter number of days"
                        min="1"
                        className="w-full px-4 py-2 rounded-xl bg-white dark:bg-[#111111] border border-gray-200 dark:border-[#1A1A1A] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-[#666666]"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-2">
                    <button
                      type="button"
                      onClick={() => setShowAddTask(false)}
                      className="px-4 py-2 text-gray-500 dark:text-[#999999] hover:text-gray-700 dark:hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-[#FFB27D] text-black rounded-xl hover:bg-[#FFB27D]/90"
                    >
                      Add Task
                    </button>
                  </div>
                </form>
              )}

              <div className="space-y-2">
                {project.tasks?.map((task, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-3 p-4 bg-gray-50 dark:bg-[#1A1A1A] rounded-xl"
                  >
                    <button
                      onClick={() => handleToggleTask(index)}
                      className={task.status === 'complete' ? 'text-[#FFB27D]' : 'text-gray-400 dark:text-[#666666]'}
                    >
                      <CheckCircleIcon className="w-6 h-6" />
                    </button>
                    <div className="flex-1">
                      <span className={`block ${
                        task.status === 'complete'
                          ? 'line-through text-gray-500 dark:text-[#666666]'
                          : 'text-gray-900 dark:text-white'
                      }`}>
                        {task.taskName}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-[#999999]">
                        Due in {task.relativeDeadline} days
                      </span>
                    </div>
                  </div>
                ))}
                {!project.tasks?.length && (
                  <p className="text-center text-gray-500 dark:text-[#999999] py-4">
                    No tasks added yet
                  </p>
                )}
              </div>
            </div>

            {/* Client Details Section */}
            {clientDetails && (
              <div className="space-y-4">
                <h3 className="text-gray-900 dark:text-white font-medium">Client Information</h3>
                <div className="bg-gray-50 dark:bg-[#1A1A1A] rounded-xl p-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500 dark:text-[#999999]">Name</p>
                      <p className="text-gray-900 dark:text-white">{clientDetails.name}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-[#999999]">Email</p>
                      <p className="text-gray-900 dark:text-white">{clientDetails.email}</p>
                    </div>
                    {clientDetails.company && (
                      <div>
                        <p className="text-sm text-gray-500 dark:text-[#999999]">Company</p>
                        <p className="text-gray-900 dark:text-white">{clientDetails.company}</p>
                      </div>
                    )}
                    {clientDetails.phone && (
                      <div>
                        <p className="text-sm text-gray-500 dark:text-[#999999]">Phone</p>
                        <p className="text-gray-900 dark:text-white">{clientDetails.phone}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modals */}
      {showInvoiceModal && (
        <InvoiceModal
          isOpen={showInvoiceModal}
          onClose={() => setShowInvoiceModal(false)}
          project={project}
          client={clientDetails}
        />
      )}

      {showAgreementModal && (
        <AgreementModal
          isOpen={showAgreementModal}
          onClose={() => setShowAgreementModal(false)}
          project={project}
          client={clientDetails}
        />
      )}
    </div>
  );
}

export default ProjectDetails;
