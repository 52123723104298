import React, { useState, useEffect } from 'react';
import { getFirestore, collection, onSnapshot, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { motion } from 'framer-motion';
import { auth } from '../firebase';

const Clients = () => {
  const currentUser = auth.currentUser;
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newClient, setNewClient] = useState({
    name: '',
    email: '',
    company: '',
    phone: ''
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    const firestore = getFirestore();
    const clientsRef = collection(firestore, 'users', currentUser.uid, 'clients');
    
    const unsubscribe = onSnapshot(clientsRef, snapshot => {
      const clientsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setClients(clientsData);
      setIsLoading(false);
    }, error => {
      setError('Failed to load clients');
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  const handleAddClient = async (e) => {
    e.preventDefault();
    
    try {
      const firestore = getFirestore();
      const clientsRef = collection(firestore, 'users', currentUser.uid, 'clients');
      
      await addDoc(clientsRef, {
        ...newClient,
        createdAt: new Date()
      });

      setNewClient({ name: '', email: '', company: '', phone: '' });
      setShowAddForm(false);
    } catch (err) {
      setError('Failed to add client');
    }
  };

  const handleDeleteClient = async (clientId) => {
    if (!window.confirm('Are you sure you want to delete this client?')) return;

    try {
      const firestore = getFirestore();
      await deleteDoc(doc(firestore, 'users', currentUser.uid, 'clients', clientId));
    } catch (err) {
      setError('Failed to delete client');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-brown-400">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex-1 bg-white dark:bg-[#0A0A0A] overflow-hidden flex">
      {/* Client List */}
      <div className="w-[400px] border-r border-gray-100 dark:border-[#1A1A1A]">
        <div className="p-6 border-b border-gray-100 dark:border-[#1A1A1A]">
          <h1 className="text-2xl font-medium tracking-wide mb-6 text-gray-900 dark:text-white">Clients</h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search clients..."
              className="w-full bg-gray-50 dark:bg-[#111111] text-gray-900 dark:text-white pl-10 pr-4 py-3 rounded-lg border-0 text-sm placeholder-gray-500 dark:placeholder-[#666666]"
            />
            <svg className="absolute left-3 top-3.5 h-4 w-4 text-gray-400 dark:text-[#666666]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>

        <div className="overflow-y-auto h-[calc(100vh-8.5rem)]">
          {clients.map(client => (
            <div
              key={client.id}
              onClick={() => setSelectedClient(client)}
              className={`p-6 border-b border-gray-100 dark:border-[#1A1A1A] cursor-pointer hover:bg-gray-50 dark:hover:bg-[#111111] transition-colors ${
                selectedClient?.id === client.id ? 'bg-gray-50 dark:bg-[#111111]' : ''
              }`}
            >
              <div className="flex items-center space-x-4">
                <div className="w-12 h-12 bg-gradient-to-b from-[#FFB27D] to-[#FF9D5C] rounded-xl flex items-center justify-center text-lg font-medium text-black">
                  {client.name?.charAt(0)}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between mb-1">
                    <h3 className="text-sm font-medium truncate">{client.name}</h3>
                    <span className={`text-xs px-2 py-0.5 rounded ${
                      client.status === 'ACTIVE' 
                        ? 'bg-[#059669] bg-opacity-20 text-[#059669]'
                        : 'bg-[#FFB27D] bg-opacity-20 text-[#FFB27D]'
                    }`}>
                      {client.status || 'PENDING'}
                    </span>
                  </div>
                  <div className="text-xs text-[#666666] mb-2">{client.email}</div>
                  <div className="flex items-center space-x-4 text-xs">
                    <div className="text-[#999999]">
                      {client.projects || 0} {(client.projects || 0) === 1 ? 'Project' : 'Projects'}
                    </div>
                    <div className="text-[#FFB27D]">
                      ${(client.totalRevenue || 0).toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Client Details */}
      {selectedClient ? (
        <div className="flex-1 overflow-y-auto">
          <div className="p-6 border-b border-gray-100 dark:border-[#1A1A1A]">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center space-x-4">
                <div className="w-16 h-16 bg-gradient-to-b from-[#FFB27D] to-[#FF9D5C] rounded-xl flex items-center justify-center text-2xl font-medium text-black">
                  {selectedClient.name?.charAt(0)}
                </div>
                <div>
                  <h2 className="text-xl font-medium mb-1">{selectedClient.name}</h2>
                  <div className="text-sm text-[#666666]">{selectedClient.email}</div>
                </div>
              </div>
              <button className="px-4 py-2 bg-[#FFB27D] text-black text-sm font-medium rounded-lg hover:bg-[#FFA164] transition-colors">
                + New Project
              </button>
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div className="bg-[#111111] rounded-lg p-4">
                <div className="text-sm text-[#666666] mb-1">Projects</div>
                <div className="text-2xl font-medium">{selectedClient.projects || 0}</div>
              </div>
              <div className="bg-[#111111] rounded-lg p-4">
                <div className="text-sm text-[#666666] mb-1">Total Revenue</div>
                <div className="text-2xl font-medium">${(selectedClient.totalRevenue || 0).toLocaleString()}</div>
              </div>
              <div className="bg-[#111111] rounded-lg p-4">
                <div className="text-sm text-[#666666] mb-1">Last Active</div>
                <div className="text-2xl font-medium">{selectedClient.lastActive || 'Never'}</div>
              </div>
            </div>
          </div>

          <div className="p-6">
            <div className="mb-8">
              <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-white">Recent Activity</h3>
              <div className="space-y-4">
                <div className="bg-gray-50 dark:bg-[#111111] rounded-lg p-4">
                  <div className="flex items-center justify-between mb-2">
                    <div className="text-sm font-medium">{selectedClient.recentActivity || 'No recent activity'}</div>
                    <div className="text-xs text-[#666666]">2 hours ago</div>
                  </div>
                  <div className="text-xs text-[#666666]">Status: Completed</div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-4 text-gray-900 dark:text-white">Projects</h3>
              <div className="space-y-4">
                <div className="bg-gray-50 dark:bg-[#111111] rounded-lg p-4">
                  <div className="flex items-center justify-between mb-2">
                    <div className="text-sm font-medium">Website Redesign</div>
                    <div className="text-xs px-2 py-0.5 rounded bg-[#059669] bg-opacity-20 text-[#059669]">
                      IN PROGRESS
                    </div>
                  </div>
                  <div className="text-xs text-[#666666] mb-3">Due: June 30, 2024</div>
                  <div className="flex items-center justify-between text-xs">
                    <div className="text-[#FFB27D]">$5,000</div>
                    <div className="text-[#666666]">70% completed</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center text-gray-500 dark:text-[#666666]">
          Select a client to view details
        </div>
      )}

      {showAddForm && (
        <div className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white dark:bg-[#111111] rounded-3xl shadow-xl w-full max-w-md p-6 border border-gray-200 dark:border-[#1A1A1A]"
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl text-gray-900 dark:text-white">Add New Client</h2>
              <button
                onClick={() => setShowAddForm(false)}
                className="text-gray-500 dark:text-[#666666] hover:text-gray-900 dark:hover:text-white"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <form onSubmit={handleAddClient} className="space-y-4">
              <div>
                <label className="block text-sm text-gray-900 dark:text-white mb-2">Name</label>
                <input
                  type="text"
                  value={newClient.name}
                  onChange={(e) => setNewClient(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-4 py-2 rounded-xl bg-gray-50 dark:bg-[#111111] border border-gray-200 dark:border-[#1A1A1A] text-gray-900 dark:text-white focus:ring-2 focus:ring-[#FFB27D] focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-900 dark:text-white mb-2">Email</label>
                <input
                  type="email"
                  value={newClient.email}
                  onChange={(e) => setNewClient(prev => ({ ...prev, email: e.target.value }))}
                  className="w-full px-4 py-2 rounded-xl bg-gray-50 dark:bg-[#111111] border border-gray-200 dark:border-[#1A1A1A] text-gray-900 dark:text-white focus:ring-2 focus:ring-[#FFB27D] focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-900 dark:text-white mb-2">Company</label>
                <input
                  type="text"
                  value={newClient.company}
                  onChange={(e) => setNewClient(prev => ({ ...prev, company: e.target.value }))}
                  className="w-full px-4 py-2 rounded-xl bg-gray-50 dark:bg-[#111111] border border-gray-200 dark:border-[#1A1A1A] text-gray-900 dark:text-white focus:ring-2 focus:ring-[#FFB27D] focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm text-gray-900 dark:text-white mb-2">Phone</label>
                <input
                  type="tel"
                  value={newClient.phone}
                  onChange={(e) => setNewClient(prev => ({ ...prev, phone: e.target.value }))}
                  className="w-full px-4 py-2 rounded-xl bg-gray-50 dark:bg-[#111111] border border-gray-200 dark:border-[#1A1A1A] text-gray-900 dark:text-white focus:ring-2 focus:ring-[#FFB27D] focus:border-transparent"
                />
              </div>

              <div className="flex justify-end gap-3 mt-6">
                <button
                  type="button"
                  onClick={() => setShowAddForm(false)}
                  className="px-4 py-2 text-gray-500 dark:text-[#666666] hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-[#1A1A1A] rounded-xl transition-colors"
                >
                  Cancel
                </button>
                <motion.button
                  type="submit"
                  className="px-4 py-2 bg-[#FFB27D] text-black rounded-xl hover:bg-[#FFA164] transition-colors"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Add Client
                </motion.button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default Clients;