import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { toast } from 'react-hot-toast';
import BookingModal from '../../components/BookingModal';

// Footer Component
const Footer = () => (
  <div className="mt-8 pt-4 border-t border-gray-200 dark:border-[#333333] text-center text-sm text-gray-500 dark:text-[#999999]">
    Powered by{" "}
    <a
      href="https://klorah.com"
      target="_blank"
      rel="noopener noreferrer"
      className="text-[#E6A15E] hover:text-[#E6A15E]/90 hover:underline"
    >
      Klorah
    </a>
  </div>
);

const ProfileView = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        let profileData;

        // Case 1: Viewing someone else's profile by username (public route, no auth required)
        if (username) {
          const profilesRef = collection(db, 'profiles');
          const q = query(profilesRef, where('username', '==', username.toLowerCase()));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            profileData = {
              id: querySnapshot.docs[0].id,
              ...querySnapshot.docs[0].data()
            };
          } else {
            setError('Profile not found');
            return;
          }
        }
        // Case 2: Viewing own profile (requires authentication)
        else if (currentUser?.uid) {
          const profileRef = doc(db, 'profiles', currentUser.uid);
          const profileSnap = await getDoc(profileRef);

          if (profileSnap.exists()) {
            profileData = {
              id: profileSnap.id,
              ...profileSnap.data()
            };
          } else {
            navigate('/app/profile/welcome');
            return;
          }
        }
        // Case 3: Trying to view own profile but not authenticated
        else {
          setError('Please sign in to view your profile');
          return;
        }

        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Error loading profile');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [username, navigate, currentUser]);

  const handleShareProfile = () => {
    const profileUrl = `${window.location.origin}/profile/${profile.username}`;
    navigator.clipboard.writeText(profileUrl);
    toast.success('Profile link copied to clipboard!');
  };

  const handleBookNow = (service) => {
    setSelectedService({
      id: service.id || `service_${Date.now()}`,
      name: service.name,
      description: service.description,
      price: service.price,
      currency: service.currency || 'USD',
    });
    setIsModalOpen(true);
  };

  // Loading state
  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center bg-white dark:bg-black min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-2 border-[#E6A15E] border-t-transparent"></div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center bg-white dark:bg-black min-h-screen p-4">
        <p className="text-xl text-[#E6A15E] mb-4 text-center">{error}</p>

        {error === 'Please sign in to view your profile' ? (
          <div className="flex flex-col sm:flex-row gap-4">
            <button
              onClick={() => navigate('/')}
              className="px-6 py-3 bg-[#E6A15E] text-black font-medium rounded-xl hover:bg-[#E6A15E]/90 transition-colors w-full sm:w-auto"
            >
              Sign In
            </button>
            <button
              onClick={() => navigate('/')}
              className="px-6 py-3 bg-gray-100 dark:bg-black text-gray-900 dark:text-white border border-gray-200 dark:border-[#333333] rounded-xl hover:bg-gray-200 dark:hover:bg-[#111111] transition-colors w-full sm:w-auto"
            >
              Go Home
            </button>
          </div>
        ) : (
          <button
            onClick={() => navigate('/')}
            className="px-6 py-3 bg-[#E6A15E] text-black font-medium rounded-xl hover:bg-[#E6A15E]/90 transition-colors w-full sm:w-auto"
          >
            Go Home
          </button>
        )}
      </div>
    );
  }

  // Flag to check if this is the user's own profile
  const isOwnProfile =
    currentUser?.uid && (!username || profile?.id === currentUser?.uid);

  return (
    <div className="py-4 sm:py-8 px-4 sm:px-6 lg:px-8 bg-white dark:bg-black min-h-screen">
      <div className="max-w-4xl mx-auto space-y-4 sm:space-y-8">
        {isModalOpen && selectedService && (
          <BookingModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            service={selectedService}
            profileId={profile.id}
          />
        )}

        <div className="bg-white dark:bg-black rounded-2xl p-4 sm:p-8 border border-gray-200 dark:border-[#333333]">
          <div className="flex flex-col items-center gap-4 sm:gap-8">
            <div className="flex flex-col items-center text-center w-full">
              <div className="relative group mb-4">
                <div className="absolute -inset-1 bg-gradient-to-r from-[#E6A15E] to-[#E6A15E]/80 opacity-25 group-hover:opacity-50 transition-opacity blur rounded-full"></div>
                <div className="relative w-24 h-24 sm:w-28 sm:h-28 rounded-full bg-[#E6A15E] flex items-center justify-center text-3xl font-bold text-black ring-2 ring-gray-200 dark:ring-[#333333] overflow-hidden">
                  {profile.profilePicture ? (
                    <img
                      src={profile.profilePicture}
                      alt={profile.name}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    profile.name?.[0]?.toUpperCase() || '?'
                  )}
                </div>
              </div>
              <div>
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white tracking-tight">
                  {profile.name}
                </h1>
                <p className="text-[#E6A15E] mt-1 font-medium">
                  {profile.professionalTitle}
                </p>
                <p className="text-gray-500 dark:text-[#999999] mt-4 max-w-2xl leading-relaxed">
                  {profile.bio}
                </p>
              </div>
            </div>
            {isOwnProfile && (
              <div className="flex flex-col w-full gap-3">
                <button
                  onClick={() => navigate('/app/profile/edit')}
                  className="w-full px-6 py-3 bg-[#E6A15E] text-black font-medium rounded-xl hover:bg-[#E6A15E]/90 transition-colors"
                >
                  Edit Profile
                </button>
                <button
                  onClick={handleShareProfile}
                  className="w-full px-6 py-3 bg-gray-100 dark:bg-black text-gray-900 dark:text-white font-medium rounded-xl hover:bg-gray-200 dark:hover:bg-[#111111] border border-gray-200 dark:border-[#333333] hover:border-gray-300 dark:hover:border-[#444444] transition-all"
                >
                  Share Profile
                </button>
              </div>
            )}
          </div>
        </div>

        {profile.services?.length > 0 && (
          <div className="bg-white dark:bg-black rounded-2xl p-4 sm:p-8 border border-gray-200 dark:border-[#333333]">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white mb-6 sm:mb-8 flex items-center">
              <span className="mr-3 text-[#E6A15E]">Services</span>
              <div className="flex-1 h-px bg-gradient-to-r from-gray-200 dark:from-[#333333] to-transparent"></div>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
              {profile.services.map((service, index) => (
                <div
                  key={index}
                  className="group bg-gray-50 dark:bg-black rounded-xl p-4 sm:p-6 hover:bg-gray-100 dark:hover:bg-[#111111] transition-colors border border-gray-200 dark:border-[#333333] hover:border-gray-300 dark:hover:border-[#444444]"
                >
                  <h3 className="text-lg sm:text-xl font-bold text-gray-900 dark:text-white group-hover:text-[#E6A15E] transition-colors">
                    {service.name}
                  </h3>
                  <p className="text-[#E6A15E] font-medium mt-3 flex items-center">
                    <span className="text-xl sm:text-2xl">{service.price}</span>
                    <span className="ml-1 text-sm text-gray-500 dark:text-[#999999]">
                      {service.currency}
                    </span>
                  </p>
                  <p className="text-gray-500 dark:text-[#999999] mt-3 leading-relaxed">
                    {service.description}
                  </p>
                  <button
                    onClick={() => handleBookNow(service)}
                    className="mt-4 w-full px-6 py-3 bg-[#E6A15E] text-black font-medium rounded-xl hover:bg-[#E6A15E]/90 transition-colors"
                  >
                    Book Now
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {profile.portfolio?.length > 0 && (
          <div className="bg-white dark:bg-black rounded-2xl p-4 sm:p-8 border border-gray-200 dark:border-[#333333]">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white mb-6 sm:mb-8 flex items-center">
              <span className="mr-3 text-[#E6A15E]">Portfolio</span>
              <div className="flex-1 h-px bg-gradient-to-r from-gray-200 dark:from-[#333333] to-transparent"></div>
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
              {profile.portfolio.map((item, index) => (
                <div
                  key={index}
                  className="group bg-gray-50 dark:bg-black rounded-xl overflow-hidden border border-gray-200 dark:border-[#333333] hover:border-gray-300 dark:hover:border-[#444444] transition-all hover:-translate-y-1"
                >
                  {item.image && (
                    <div className="relative">
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity z-10"></div>
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-full h-40 sm:h-48 object-cover transition-transform group-hover:scale-105"
                      />
                    </div>
                  )}
                  <div className="p-4 sm:p-6">
                    <h3 className="text-base sm:text-lg font-bold text-gray-900 dark:text-white group-hover:text-[#E6A15E] transition-colors">
                      {item.title}
                    </h3>
                    <p className="text-gray-500 dark:text-[#999999] mt-2 leading-relaxed text-sm sm:text-base">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="bg-white dark:bg-black rounded-2xl p-4 sm:p-8 border border-gray-200 dark:border-[#333333]">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white mb-6 sm:mb-8 flex items-center">
            <span className="mr-3 text-[#E6A15E]">Contact Information</span>
            <div className="flex-1 h-px bg-gradient-to-r from-gray-200 dark:from-[#333333] to-transparent"></div>
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6">
            {profile.contactInfo?.email && (
              <div className="bg-gray-50 dark:bg-black rounded-xl p-4 sm:p-6 border border-gray-200 dark:border-[#333333] hover:border-gray-300 dark:hover:border-[#444444] transition-all group">
                <div className="flex items-center space-x-3 mb-3">
                  <svg
                    className="w-5 h-5 text-[#E6A15E]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <label className="text-gray-500 dark:text-[#999999] font-medium">Email</label>
                </div>
                <p className="text-gray-900 dark:text-white group-hover:text-[#E6A15E] transition-colors break-all">
                  {profile.contactInfo.email}
                </p>
              </div>
            )}
            {profile.contactInfo?.phone && (
              <div className="bg-gray-50 dark:bg-black rounded-xl p-4 sm:p-6 border border-gray-200 dark:border-[#333333] hover:border-gray-300 dark:hover:border-[#444444] transition-all group">
                <div className="flex items-center space-x-3 mb-3">
                  <svg
                    className="w-5 h-5 text-[#E6A15E]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <label className="text-gray-500 dark:text-[#999999] font-medium">Phone</label>
                </div>
                <p className="text-gray-900 dark:text-white group-hover:text-[#E6A15E] transition-colors">
                  {profile.contactInfo.phone}
                </p>
              </div>
            )}
            {profile.contactInfo?.location && (
              <div className="bg-gray-50 dark:bg-black rounded-xl p-4 sm:p-6 border border-gray-200 dark:border-[#333333] hover:border-gray-300 dark:hover:border-[#444444] transition-all group">
                <div className="flex items-center space-x-3 mb-3">
                  <svg
                    className="w-5 h-5 text-[#E6A15E]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <label className="text-gray-500 dark:text-[#999999] font-medium">Location</label>
                </div>
                <p className="text-gray-900 dark:text-white group-hover:text-[#E6A15E] transition-colors">
                  {profile.contactInfo.location}
                </p>
              </div>
            )}
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ProfileView;