import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../../firebase';
import { toast } from 'react-hot-toast';

// Initial profile state
const initialProfileState = {
  name: '',
  username: '',
  professionalTitle: '',
  bio: '',
  profilePicture: '',
  services: [],
  portfolio: [],
  contactInfo: {
    email: auth.currentUser?.email || '',
    phone: '',
    location: ''
  }
};

// Profile Image Upload Component
const ProfileImageUpload = ({ profilePicture, name, onImageUpload }) => {
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const storageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      onImageUpload(url);
      toast.success('Profile picture uploaded');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading profile picture');
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-white mb-2">Profile Picture</label>
      <div className="flex items-center space-x-6">
        <div className="w-24 h-24 rounded-full bg-[#E6A15E] flex items-center justify-center text-3xl font-bold text-white">
          {profilePicture ? (
            <img
              src={profilePicture}
              alt="Profile"
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            name?.[0]?.toUpperCase() || '?'
          )}
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="text-sm text-[#999999]"
        />
      </div>
    </div>
  );
};

// Basic Info Form Component
const BasicInfoForm = ({ profile, setProfile }) => (
  <div className="space-y-6">
    <ProfileImageUpload 
      profilePicture={profile.profilePicture} 
      name={profile.name} 
      onImageUpload={(url) => setProfile(prev => ({ ...prev, profilePicture: url }))}
    />

    <div>
      <label className="block text-sm font-medium text-white mb-2">Full Name</label>
      <input
        type="text"
        value={profile.name}
        onChange={(e) => setProfile(prev => ({ ...prev, name: e.target.value }))}
        className="w-full px-4 py-2 bg-black border border-[#333333] rounded-lg text-white focus:outline-none focus:border-[#E6A15E]"
        placeholder="John Doe"
        required
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-white mb-2">Username</label>
      <input
        type="text"
        value={profile.username}
        onChange={(e) => setProfile(prev => ({ ...prev, username: e.target.value }))}
        className="w-full px-4 py-2 bg-black border border-[#333333] rounded-lg text-white focus:outline-none focus:border-[#E6A15E]"
        placeholder="johndoe"
        required
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-white mb-2">Professional Title</label>
      <input
        type="text"
        value={profile.professionalTitle}
        onChange={(e) => setProfile(prev => ({ ...prev, professionalTitle: e.target.value }))}
        className="w-full px-4 py-2 bg-black border border-[#333333] rounded-lg text-white focus:outline-none focus:border-[#E6A15E]"
        placeholder="Software Engineer"
      />
    </div>
  </div>
);

// Advanced Info Form Component
const AdvancedInfoForm = ({ profile, setProfile }) => (
  <div className="space-y-6">
    <div>
      <label className="block text-sm font-medium text-white mb-2">Bio</label>
      <textarea
        value={profile.bio}
        onChange={(e) => setProfile(prev => ({ ...prev, bio: e.target.value }))}
        className="w-full px-4 py-2 bg-black border border-[#333333] rounded-lg text-white focus:outline-none focus:border-[#E6A15E] h-32"
        placeholder="Tell us about yourself..."
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-white mb-2">Contact Information</label>
      <div className="space-y-4">
        <input
          type="email"
          value={profile.contactInfo.email}
          onChange={(e) => setProfile(prev => ({
            ...prev,
            contactInfo: { ...prev.contactInfo, email: e.target.value }
          }))}
          className="w-full px-4 py-2 bg-black border border-[#333333] rounded-lg text-white focus:outline-none focus:border-[#E6A15E]"
          placeholder="Email"
        />
        <input
          type="tel"
          value={profile.contactInfo.phone}
          onChange={(e) => setProfile(prev => ({
            ...prev,
            contactInfo: { ...prev.contactInfo, phone: e.target.value }
          }))}
          className="w-full px-4 py-2 bg-black border border-[#333333] rounded-lg text-white focus:outline-none focus:border-[#E6A15E]"
          placeholder="Phone"
        />
        <input
          type="text"
          value={profile.contactInfo.location}
          onChange={(e) => setProfile(prev => ({
            ...prev,
            contactInfo: { ...prev.contactInfo, location: e.target.value }
          }))}
          className="w-full px-4 py-2 bg-black border border-[#333333] rounded-lg text-white focus:outline-none focus:border-[#E6A15E]"
          placeholder="Location"
        />
      </div>
    </div>
  </div>
);

// Step Progress Bar Component
const StepProgressBar = ({ currentStep, totalSteps = 2 }) => (
  <div className="flex justify-center mb-8">
    <div className="flex items-center">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <React.Fragment key={index}>
          <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
            currentStep >= index + 1 ? 'bg-[#E6A15E] text-white' : 'bg-[#333333] text-[#999999]'
          }`}>
            {index + 1}
          </div>
          {index < totalSteps - 1 && (
            <div className={`w-16 h-1 ${
              currentStep > index + 1 ? 'bg-[#E6A15E]' : 'bg-[#333333]'
            }`} />
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);

// Footer Component
const Footer = () => (
  <div className="mt-8 pt-4 border-t border-[#333333] text-center text-sm text-[#999999]">
    Powered by{" "}
    <a 
      href="https://klorah.com" 
      target="_blank" 
      rel="noopener noreferrer"
      className="text-[#E6A15E] hover:underline"
    >
      Klorah
    </a>
  </div>
);

const WelcomeProfile = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(initialProfileState);
  
  // Form validation
  const validateStep = (stepNumber) => {
    if (stepNumber === 1) {
      if (!profile.name.trim()) {
        toast.error('Name is required');
        return false;
      }
      if (!profile.username.trim()) {
        toast.error('Username is required');
        return false;
      }
      // Add more validation as needed
      return true;
    }
    return true;
  };

  const handleNextStep = () => {
    if (validateStep(step)) {
      setStep(step + 1);
    }
  };

  const handleSubmit = async () => {
    if (!auth.currentUser) {
      toast.error('Please sign in to create a profile');
      return;
    }

    if (!validateStep(step)) {
      return;
    }

    setLoading(true);
    try {
      await setDoc(doc(db, 'profiles', auth.currentUser.uid), {
        ...profile,
        username: profile.username.toLowerCase(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
      
      toast.success('Profile created successfully!');
      navigate('/profile');
    } catch (error) {
      console.error('Error creating profile:', error);
      toast.error('Error creating profile');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 bg-black min-h-screen">
      <div className="max-w-2xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-white">Create Your Profile</h1>
          <p className="mt-2 text-[#999999]">Let's get you set up with a professional profile</p>
        </div>

        <div className="bg-black rounded-2xl p-8 border border-[#333333]">
          <StepProgressBar currentStep={step} />

          {step === 1 ? (
            <BasicInfoForm profile={profile} setProfile={setProfile} />
          ) : (
            <AdvancedInfoForm profile={profile} setProfile={setProfile} />
          )}

          <div className="mt-8 flex justify-between">
            {step > 1 && (
              <button
                onClick={() => setStep(step - 1)}
                className="px-6 py-3 bg-black text-white border border-[#333333] rounded-xl hover:bg-[#111111] transition-colors"
              >
                Back
              </button>
            )}
            <button
              onClick={() => {
                if (step === 1) {
                  handleNextStep();
                } else {
                  handleSubmit();
                }
              }}
              disabled={loading}
              className={`px-6 py-3 bg-[#E6A15E] text-white rounded-xl hover:bg-[#E6A15E]/90 transition-colors ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? (
                <div className="flex items-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white mr-2"></div>
                  Creating...
                </div>
              ) : step === 1 ? (
                'Next'
              ) : (
                'Create Profile'
              )}
            </button>
          </div>
          
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default WelcomeProfile;
