import jsPDF from 'jspdf'
import 'jspdf-autotable'

export function generateAgreementPDF(agreement, signatures = {}) {
  // Create new PDF document
  const doc = new jsPDF()
  
  // Set font styles
  doc.setFont('helvetica', 'bold')
  doc.setFontSize(20)
  
  // Add title
  const title = agreement?.title || `Agreement with ${agreement?.client}`
  doc.text(title, 20, 20)
  
  // Add date
  doc.setFont('helvetica', 'normal')
  doc.setFontSize(12)
  const date = agreement?.createdAt?.toDate().toLocaleDateString() || new Date().toLocaleDateString()
  doc.text(`Date: ${date}`, 20, 30)
  
  // Add agreement content
  doc.setFontSize(12)
  const contentLines = doc.splitTextToSize(agreement?.text || '', 170)
  doc.text(contentLines, 20, 45)
  
  // Calculate signature section Y position
  const textHeight = contentLines.length * 7 // Approximate height of text
  const signatureY = Math.max(200, 45 + textHeight + 30)
  
  // Add signature section
  doc.setDrawColor(200, 200, 200)
  doc.line(20, signatureY, 95, signatureY) // Client signature line
  doc.line(115, signatureY, 190, signatureY) // Provider signature line
  
  doc.setFontSize(10)
  doc.text('Client Signature', 20, signatureY - 5)
  doc.text('Service Provider Signature', 115, signatureY - 5)
  
  // Add signature dates
  if (signatures.clientSignature?.timestamp) {
    doc.text(
      `Signed: ${new Date(signatures.clientSignature.timestamp).toLocaleDateString()}`,
      20,
      signatureY + 10
    )
  }
  if (signatures.freelancerSignature?.timestamp) {
    doc.text(
      `Signed: ${new Date(signatures.freelancerSignature.timestamp).toLocaleDateString()}`,
      115,
      signatureY + 10
    )
  }
  
  // Add signature images if they exist
  if (signatures.clientSignature?.signature) {
    try {
      doc.addImage(
        signatures.clientSignature.signature,
        'PNG',
        20,
        signatureY - 25,
        75,
        20
      )
    } catch (error) {
      console.warn('Could not add client signature image:', error)
    }
  }
  
  if (signatures.freelancerSignature?.signature) {
    try {
      doc.addImage(
        signatures.freelancerSignature.signature,
        'PNG',
        115,
        signatureY - 25,
        75,
        20
      )
    } catch (error) {
      console.warn('Could not add provider signature image:', error)
    }
  }
  
  // Add footer
  doc.setFontSize(8)
  doc.setTextColor(150, 150, 150)
  doc.text('Generated by Klorah', 20, 285)
  
  return doc
}