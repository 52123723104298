import React, { useState, useRef, useEffect } from 'react';
import { auth, signOut, db } from '../firebase';
import { useEmail } from '../contexts/EmailContext';
import EmailAccountsModal from './EmailAccountsModal';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

// Icons as separate components for better readability
const Icons = {
  Profile: () => (
    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
    </svg>
  ),
  CreateProfile: () => (
    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  ),
  Edit: () => (
    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
    </svg>
  ),
  Email: () => (
    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
    </svg>
  ),
  SignOut: () => (
    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
    </svg>
  ),
  ChevronDown: ({ isOpen }) => (
    <svg 
      className={`w-4 h-4 text-gray-400 dark:text-white/60 transition-transform ${isOpen ? 'rotate-180' : ''}`} 
      fill="none" 
      stroke="currentColor" 
      viewBox="0 0 24 24"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 9l-7 7-7-7" />
    </svg>
  )
};

// Menu Item Component
const MenuItem = ({ icon, text, onClick, className = "", children }) => (
  <button
    onClick={onClick}
    className={`w-full text-left px-4 py-2.5 text-sm hover:bg-gray-100 dark:hover:bg-white/5 transition-colors flex items-center justify-between group ${className}`}
  >
    <div className="flex items-center space-x-3">
      <span className="text-gray-400 dark:text-white/60 group-hover:text-gray-900 dark:group-hover:text-white transition-opacity">{icon}</span>
      <span className="text-gray-700 dark:text-white/90 group-hover:text-gray-900 dark:group-hover:text-white font-medium">{text}</span>
    </div>
    {children}
  </button>
);

// Profile Avatar Component
const ProfileAvatar = ({ user }) => (
  <div className="w-8 h-8 rounded-full bg-gray-100 dark:bg-white/10 flex items-center justify-center text-sm font-medium text-gray-900 dark:text-white">
    {user?.email?.charAt(0).toUpperCase() || 'U'}
  </div>
);

// Email Account Item Component
const EmailAccountItem = ({ account, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`w-full flex items-center space-x-2 px-4 py-2 text-sm ${
      isActive
        ? 'bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white'
        : 'text-gray-600 dark:text-[#999999] hover:bg-gray-100 dark:hover:bg-white/5 hover:text-gray-900 dark:hover:text-white'
    } transition-colors`}
  >
    <div className="w-6 h-6 bg-[#FFB27D] rounded-full flex items-center justify-center text-sm text-black font-medium">
      {account.email.charAt(0).toUpperCase()}
    </div>
    <span className="flex-1 truncate text-left">{account.email}</span>
    {isActive && (
      <svg className="w-4 h-4 text-[#FFB27D]" fill="currentColor" viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
    )}
  </button>
);

// Footer Component
const Footer = () => (
  <div className="mt-1 px-4 py-3 text-center text-xs text-gray-500 dark:text-white/60 border-t border-gray-200 dark:border-white/10">
    Powered by{" "}
    <a 
      href="https://klorah.com" 
      target="_blank" 
      rel="noopener noreferrer"
      className="underline hover:text-gray-900 dark:hover:text-white transition-opacity"
    >
      Klorah
    </a>
  </div>
);

const ProfileDropdown = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showEmailAccounts, setShowEmailAccounts] = useState(false);
  const [hasProfile, setHasProfile] = useState(false);
  const [profileChecked, setProfileChecked] = useState(false);
  const dropdownRef = useRef(null);
  const { activeEmail, accounts, handleSwitchAccount } = useEmail();
  const navigate = useNavigate();

  useEffect(() => {
    const checkProfile = async () => {
      if (user?.uid) {
        try {
          const profileRef = doc(db, 'profiles', user.uid);
          const profileSnap = await getDoc(profileRef);
          setHasProfile(profileSnap.exists());
        } catch (error) {
          console.error('Error checking profile:', error);
        } finally {
          setProfileChecked(true);
        }
      }
    };
    checkProfile();
  }, [user?.uid]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.href = '/';
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Failed to sign out. Please try again.');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const goToProfile = () => {
    setIsOpen(false);
    navigate('/app/profile');
  };

  const goToCreateProfile = () => {
    setIsOpen(false);
    navigate('/app/profile/welcome');
  };

  const goToEditProfile = () => {
    setIsOpen(false);
    navigate('/app/profile/edit');
  };

  const openEmailAccounts = () => {
    setShowEmailAccounts(true);
    setIsOpen(false);
  };

  if (!user) return null;

  return (
    <>
      <div className="relative" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-white/5 transition-colors"
        >
          <ProfileAvatar user={user} />
          <Icons.ChevronDown isOpen={isOpen} />
        </button>

        {isOpen && (
          <div className="absolute right-0 mt-2 w-72 bg-white dark:bg-black/95 backdrop-blur-sm rounded-lg shadow-xl border border-gray-200 dark:border-white/10 py-1 z-50">
            {/* User Info Section */}
            <div className="px-4 py-3 border-b border-gray-200 dark:border-white/10">
              <div className="flex items-center space-x-2">
                <div className="w-6 h-6 bg-[#FFB27D] rounded-full flex items-center justify-center text-sm text-black font-medium">
                  {user?.email?.charAt(0).toUpperCase()}
                </div>
                <div>
                  <p className="text-sm text-gray-900 dark:text-white/80 truncate">{user?.email}</p>
                  <p className="text-xs text-gray-500 dark:text-[#999999]">Klorah Account</p>
                </div>
              </div>
            </div>

            {/* Connected Email Accounts Section */}
            {accounts.length > 0 && (
              <div className="px-2 py-2 border-b border-gray-200 dark:border-white/10">
                <div className="px-2 py-1.5 text-xs font-medium text-gray-500 dark:text-[#999999] uppercase tracking-wider">
                  Connected Email Accounts
                </div>
                {accounts.map(account => (
                  <EmailAccountItem
                    key={account.id}
                    account={account}
                    isActive={account.email === activeEmail}
                    onClick={() => {
                      handleSwitchAccount(account.id);
                      setIsOpen(false);
                    }}
                  />
                ))}
              </div>
            )}

            {/* Klorah Account Section */}
            {hasProfile ? (
              <>
                <MenuItem 
                  icon={<Icons.Profile />}
                  text="View Klorah Profile"
                  onClick={goToProfile}
                />
                <MenuItem 
                  icon={<Icons.Edit />}
                  text="Edit Klorah Profile"
                  onClick={goToEditProfile}
                />
              </>
            ) : (
              <MenuItem 
                icon={<Icons.CreateProfile />}
                text="Create Klorah Profile"
                onClick={goToCreateProfile}
              />
            )}

            {/* Email Management */}
            <MenuItem 
              icon={<Icons.Email />}
              text="Manage Connected Accounts"
              onClick={openEmailAccounts}
            >
              {accounts.length > 0 && (
                <span className="text-xs text-gray-500 dark:text-white/60 group-hover:text-gray-900 dark:group-hover:text-white transition-opacity">
                  {accounts.length}
                </span>
              )}
            </MenuItem>
            
            {/* Sign Out */}
            <MenuItem 
              icon={<Icons.SignOut />}
              text="Sign Out"
              onClick={handleLogout}
            />

            <Footer />
          </div>
        )}
      </div>

      <EmailAccountsModal
        isOpen={showEmailAccounts}
        onClose={() => setShowEmailAccounts(false)}
      />
    </>
  );
};

export default ProfileDropdown;