import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export default function InvoicePreview({ invoiceData, invoiceId }) {
  // Format date for consistent display
  const formatDate = (date) => {
    if (!date) return 'Not specified';
    if (typeof date === 'string') {
      const d = new Date(date);
      return !isNaN(d) ? d.toLocaleDateString() : 'Invalid date';
    }
    if (date instanceof Date && !isNaN(date)) {
      return date.toLocaleDateString();
    }
    return 'Invalid date';
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF({ orientation: 'portrait', unit: 'pt', format: 'a4' });
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 40;
    let yPos = margin;

    // Title
    doc.setFontSize(24);
    doc.text('INVOICE', margin, yPos);
    yPos += 30;

    // Invoice info
    doc.setFontSize(12);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, margin, yPos);
    yPos += 15;
    if (invoiceId) {
      doc.text(`Invoice #: ${invoiceId}`, margin, yPos);
      yPos += 20;
    }

    // Bill To
    doc.setFontSize(14);
    doc.text('Bill To:', margin, yPos);
    yPos += 18;
    doc.setFontSize(12);
    doc.text(invoiceData.client || invoiceData.customer || 'No client name', margin, yPos);
    yPos += 20;

    // Table of items
    const itemDesc = invoiceData.project || invoiceData.gig || 'Service';
    const itemAmount = invoiceData.amount || invoiceData.price || '0.00';
    doc.autoTable({
      startY: yPos,
      head: [['Description', 'Amount']],
      body: [[itemDesc, `${invoiceData.currency} ${itemAmount}`]],
      margin: { left: margin },
      theme: 'striped'
    });
    yPos = doc.autoTable.previous.finalY + 10;

    // Total
    doc.setFontSize(12);
    doc.text('Total:', margin, yPos);
    doc.text(`${invoiceData.currency} ${itemAmount}`, pageWidth - margin, yPos, { align: 'right' });
    yPos += 20;

    // Due date
    doc.text(`Due Date: ${formatDate(invoiceData.dueDate)}`, margin, yPos);
    yPos += 20;

    // Footer
    doc.setFontSize(10);
    doc.text('Thank you for your business!', margin, yPos);

    // Save
    const dateStr = new Date().toISOString().split('T')[0];
    const filename = invoiceId
      ? `invoice-${invoiceId}-${dateStr}.pdf`
      : `invoice-${dateStr}.pdf`;
    doc.save(filename);
  };

  return (
    <div className="bg-[#1A1A1A] border border-[#333] rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Invoice Preview</h2>
        <button
          onClick={handleDownloadPDF}
          className="px-4 py-2 text-sm bg-[#FFB27D] text-black rounded-lg hover:bg-[#FFA164]"
        >
          Download PDF
        </button>
      </div>

      <div className="border border-[#333] rounded-lg p-4">
        <div className="mb-4">
          <h3 className="text-2xl font-bold">INVOICE</h3>
          <p className="text-sm text-[#999999]">Date: {new Date().toLocaleDateString()}</p>
          {invoiceId && <p className="text-sm text-[#999999]">Invoice #: {invoiceId}</p>}
        </div>

        <div className="mb-4">
          <h4 className="text-sm font-medium text-[#999999]">Bill To:</h4>
          <p>{invoiceData.client || invoiceData.customer || 'No client name'}</p>
        </div>

        <div className="mb-4">
          <table className="w-full">
            <thead>
              <tr className="border-b border-[#333]">
                <th className="text-left py-2 text-sm">Description</th>
                <th className="text-right py-2 text-sm">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2">
                  {invoiceData.project || invoiceData.gig || 'Service'}
                </td>
                <td className="py-2 text-right">
                  {invoiceData.currency} {invoiceData.amount || invoiceData.price || '0.00'}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className="border-t border-[#333]">
                <td className="py-2 text-right font-semibold">Total:</td>
                <td className="py-2 text-right font-semibold">
                  {invoiceData.currency} {invoiceData.amount || invoiceData.price || '0.00'}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <p className="text-sm text-[#999999] mb-1">
          Due Date: {invoiceData.dueDate ? formatDate(invoiceData.dueDate) : 'Not specified'}
        </p>
        <p className="text-sm text-[#999999]">Thank you for your business!</p>
      </div>
    </div>
  );
}
