import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_live_51IuUXbLwpaCHfpZ1EZNNYaQYdXSIrE6NrdA3r7lUQOIq5tsdhC7MaTITIMFb3nW4yxqyppRd8C66QcxWhuoPC8aa00Ow2q0o0z"); 
// e.g. process.env.REACT_APP_STRIPE_PK or "pk_test_123..."

function PaymentForm({ clientSecret, invoiceData, invoiceId }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setProcessing(true);
    setError('');

    // Force PaymentElement to validate
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(submitError.message);
      setProcessing(false);
      return;
    }

    // Confirm payment
    const result = await stripe.confirmPayment({ elements, redirect: 'if_required' });
    if (result.error) {
      setError(result.error.message);
      setProcessing(false);
    } else if (result.paymentIntent?.status === 'succeeded') {
      // Mark invoice as paid in Firestore
      const firestore = getFirestore();
      await updateDoc(doc(firestore, 'invoices', invoiceId), {
        status: 'paid',
        paidAt: new Date()
      });
      navigate(`/payment-confirmation/${invoiceId}`);
    }
    setProcessing(false);
  };

  // Just to ensure we display the amount as a decimal if needed
  const displayAmount = invoiceData.amount?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="space-y-1">
        <div className="px-3 py-2 text-sm font-medium text-gray-300">
          Payment Information
        </div>
        <div className="bg-black/30 backdrop-blur-sm rounded-xl p-5 border border-[#333] transition-all duration-300 hover:border-[#444]">
          <PaymentElement className="payment-element-container" />
        </div>
      </div>
      
      {error && (
        <div className="animate-fade-in bg-red-500/10 border border-red-500/20 rounded-lg p-4 flex items-center space-x-3">
          <svg className="w-5 h-5 text-red-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <p className="text-red-400 text-sm">{error}</p>
        </div>
      )}
      
      <button
        type="submit"
        disabled={!stripe || processing}
        className={`w-full py-3.5 px-6 rounded-xl font-medium transition-all duration-300 
          ${processing 
            ? 'bg-gray-700 cursor-not-allowed shadow-none' 
            : 'bg-gradient-to-r from-[#FFB27D] to-[#FFA164] hover:from-[#FFA164] hover:to-[#FF9050] text-black shadow-lg shadow-amber-800/10 hover:shadow-amber-800/20 hover:-translate-y-0.5'}`}
      >
        {processing ? (
          <div className="flex items-center justify-center space-x-2">
            <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span>Processing...</span>
          </div>
        ) : (
          `Pay ${invoiceData.currency} ${displayAmount}`
        )}
      </button>
      
      <div className="flex items-center justify-center space-x-2 text-gray-500 text-xs pt-2">
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
        <span>Secure payment powered by Stripe</span>
      </div>
    </form>
  );
}

export default function PaymentPage() {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadAndCreateIntent = async () => {
      try {
        // 1) Load invoice from Firestore
        const firestore = getFirestore();
        const snap = await getDoc(doc(firestore, 'invoices', invoiceId));
        if (!snap.exists()) {
          setError('Invoice not found.');
          setLoading(false);
          return;
        }
        const invoice = snap.data();

        // --- Convert to a numeric amount. ---
        // If your Firestore doc has 'price' or 'amount', parse accordingly.
        // e.g. invoice.price might be "1000" or 1000. We use parseFloat here.
        const rawPrice = invoice.price ?? invoice.amount ?? '0';
        const numericPrice = parseFloat(rawPrice);
        if (Number.isNaN(numericPrice)) {
          setError('Invoice data has an invalid amount/price.');
          setLoading(false);
          return;
        }

        // Store it back in the object so we can easily pass it around
        invoice.amount = numericPrice;

        setInvoiceData(invoice);

        // If invoice already paid, skip
        if (invoice.status === 'paid') {
          navigate(`/payment-confirmation/${invoiceId}`);
          return;
        }

        // 2) Call your server to create PaymentIntent
        //    (the route you posted: /create-payment-intent)
        const payload = {
          invoiceId,
          userId: invoice.userId,
          // We must send an integer to your server – in cents.
          amount: Math.round(numericPrice * 100),
          currency: invoice.currency,
          description: `Payment for invoice ${invoiceId}`,
          metadata: { invoiceId },
        };
        
        // Only include connectedAccountId if it exists and is not empty
        if (invoice.connectedAccountId) {
          payload.connectedAccountId = invoice.connectedAccountId;
        }
        
        const response = await fetch('https://klorah-fast-server.onrender.com/api/create-payment-intent', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          const text = await response.text();
          setError(`Failed to create PaymentIntent: ${text}`);
          setLoading(false);
          return;
        }

        const { clientSecret } = await response.json();
        setClientSecret(clientSecret);
      } catch (err) {
        setError(`Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    loadAndCreateIntent();
  }, [invoiceId, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-black to-[#111]">
        <div className="flex flex-col items-center">
          <svg className="animate-spin h-10 w-10 text-[#FFB27D]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <div className="mt-4 text-gray-400 font-light tracking-wider">Loading payment details...</div>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-black to-[#111]">
        <div className="max-w-md w-full mx-auto px-8">
          <div className="text-center mb-8">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-500/10 mb-4">
              <svg className="w-8 h-8 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-gray-200 mb-2">Payment Error</h3>
            <p className="text-red-400 bg-red-500/10 px-4 py-3 rounded-lg border border-red-500/20">{error}</p>
          </div>
          <button 
            onClick={() => window.location.reload()} 
            className="w-full py-2.5 px-4 bg-gray-800 hover:bg-gray-700 text-gray-300 rounded-lg transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }
  
  if (!invoiceData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-black to-[#111]">
        <div className="flex flex-col items-center max-w-md text-center">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-800 mb-4">
            <svg className="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-200 mb-2">No Invoice Found</h3>
          <p className="text-gray-500 mb-6">The invoice you're looking for doesn't exist or has been removed.</p>
          <button 
            onClick={() => navigate('/app/emails')} 
            className="py-2.5 px-6 bg-gray-800 hover:bg-gray-700 text-gray-300 rounded-lg transition-colors"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-[#111] text-gray-100">
      <div className="max-w-3xl mx-auto px-4 py-12">
        <div className="flex flex-col items-center mb-8">
          <h1 className="text-2xl tracking-wider mb-1">K L O R A H</h1>
          <div className="h-0.5 w-10 bg-[#FFB27D]"></div>
        </div>
      
        <div className="bg-[#1A1A1A]/80 backdrop-blur-sm border border-[#333] rounded-2xl p-8 md:p-10 shadow-xl shadow-black/30">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-8 pb-6 border-b border-gray-800">
            <div>
              <h2 className="text-2xl font-semibold mb-1 tracking-tight">Invoice Payment</h2>
              <div className="text-sm text-gray-400 flex items-center">
                <span className="inline-block w-2 h-2 rounded-full bg-[#FFB27D] mr-2"></span>
                Invoice #{invoiceId}
              </div>
            </div>
            <div className="bg-gradient-to-r from-[#111111] to-[#1A1A1A] rounded-lg p-3 border border-[#333]">
              <div className="text-xs text-gray-500 mb-1">AMOUNT DUE</div>
              <div className="text-xl md:text-2xl font-semibold text-[#FFB27D]">
                {invoiceData.currency} {invoiceData.amount?.toFixed(2)}
              </div>
            </div>
          </div>
          
          {/* Invoice Details Card */}
          {invoiceData.description && (
            <div className="mb-8">
              <div className="text-sm text-gray-400 mb-2">DETAILS</div>
              <div className="bg-black/30 rounded-lg p-4 border border-[#333]">
                <p className="text-gray-300">{invoiceData.description || "Payment for services"}</p>
              </div>
            </div>
          )}
          
          {clientSecret ? (
            <div className="relative">
              <Elements 
                stripe={stripePromise} 
                options={{ 
                  clientSecret,
                  appearance: {
                    theme: 'night',
                    variables: {
                      colorPrimary: '#FFB27D',
                      colorBackground: '#000000',
                      colorText: '#FFFFFF',
                      colorDanger: '#FF5252',
                      fontFamily: 'system-ui, sans-serif',
                      spacingUnit: '4px',
                      borderRadius: '8px'
                    }
                  }
                }}
              >
                <PaymentForm 
                  clientSecret={clientSecret} 
                  invoiceData={invoiceData} 
                  invoiceId={invoiceId} 
                />
              </Elements>
            </div>
          ) : (
            <div className="text-center py-10">
              <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-800 mb-4">
                <svg className="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <p className="text-gray-400">No payment details found. Please try again.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}