import React from 'react';

export default function CustomerList({ customers, onSelect }) {
  if (!customers || customers.length === 0) {
    return null;
  }

  return (
    <ul className="mt-2 divide-y divide-gray-200 dark:divide-[#333] border border-gray-200 dark:border-[#333] rounded-md">
      {customers.map((client) => (
        <li
          key={client.id}
          className="px-4 py-2 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-[#222] cursor-pointer"
          onClick={() => onSelect(client)}
        >
          {client.name}
        </li>
      ))}
    </ul>
  );
}