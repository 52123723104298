import React from 'react';
import { motion } from 'framer-motion';

export default function ProjectCard({ project, onNavigate }) {
  const { projectName, status, tasks = [], dueDate } = project;
  const completedTasks = tasks.filter((t) => t.status === 'complete').length;
  const totalTasks = tasks.length || 1;
  const progress = Math.round((completedTasks / totalTasks) * 100);
  const daysRemaining = dueDate ? Math.ceil((dueDate.toDate() - new Date()) / (86400000)) : 0;

  return (
    <motion.div
      whileHover={{ y: -4 }}
      className="bg-white dark:bg-[#111111] rounded-xl border border-gray-200 dark:border-[#1A1A1A] overflow-hidden cursor-pointer"
      onClick={() => onNavigate(project.id)}
    >
      <div className="p-4 space-y-4">
        <div className="flex items-center justify-between">
          <h3 className="text-gray-900 dark:text-white text-lg font-medium">{projectName}</h3>
          <span
            className={`px-2 py-1 rounded-full text-xs font-medium ${
              status === 'In Progress'
                ? 'bg-[#FFB27D]/10 text-[#FFB27D]'
                : status === 'Completed'
                ? 'bg-green-500/10 text-green-500'
                : 'bg-yellow-500/10 text-yellow-500'
            }`}
          >
            {status}
          </span>
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between text-sm text-gray-500 dark:text-[#999999]">
            <span>Progress</span>
            <span>{progress}%</span>
          </div>
          <div className="w-full bg-gray-100 dark:bg-[#1A1A1A] rounded-full h-1.5">
            <div
              className="bg-[#FFB27D] h-1.5 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
        <div className="flex items-center justify-between text-sm text-gray-500 dark:text-[#999999]">
          <span>{completedTasks} of {totalTasks} tasks</span>
          <span>{daysRemaining} days left</span>
        </div>
      </div>
    </motion.div>
  );
} 