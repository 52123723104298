import React, { useState } from 'react';
import { useEmail } from '../contexts/EmailContext';
import { toast } from 'react-hot-toast';

const EmailAccountsModal = ({ isOpen, onClose, onAccountSwitch }) => {
  const {
    accounts,
    activeAccountId,
    handleGmailAuth,
    handleSwitchAccount,
    handleDeleteAccount,
    isGmailAuthorized,
    isAddingAccount,
    isDeletingAccount
  } = useEmail();

  const [accountToDelete, setAccountToDelete] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  if (!isOpen) return null;

  const confirmDeleteAccount = (account) => {
    setAccountToDelete(account);
    setShowDeleteConfirm(true);
  };

  const cancelDelete = () => {
    setAccountToDelete(null);
    setShowDeleteConfirm(false);
  };

  const deleteAccount = async () => {
    if (!accountToDelete) return;

    try {
      const result = await handleDeleteAccount(accountToDelete.id);
      if (result.success) {
        toast.success(`Account ${accountToDelete.email} has been disconnected`);
        setShowDeleteConfirm(false);
        setAccountToDelete(null);
      } else {
        toast.error('Failed to disconnect account');
      }
    } catch (error) {
      console.error('Error disconnecting account:', error);
      toast.error('Failed to disconnect account: ' + (error.message || 'Unknown error'));
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-[#1A1A1A] rounded-lg p-6 w-full max-w-md">
        {!showDeleteConfirm ? (
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl text-gray-900 dark:text-[#FFB27D] font-semibold">Email Accounts</h2>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
                <svg className="w-6 h-6" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {accounts.length > 0 ? (
              <div className="space-y-4">
                {accounts.map((account) => (
                  <div
                    key={account.id}
                    className={`p-4 rounded-lg cursor-pointer transition-colors relative ${
                      activeAccountId === account.id
                        ? 'bg-[#FFB27D]/10 border border-[#FFB27D] dark:bg-[#FFB27D]/20 dark:border-[#FFB27D]'
                        : 'bg-gray-50 hover:bg-gray-100 dark:bg-[#2A2A2A] dark:hover:bg-[#3A3A3A] border border-gray-100 dark:border-[#2A2A2A]'
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex-1">
                        <p className="text-gray-900 dark:text-white font-medium">{account.email}</p>
                        {activeAccountId === account.id && (
                          <span className="text-sm text-[#FFB27D]">Active Account</span>
                        )}
                      </div>
                      <div className="flex items-center space-x-2">
                        {activeAccountId !== account.id && (
                          <button
                            onClick={() => {
                              handleSwitchAccount(account.id, onAccountSwitch);
                              onClose();
                            }}
                            className="px-3 py-1.5 bg-gray-100 hover:bg-gray-200 dark:bg-[#333] dark:hover:bg-[#444] rounded-md text-sm text-[#FFB27D] transition-colors"
                          >
                            Use This
                          </button>
                        )}
                        <button
                          onClick={() => confirmDeleteAccount(account)}
                          className="text-red-500 hover:text-red-600 dark:text-red-400 dark:hover:text-red-300 p-1.5 rounded-md hover:bg-red-50 dark:hover:bg-red-500/10 transition-colors"
                          disabled={isDeletingAccount}
                          title="Disconnect account"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1.5}
                              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 dark:text-gray-400 text-center mb-4">No email accounts connected</p>
            )}

            <button
              onClick={handleGmailAuth}
              disabled={isAddingAccount}
              className={`mt-6 w-full bg-[#FFB27D] text-black font-medium py-2 px-4 rounded-lg hover:bg-[#FFA164] transition-colors flex items-center justify-center ${
                isAddingAccount ? 'opacity-70 cursor-not-allowed' : ''
              }`}
            >
              {isAddingAccount ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Connecting...
                </>
              ) : (
                <>{isGmailAuthorized ? 'Connect Another Account' : 'Connect Gmail Account'}</>
              )}
            </button>
          </>
        ) : (
          // Disconnect Confirmation View
          <>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl text-red-600 dark:text-red-400 font-semibold">Confirm Disconnection</h2>
              <button onClick={cancelDelete} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
                <svg className="w-6 h-6" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="p-4 bg-red-50 dark:bg-red-900/20 border border-red-100 dark:border-red-500/30 rounded-lg mb-6">
              <p className="text-gray-900 dark:text-white mb-2">Are you sure you want to disconnect this account?</p>
              <p className="text-red-600 dark:text-red-300 font-medium">{accountToDelete?.email}</p>
              <p className="text-gray-500 dark:text-gray-400 text-sm mt-4">
                This will remove the account from Klorah. Your Gmail account will remain intact, and you can always reconnect it later.
              </p>
            </div>

            <div className="flex space-x-3">
              <button
                onClick={cancelDelete}
                className="flex-1 py-2 px-4 bg-gray-100 hover:bg-gray-200 dark:bg-[#2A2A2A] dark:hover:bg-[#3A3A3A] text-gray-900 dark:text-white rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={deleteAccount}
                disabled={isDeletingAccount}
                className={`flex-1 py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors flex items-center justify-center ${
                  isDeletingAccount ? 'opacity-70 cursor-not-allowed' : ''
                }`}
              >
                {isDeletingAccount ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Disconnecting...
                  </>
                ) : (
                  'Disconnect Account'
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailAccountsModal;
