import React, { useEffect, useState, useRef } from 'react';
import EmailList from './EmailList';
import EmailView from './EmailView';
import { useEmail } from '../contexts/EmailContext';
import EmailService from '../services/EmailService';
import ComposeEmail from './ComposeEmail';
import AccountSwitcher from './AccountSwitcher';
import EmailAccountsModal from './EmailAccountsModal';
import InvoiceModal from './InvoiceModal';
import AgreementModal from './AgreementModal';

const EmailSection = ({ user }) => {
  const {
    emails,
    loading: emailsLoading,
    error: emailError,
    isGmailAuthorized,
    authStatus,
    handleGmailAuth,
    handleLoadMore,
    hasMore,
    fetchAccounts,
    activeAccountId
  } = useEmail() || {};
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [fullEmailContent, setFullEmailContent] = useState(null);
  const [loadingContent, setLoadingContent] = useState(false);
  const [contentError, setContentError] = useState(null);
  const [showComposeModal, setShowComposeModal] = useState(false);
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [aiSummary, setAiSummary] = useState(null);
  const [isAiLoading, setIsAiLoading] = useState(false);
  const hasAttemptedFetch = useRef(false);
  const previousAccountId = useRef(activeAccountId);

  // Clear selected email when account changes
  useEffect(() => {
    if (previousAccountId.current !== activeAccountId) {
      setSelectedEmail(null);
      setFullEmailContent(null);
      setAiSummary(null);
      setContentError(null);
      previousAccountId.current = activeAccountId;
    }
  }, [activeAccountId]);

  useEffect(() => {
    if (isGmailAuthorized && emails.length === 0 && !emailsLoading && !hasAttemptedFetch.current && fetchAccounts) {
      hasAttemptedFetch.current = true;
      fetchAccounts().catch(() => {});
    }
  }, [isGmailAuthorized, emails.length, emailsLoading, fetchAccounts]);

  const handleSummarizeEmail = async () => {
    if (!selectedEmail) return;
    setIsAiLoading(true);
    try {
      const result = await EmailService.analyzeEmail({
        user_id: user.uid,
        email_id: selectedEmail.id,
        account_id: selectedEmail.accountId
      });
      setAiSummary(result.summary);
    } catch (error) {
      console.error('Error analyzing email:', error);
    } finally {
      setIsAiLoading(false);
    }
  };

  const handleRefreshEmail = async () => {
    if (!selectedEmail) return;
    setLoadingContent(true);
    try {
      const emailContent = await EmailService.getEmailContent(user.uid, selectedEmail.id, selectedEmail.accountId);
      setFullEmailContent(emailContent);
    } catch (error) {
      console.error('Error refreshing email:', error);
    } finally {
      setLoadingContent(false);
    }
  };

  // Handle account switching by clearing the selected email
  const handleAccountSwitch = () => {
    setSelectedEmail(null);
    setFullEmailContent(null);
    setAiSummary(null);
    setContentError(null);
  };

  // Handle email selection
  const handleSelectEmail = async (email) => {
    // If selecting a different email, clear current content and fetch new content
    if (email?.id !== selectedEmail?.id) {
      setSelectedEmail(email);
      setFullEmailContent(null);
      setContentError(null);
      setAiSummary(null);

      if (email) {
        // Ensure the email has the correct accountId
        const emailWithAccount = {
          ...email,
          accountId: email.accountId || activeAccountId
        };
        setSelectedEmail(emailWithAccount);
      }
    }
  };

  // -------------------------------------------------
  // Load the full HTML/text content for the selected email
  // -------------------------------------------------
  useEffect(() => {
    const fetchEmailContent = async () => {
      if (!selectedEmail || !user?.uid) {
        setFullEmailContent(null);
        setContentError(null);
        return;
      }

      // Ensure we have an accountId
      const emailAccountId = selectedEmail.accountId || activeAccountId;
      
      if (!emailAccountId) {
        console.error('No account ID available for email:', selectedEmail);
        setContentError('Unable to load email: No account ID available');
        return;
      }

      try {
        setLoadingContent(true);
        setContentError(null);
        console.log('Fetching email content for:', {
          emailId: selectedEmail.id,
          accountId: emailAccountId,
          userId: user.uid
        });
        
        const data = await EmailService.getEmailContent(
          user.uid,
          selectedEmail.id,
          emailAccountId
        );
        
        if (!data) {
          throw new Error('No data received from server');
        }

        // Set full email content with all necessary fields
        setFullEmailContent({
          ...selectedEmail,
          accountId: emailAccountId,
          content: data.payload?.html || data.payload?.text || data.snippet || selectedEmail.snippet,
          body: {
            html: data.payload?.html || '',
            text: data.payload?.text || ''
          },
          snippet: data.snippet || selectedEmail.snippet,
          threadId: data.threadId || selectedEmail.threadId
        });
      } catch (error) {
        console.error('Error in fetchEmailContent:', error);
        
        let errorMessage = 'Unable to load email content. Please try again later.';
        if (error.message) {
          errorMessage = error.message;
        }
        
        setContentError(errorMessage);
        
        // Set minimal content as fallback
        setFullEmailContent({
          ...selectedEmail,
          accountId: emailAccountId,
          content: selectedEmail.snippet || '',
          body: {
            html: '',
            text: selectedEmail.snippet || ''
          }
        });
      } finally {
        setLoadingContent(false);
      }
    };

    fetchEmailContent();
  }, [selectedEmail, user, activeAccountId]);

  const handleComposeClick = () => {
    setShowComposeModal(true);
  };

  const handleEmailSent = (result) => {
    setShowComposeModal(false);
    setEmailSent(true);
    setTimeout(() => {
      setEmailSent(false);
    }, 3000);
  };

  const renderAuthPrompt = () => (
    <div className="flex-1 flex items-center justify-center p-4 bg-white dark:bg-black">
      <div className="text-center max-w-md mx-auto">
        <svg className="w-16 h-16 mx-auto mb-6 text-[#FFB27D]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
        <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">Connect Your Gmail Account</h2>
        <p className="text-gray-500 dark:text-[#666666] mb-6">To start managing your emails, connect your Gmail account with Klorah</p>
        {emailError && (
          <div className="mb-4 px-4 py-2 bg-red-50 dark:bg-red-900/10 text-red-600 dark:text-red-400 rounded">
            <p>{emailError}</p>
          </div>
        )}
        <button
          onClick={handleGmailAuth}
          className="px-6 py-3 bg-[#FFB27D] text-black rounded-lg hover:bg-[#FFA164] transition-colors"
        >
          Connect Gmail
        </button>
      </div>
    </div>
  );

  const renderLoadingState = () => (
    <div className="flex-1 flex items-center justify-center p-4 bg-white dark:bg-black">
      <div className="text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-[#FFB27D] border-t-transparent mb-4" />
        <p className="text-gray-500 dark:text-[#666666]">Connecting to Gmail...</p>
      </div>
    </div>
  );

  if (authStatus === 'checking') {
    return renderLoadingState();
  }

  if (!isGmailAuthorized || authStatus === 'unauthorized') {
    return renderAuthPrompt();
  }

  return (
    <div className="h-full flex flex-col bg-white dark:bg-black overflow-hidden">
      {/* Top Bar */}
      {/* <div className="flex items-center justify-between border-b border-[#1A1A1A] p-3">
        <div className="flex items-center space-x-3">
          <button
            onClick={() => setShowAccountsModal(true)}
            className="text-sm text-[#999999] hover:text-white"
          >
            Manage Accounts
          </button>
        </div>
      </div> */}
      
      <div className="flex-1 flex overflow-hidden">
        {/* Left side: EmailList */}
        <div
          className={`h-full w-full md:w-[380px] flex-shrink-0 overflow-y-auto ${
            selectedEmail ? 'hidden md:block' : ''
          } border-r border-gray-100 dark:border-[#1A1A1A]`}
        >
          <EmailList
            emails={emails}
            loading={emailsLoading}
            error={emailError}
            selectedEmail={selectedEmail}
            onSelectEmail={handleSelectEmail}
            onLoadMore={typeof handleLoadMore === 'function' ? handleLoadMore : undefined}
            hasMore={hasMore}
          />
        </div>

        {/* Right side: EmailView */}
        <div className={`flex-1 h-full overflow-hidden ${!selectedEmail ? 'hidden md:block' : ''}`}>
          <EmailView
            email={fullEmailContent || selectedEmail}
            aiSummary={aiSummary}
            isAiLoading={isAiLoading || loadingContent}
            onSummarizeEmail={handleSummarizeEmail}
            onBack={() => setSelectedEmail(null)}
            user={user}
            setShowInvoiceModal={setShowInvoiceModal}
            setShowAgreementModal={setShowAgreementModal}
            error={contentError}
            onRefresh={handleRefreshEmail}
          />
        </div>
      </div>

      {/* Compose Modal */}
      <ComposeEmail
        isOpen={showComposeModal}
        onClose={() => setShowComposeModal(false)}
        user={user}
        onEmailSent={handleEmailSent}
      />
      
      {/* Email Accounts Modal */}
      <EmailAccountsModal
        isOpen={showAccountsModal}
        onClose={() => setShowAccountsModal(false)}
        onAccountSwitch={handleAccountSwitch}
      />
      
      {/* Invoice Modal */}
      <InvoiceModal 
        isOpen={showInvoiceModal}
        onClose={() => setShowInvoiceModal(false)}
        user={user}
        email={selectedEmail}
      />
      
      {/* Agreement Modal */}
      <AgreementModal 
        isOpen={showAgreementModal}
        onClose={() => setShowAgreementModal(false)}
        email={selectedEmail}
      />
    </div>
  );
};

export default EmailSection;