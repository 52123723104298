import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, collection, onSnapshot, updateDoc, arrayUnion } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { InvoiceService } from '../services/InvoiceService';
import { toast } from 'react-hot-toast';
import { useStripe } from '../contexts/StripeContext';
import { useEmail } from '../contexts/EmailContext';
import CustomerList from './CustomerList';
import ServiceList from './ServiceList';

const InvoiceModal = ({ isOpen, onClose, project, client, email, user }) => {
  const navigate = useNavigate();
  const { openCompose } = useEmail() || {};
  const { isOnboarded, stripeAccountId } = useStripe();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);

  // Initialize invoice data with project and client details if available
  const [invoiceData, setInvoiceData] = useState({
    client: client?.name || email?.sender || '',
    email: client?.email || email?.from || '',
    project: project?.projectName || email?.subject || '',
    amount: '',
    currency: 'USD',
    dueDate: project?.dueDate?.toDate() || new Date(),
    notes: '',
    sendEmail: true,
    projectId: project?.id || null,
    clientId: client?.id || project?.clientId || null
  });

  // Show/hide currency dropdown
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const currencies = ['USD', 'EUR', 'GBP', 'CAD', 'AUD', 'JPY'];

  // Load the user's services from Firestore
  useEffect(() => {
    const fetchServices = async () => {
      if (!user) return;
      const firestore = getFirestore();
      const profileRef = doc(firestore, 'profiles', user.uid);
      const profileSnapshot = await getDoc(profileRef);
      if (profileSnapshot.exists()) {
        const data = profileSnapshot.data();
        setServices(data.services || []);
      }
    };
    fetchServices();
  }, [user]);

  // Load the user's clients from Firestore
  useEffect(() => {
    if (!user) return;
    const firestore = getFirestore();
    const clientsRef = collection(firestore, 'users', user.uid, 'clients');
    const unsubscribe = onSnapshot(clientsRef, snapshot => {
      const clientsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(clientsData);
    });
    return () => unsubscribe();
  }, [user]);

  // Close modal if not open
  if (!isOpen) return null;

  // Helper to update invoiceData
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData(prev => ({ ...prev, [name]: value }));
  };

  // If user selects a service from the list
  const handleServiceSelect = (service) => {
    setInvoiceData(prev => ({
      ...prev,
      project: service.name,
      amount: service.price,
      currency: service.currency || 'USD'
    }));
  };

  // If user selects a client from the list
  const handleCustomerSelect = (customer) => {
    setInvoiceData(prev => ({
      ...prev,
      client: customer.name,
      email: customer.email,
      clientId: customer.id,
      notes: `Dear ${customer.name},\n\nPlease find attached the invoice for ${prev.project}.`
    }));
  };

  // Submits the invoice
  const handleSubmit = async () => {
    // Basic validations
    setError('');
    if (!isOnboarded || !stripeAccountId) {
      setError('Please set up your Stripe account first.');
      return;
    }
    if (!user) {
      setError('You must be logged in to create an invoice.');
      return;
    }
    if (!invoiceData.client || !invoiceData.project || !invoiceData.amount) {
      setError('Please fill out all required fields (client, project, amount).');
      return;
    }

    setIsSubmitting(true);
    try {
      // Create the invoice in Firestore
      const invoiceService = new InvoiceService(user);
      const invoiceId = await invoiceService.createInvoice({
        ...invoiceData,
        userId: user.uid,
        status: 'pending',
        createdAt: new Date()
      });

      // Update project with invoice reference if this is a project-specific invoice
      if (project?.id) {
        const db = getFirestore();
        const projectRef = doc(db, 'projects', project.id);
        await updateDoc(projectRef, {
          invoices: arrayUnion(invoiceId)
        });
      }

      // If "sendEmail" is true, compose an email
      if (invoiceData.sendEmail && invoiceData.email && openCompose) {
        const invoiceUrl = `${window.location.origin}/preview-invoice/${invoiceId}`;
        const emailBody = `
          <p>Hello ${invoiceData.client},</p>
          <p>I've created an invoice for our project <b>"${invoiceData.project}"</b>. 
             You can view and pay using the link below:</p>
          <p><a href="${invoiceUrl}">View Invoice</a></p>
          <p>Amount: ${invoiceData.currency} ${invoiceData.amount}<br/>
             Due Date: ${invoiceData.dueDate.toLocaleDateString()}</p>
          <p>${invoiceData.notes}</p>
          <p>Thank you for your business!</p>
        `;
        openCompose({
          to: invoiceData.email,
          subject: `Invoice for ${invoiceData.project}`,
          content: emailBody
        });
      } else {
        // Otherwise, just navigate to the preview
        navigate(`/preview-invoice/${invoiceId}`);
      }

      toast.success('Invoice created successfully!');
      onClose();
    } catch (err) {
      console.error('Error creating invoice:', err);
      setError('Failed to create invoice. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-md flex items-center justify-center p-6 z-[999]">
      <div className="bg-white dark:bg-black rounded-3xl shadow-2xl w-full max-w-lg">
        {/* Header */}
        <div className="px-8 py-6 border-b border-gray-100 dark:border-gray-800">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-medium">Create Invoice</h2>
            <button onClick={onClose} className="p-2 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-full transition-colors">
              <svg className="w-5 h-5" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        {/* Body */}
        <div className="px-8 py-6 space-y-6">
          {error && <p className="text-sm text-red-500 bg-red-50 dark:bg-red-900/20 p-4 rounded-2xl">{error}</p>}

          {/* Client */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Client</label>
            <input
              type="text"
              name="client"
              className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
              placeholder="Client name"
              value={invoiceData.client}
              onChange={handleChange}
            />
            {!client && <CustomerList customers={customers} onSelect={handleCustomerSelect} />}
          </div>

          {/* Project */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Project</label>
            <input
              type="text"
              name="project"
              className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
              placeholder="Project name"
              value={invoiceData.project}
              onChange={handleChange}
              readOnly={!!project}
            />
            {!project && <ServiceList services={services} onSelect={handleServiceSelect} />}
          </div>

          {/* Amount + Currency */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium">Amount</label>
              <input
                type="number"
                name="amount"
                className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
                placeholder="0.00"
                value={invoiceData.amount}
                onChange={handleChange}
              />
            </div>

            <div className="relative space-y-2">
              <label className="block text-sm font-medium">Currency</label>
              <button
                type="button"
                onClick={() => setDropdownVisible(!dropdownVisible)}
                className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 text-left flex items-center justify-between"
              >
                {invoiceData.currency}
                <svg className={`w-4 h-4 transition-transform ${dropdownVisible ? 'rotate-180' : ''}`} fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {dropdownVisible && (
                <div className="absolute w-full bg-white dark:bg-gray-900 shadow-lg rounded-2xl mt-1 py-2 z-10">
                  {currencies.map((cur) => (
                    <button
                      key={cur}
                      type="button"
                      className="w-full px-4 py-2 text-left hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
                      onClick={() => {
                        setInvoiceData(prev => ({ ...prev, currency: cur }));
                        setDropdownVisible(false);
                      }}
                    >
                      {cur}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Due Date */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Due Date</label>
            <DatePicker
              selected={invoiceData.dueDate}
              onChange={(date) => setInvoiceData(prev => ({ ...prev, dueDate: date }))}
              className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
              dateFormat="MMMM d, yyyy"
            />
          </div>

          {/* Notes */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Notes</label>
            <textarea
              name="notes"
              rows="3"
              className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
              placeholder="Add any additional notes..."
              value={invoiceData.notes}
              onChange={handleChange}
            />
          </div>

          {/* Email? */}
          <label className="flex items-center gap-3 py-2 cursor-pointer group">
            <input
              type="checkbox"
              className="w-5 h-5 rounded-lg border-2 border-gray-300 dark:border-gray-700 checked:border-gray-900 dark:checked:border-white transition-colors"
              checked={invoiceData.sendEmail}
              onChange={(e) => setInvoiceData(prev => ({ ...prev, sendEmail: e.target.checked }))}
            />
            <span className="text-sm group-hover:opacity-80 transition-opacity">Send invoice via email</span>
          </label>
        </div>

        {/* Footer */}
        <div className="px-8 py-6 border-t border-gray-100 dark:border-gray-800 flex justify-end gap-4">
          <button
            className="px-6 py-3 text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-900 rounded-2xl transition-colors"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className={`px-6 py-3 text-sm font-medium rounded-2xl transition-colors ${
              isSubmitting
                ? 'bg-gray-100 dark:bg-gray-900 opacity-50 cursor-not-allowed'
                : 'bg-gray-900 dark:bg-white text-white dark:text-black hover:opacity-90'
            }`}
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Creating...' : 'Create Invoice'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceModal;