 // Start of Selection
import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore'
import { motion } from 'framer-motion'
import { toast } from 'react-hot-toast'
import { getAuth } from 'firebase/auth'
import { generateAgreementPDF } from '../utils/pdfutils'
import SignatureCanvas from 'react-signature-canvas'
import { ref, uploadString } from 'firebase/storage'
import { db, storage } from '../firebase'

// Example SVG icons
const ShareIcon = () => (
  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
      d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342
       m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0
       a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.367 2.684
       3 3 0 00-5.367-2.684z"
    />
  </svg>
)

const DownloadIcon = () => (
  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
    />
  </svg>
)

const EditIcon = () => (
  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0
       113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
    />
  </svg>
)

// 1) Helper to transform near-white pixels to black on a signature dataURL
function convertWhiteToBlack(dataUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      const imageData = ctx.getImageData(0, 0, img.width, img.height)
      const d = imageData.data
      for (let i = 0; i < d.length; i += 4) {
        const r = d[i]
        const g = d[i + 1]
        const b = d[i + 2]
        // If pixel is "almost white"
        if (r > 200 && g > 200 && b > 200) {
          // set to black
          d[i] = 0
          d[i + 1] = 0
          d[i + 2] = 0
        }
      }
      ctx.putImageData(imageData, 0, 0)
      const newUrl = canvas.toDataURL('image/png')
      resolve(newUrl)
    }
    img.onerror = reject
    img.src = dataUrl
  })
}

export default function Agreement() {
  const { agreementId } = useParams()
  const navigate = useNavigate()
  const auth = getAuth()
  const location = useLocation()

  const [agreement, setAgreement] = useState(null)
  const [loading, setLoading] = useState(true)
  const [downloading, setDownloading] = useState(false)
  const [isPublicView, setIsPublicView] = useState(false)

  // Editing states
  const [isEditing, setIsEditing] = useState(false)
  const [editedText, setEditedText] = useState('')
  const [aiInstruction, setAiInstruction] = useState('')
  const [isAiLoading, setIsAiLoading] = useState(false)

  // Signature states
  const [showSignatureCanvas, setShowSignatureCanvas] = useState(false)
  const [signatureType, setSignatureType] = useState('draw') // 'draw' or 'type'
  const [typedSignature, setTypedSignature] = useState('')
  const signatureRef = useRef(null)

  // For scrolling to signature area
  const signatureSectionRef = useRef(null)

  useEffect(() => {
    const fetchAgreement = async () => {
      try {
        const db = getFirestore()
        const docRef = doc(db, 'agreements', agreementId)
        const agreementDoc = await getDoc(docRef)
        if (!agreementDoc.exists()) {
          toast.error('Agreement not found')
          navigate('/app/agreements')
          return
        }
        const data = agreementDoc.data()
        setAgreement({ id: agreementDoc.id, ...data })
        setEditedText(data.text || '')
      } catch (error) {
        console.error('Error fetching agreement:', error)
        toast.error('Failed to load agreement')
      } finally {
        setLoading(false)
      }
    }
    fetchAgreement()
  }, [agreementId, navigate])

  useEffect(() => {
    setIsPublicView(!auth.currentUser)
  }, [auth.currentUser])

  // If ?download in URL, auto download
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has('download')) {
      handleDownloadPDF()
    }
  }, [location.search])

  // Download PDF
  const handleDownloadPDF = async () => {
    if (!agreement) return
    setDownloading(true)
    try {
      // Pass only the signature base64 data to generateAgreementPDF
      const doc = generateAgreementPDF(agreement, {
        freelancerSignature: agreement.freelancerSignature?.signature,
        clientSignature: agreement.clientSignature?.signature,
      })
      doc.save(`${agreement?.title || 'agreement'}.pdf`)
      toast.success('Agreement downloaded successfully')
    } catch (error) {
      console.error('Error generating PDF:', error)
      toast.error('Failed to download agreement')
    } finally {
      setDownloading(false)
    }
  }

  // Copy link
  const handleCopyLink = () => {
    const link = `${window.location.origin}/agreements/${agreementId}`
    navigator.clipboard.writeText(link).then(() => {
      toast.success('Link copied to clipboard')
    })
  }

  // Start editing
  const handleEditClick = () => {
    setIsEditing(true)
  }

  // Save agreement text
  const handleSave = async () => {
    if (!agreement) return
    setLoading(true)
    try {
      const db = getFirestore()
      await updateDoc(doc(db, 'agreements', agreement.id), {
        text: editedText,
      })
      setAgreement({ ...agreement, text: editedText })
      toast.success('Agreement updated successfully')
      setIsEditing(false)
    } catch (error) {
      console.error('Error saving edits:', error)
      toast.error('Failed to save agreement')
    } finally {
      setLoading(false)
    }
  }

  // AI Edit
  const handleAiEdit = async () => {
    if (!editedText.trim()) {
      toast.error('No agreement text to edit')
      return
    }
    if (!aiInstruction.trim()) {
      toast.error('Please enter an instruction for AI editing')
      return
    }

    setIsAiLoading(true)
    try {
      const response = await fetch('https://klorah-fast-server.onrender.com/api/ai-edit', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          text: editedText,
          instruction: aiInstruction,
        }),
      })

      if (!response.ok) {
        throw new Error('Failed AI edit request')
      }

      const result = await response.json()
      if (result.edited_text) {
        setEditedText(result.edited_text)
        toast.success('AI edit applied')
      } else {
        toast.error('No edited text returned by AI')
      }
    } catch (err) {
      console.error(err)
      toast.error('AI edit failed')
    } finally {
      setIsAiLoading(false)
    }
  }

  // Cancel editing
  const handleCancel = () => {
    setEditedText(agreement?.text || '')
    setIsEditing(false)
  }

  // Clears the signature pad or typed signature
  const handleClear = () => {
    if (signatureType === 'draw') {
      signatureRef.current?.clear()
    } else {
      setTypedSignature('')
    }
  }

  // 2) final sign & save
  const handleSaveSignature = async () => {
    if (!agreement) return

    try {
      let signatureData
      if (signatureType === 'draw') {
        // if the user hasn't drawn anything
        if (signatureRef.current?.isEmpty()) {
          toast.error('Please provide a signature')
          return
        }
        const rawDataUrl = signatureRef.current.toDataURL()
        // Convert near-white → black
        signatureData = await convertWhiteToBlack(rawDataUrl)
      } else {
        // typed signature => generate a black text signature in a canvas
        if (!typedSignature.trim()) {
          toast.error('Please provide a signature')
          return
        }
        // For typed mode, let's do a small canvas approach to create black text on transparent
        const canvas = document.createElement('canvas')
        canvas.width = 500
        canvas.height = 150
        const ctx = canvas.getContext('2d')
        ctx.fillStyle = '#000000' // black text
        ctx.font = '48px serif'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.fillText(typedSignature, canvas.width / 2, canvas.height / 2)
        signatureData = canvas.toDataURL('image/png')
      }

      // 3) Upload signatureData to Storage if you want
      const fileRef = ref(storage, `signed_agreements/${agreementId}-${Date.now()}.png`)
      await uploadString(fileRef, signatureData, 'data_url')

      // 4) Update Firestore doc
      let fieldName = 'freelancerSignature'
      if (!auth.currentUser) fieldName = 'clientSignature'
      const docRef = doc(db, 'agreements', agreement.id)
      await updateDoc(docRef, {
        status: 'signed',
        signedAt: new Date().toISOString(),
        [fieldName]: {
          signature: signatureData,
          timestamp: Date.now()
        }
      })

      toast.success('Agreement signed successfully!')
      setShowSignatureCanvas(false)
      // Optionally auto download the entire agreement
      handleDownloadPDF()
    } catch (error) {
      console.error('Error saving signature:', error)
      toast.error('Failed to save signature. Please try again.')
    }
  }

  // Helper to open signature pad
  const openSignaturePad = () => {
    setShowSignatureCanvas(true)
    requestAnimationFrame(() => {
      signatureSectionRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      })
    })
  }

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center bg-[#111111]">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#FFB27D]" />
      </div>
    )
  }

  if (!agreement) {
    return null
  }

  return (
    <div className="min-h-screen bg-[#111111] text-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-8"
        >
          {/* Header + Actions */}
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-6">
            <div>
              <h1 className="text-2xl sm:text-3xl font-semibold text-white">
                {agreement.title || `Agreement with ${agreement.client}`}
              </h1>
              <p className="mt-2 text-[#999999]">
                {agreement.createdAt?.toDate().toLocaleDateString() || new Date().toLocaleDateString()}
              </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              {!isPublicView && (
                <>
                  <button
                    onClick={handleCopyLink}
                    className="inline-flex items-center justify-center gap-2 px-4 py-2.5
                      border border-[#333333] text-sm font-medium rounded-lg text-[#999999]
                      bg-[#1A1A1A] hover:bg-[#222222] hover:text-white transition-colors"
                  >
                    <ShareIcon />
                    <span>Share</span>
                  </button>
                  <button
                    onClick={handleDownloadPDF}
                    disabled={downloading}
                    className="inline-flex items-center justify-center gap-2 px-4 py-2.5
                      border border-[#333333] text-sm font-medium rounded-lg text-[#999999]
                      bg-[#1A1A1A] hover:bg-[#222222] hover:text-white transition-colors"
                  >
                    <DownloadIcon />
                    <span>{downloading ? 'Downloading...' : 'Download'}</span>
                  </button>
                </>
              )}
              {agreement.status !== 'signed' && (
                <>
                  <button
                    onClick={openSignaturePad}
                    className="inline-flex items-center justify-center gap-2 px-4 py-2.5
                      text-sm font-medium rounded-lg text-black bg-[#FFB27D]
                      hover:bg-[#FFA164] transition-colors"
                  >
                    <EditIcon />
                    <span>Sign Now</span>
                  </button>
                  {!isEditing && (
                    <button
                      onClick={handleEditClick}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2.5
                        text-sm font-medium rounded-lg text-black bg-[#FFB27D]
                        hover:bg-[#ffa864] transition-colors"
                    >
                      <EditIcon />
                      <span>Edit Agreement</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Agreement Text */}
          <div className="flex flex-col lg:flex-row gap-8">
            {/* The text area */}
            <div className={`prose prose-invert ${showSignatureCanvas ? 'lg:w-3/5' : 'w-full'}`}>
              <h2 className="text-xl font-semibold text-white mb-6">Agreement Details</h2>
              {isEditing ? (
                <div className="space-y-4">
                  <textarea
                    className="w-full h-48 bg-[#1A1A1A] text-white p-4 border
                      border-[#333] rounded-lg focus:outline-none"
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                  />
                  <input
                    type="text"
                    className="w-full bg-[#1A1A1A] text-white p-2 border
                      border-[#333] rounded-lg focus:outline-none"
                    placeholder="Enter AI edit instruction (e.g., 'Make it more formal')"
                    value={aiInstruction}
                    onChange={(e) => setAiInstruction(e.target.value)}
                  />

                  <div className="flex gap-3 mt-4">
                    <button
                      onClick={handleAiEdit}
                      disabled={isAiLoading}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium
                        rounded-lg text-black bg-[#FFB27D] hover:bg-[#FFA164]"
                    >
                      {isAiLoading ? 'Applying AI Edit...' : 'Apply AI Edit'}
                    </button>
                    <button
                      onClick={handleSave}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium
                        rounded-lg text-black bg-green-500 hover:bg-green-400"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium
                        rounded-lg text-white bg-red-600 hover:bg-red-500"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="text-[#E6E6E6] leading-relaxed"
                  dangerouslySetInnerHTML={{
                    __html: (agreement.text || '')
                      .split('\n')
                      .map(line => (line.trim() ? `<p class="mb-6">${line}</p>` : '<br/>'))
                      .join('')
                  }}
                />
              )}
            </div>

            {/* The signature area (visible if showSignatureCanvas = true) */}
            {showSignatureCanvas && (
              <div
                ref={signatureSectionRef}
                className="lg:w-2/5 bg-[#1A1A1A] border border-[#333333] p-6 rounded-lg shadow-md"
              >
                <h2 className="text-xl font-semibold mb-6 text-white">Sign Agreement</h2>
                <div className="flex gap-4 mb-4">
                  <button
                    className={`px-4 py-2 rounded-md transition-colors ${
                      signatureType === 'draw'
                        ? 'bg-[#FFB27D] text-black font-medium'
                        : 'bg-[#2A2A2A] text-[#E6E6E6] hover:bg-[#333]'
                    }`}
                    onClick={() => setSignatureType('draw')}
                  >
                    Draw Signature
                  </button>
                  <button
                    className={`px-4 py-2 rounded-md transition-colors ${
                      signatureType === 'type'
                        ? 'bg-[#FFB27D] text-black font-medium'
                        : 'bg-[#2A2A2A] text-[#E6E6E6] hover:bg-[#333]'
                    }`}
                    onClick={() => setSignatureType('type')}
                  >
                    Type Signature
                  </button>
                </div>

                {signatureType === 'draw' ? (
                  <div className="border border-[#444] rounded-md p-2 bg-[#0D0D0D]">
                    <SignatureCanvas
                      ref={signatureRef}
                      canvasProps={{
                        className: 'signature-canvas w-full h-48 rounded-md',
                        style: { background: '#0D0D0D' },
                      }}
                      penColor="#FFFFFF" // White pen, so user sees it on dark background
                    />
                  </div>
                ) : (
                  <input
                    type="text"
                    value={typedSignature}
                    onChange={(e) => setTypedSignature(e.target.value)}
                    placeholder="Type your signature here"
                    className="w-full p-3 border border-[#444] rounded-md
                      bg-[#0D0D0D] text-white focus:outline-none
                      focus:ring-2 focus:ring-[#FFB27D] focus:border-transparent"
                  />
                )}

                <div className="flex gap-3 mt-4">
                  <button
                    onClick={() => setShowSignatureCanvas(false)}
                    className="px-4 py-2 border border-[#444] rounded-md
                      text-[#E6E6E6] bg-transparent hover:bg-[#2A2A2A] transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleClear}
                    className="px-4 py-2 border border-[#444] rounded-md
                      text-[#E6E6E6] bg-[#2A2A2A] hover:bg-[#333] transition-colors"
                  >
                    Clear
                  </button>
                  <button
                    onClick={handleSaveSignature}
                    className="px-4 py-2 rounded-md text-black bg-[#FFB27D]
                      hover:bg-[#FFA164] transition-colors ml-auto"
                  >
                    Sign & Save
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Signatures display */}
          <div className="pt-8 border-t border-[#333333]">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Client Signature */}
              <div>
                <p className="font-medium text-white mb-3">Client Signature</p>
                <div className="h-24 border-b-2 border-[#333333]">
                  {agreement.clientSignature?.signature && (
                    <img
                      src={agreement.clientSignature.signature}
                      alt="Client Signature"
                      className="h-full object-contain"
                    />
                  )}
                </div>
                <p className="text-sm text-[#999999] mt-2">
                  {agreement.clientSignature?.timestamp
                    ? new Date(agreement.clientSignature.timestamp).toLocaleDateString()
                    : 'Date: _____________'}
                </p>
              </div>

              {/* Freelancer Signature */}
              <div>
                <p className="font-medium text-white mb-3">Freelancer Signature</p>
                <div className="h-24 border-b-2 border-[#333333]">
                  {agreement.freelancerSignature?.signature && (
                    <img
                      src={agreement.freelancerSignature.signature}
                      alt="Freelancer Signature"
                      className="h-full object-contain"
                    />
                  )}
                </div>
                <p className="text-sm text-[#999999] mt-2">
                  {agreement.freelancerSignature?.timestamp
                    ? new Date(agreement.freelancerSignature.timestamp).toLocaleDateString()
                    : 'Date: _____________'}
                </p>
              </div>
            </div>
          </div>

          {/* If fully signed */}
          {agreement.status === 'signed' && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-8 p-6 bg-[#1A1A1A] rounded-xl border border-[#333333]"
            >
              <div className="flex items-center gap-4">
                <div className="w-2.5 h-2.5 rounded-full bg-[#FFB27D]" />
                <div>
                  <h3 className="text-lg font-semibold text-white">Agreement Signed</h3>
                  <p className="text-[#999999]">All parties have signed this agreement.</p>
                </div>
              </div>
              <div className="mt-4 space-y-3">
                {agreement.freelancerSignature && (
                  <div className="flex justify-between text-[#999999]">
                    <span>Freelancer Signature</span>
                    <span>{new Date(agreement.freelancerSignature.timestamp).toLocaleDateString()}</span>
                  </div>
                )}
                {agreement.clientSignature && (
                  <div className="flex justify-between text-[#999999]">
                    <span>Client Signature</span>
                    <span>{new Date(agreement.clientSignature.timestamp).toLocaleDateString()}</span>
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </motion.div>
      </div>

      {/* Footer / Branding */}
      <div className="max-w-4xl mx-auto px-4 pb-8">
        <div className="text-center text-sm text-[#666666]">
          <a
            href="https://klorah.app"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#999999] transition-colors"
          >
            Powered by Klorah
          </a>
        </div>
      </div>
    </div>
  )
}