import React from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';

function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-[#1A1A1A] rounded-lg p-8 text-center">
        <h1 className="text-2xl font-medium text-[#FFB27D] mb-4">Oops! Something went wrong</h1>
        <p className="text-[#999999] mb-6">
          {error?.message || "We encountered an unexpected error. Please try again."}
        </p>
        <div className="space-y-4">
          <button
            onClick={() => navigate(-1)}
            className="w-full py-2 px-4 bg-[#FFB27D] text-black rounded-lg hover:bg-[#FFA164] transition-colors"
          >
            Go Back
          </button>
          <button
            onClick={() => navigate('/app/emails')}
            className="w-full py-2 px-4 bg-[#1A1A1A] text-[#FFB27D] border border-[#FFB27D] rounded-lg hover:bg-[#FFB27D] hover:text-black transition-colors"
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorBoundary;
