import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { 
  getAuth, 
  GoogleAuthProvider, 
  signInWithPopup, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut, 
  onAuthStateChanged 
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC5Bg0VqwUulU6n03zQ0Y6XkF5eRGFqEN8",
  authDomain: "klorah-c4dc2.firebaseapp.com",
  projectId: "klorah-c4dc2",
  storageBucket: "klorah-c4dc2.appspot.com",
  messagingSenderId: "532276017665",
  appId: "1:532276017665:web:42b06f78bb5fcc13e7de00",
  measurementId: "G-F1MNVNGFR0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Only initialize analytics in production
let analytics = null;
if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
  analytics = getAnalytics(app);
}

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();

export { 
  app, 
  analytics, 
  auth, 
  db, 
  storage,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  provider
}; 

export default app; 