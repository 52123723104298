import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { getFirestore, addDoc, collection, serverTimestamp, doc, updateDoc, arrayUnion } from 'firebase/firestore'
import { useAuth } from '../contexts/AuthContext'

export default function AgreementModal({ isOpen, onClose, project, client, email }) {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  // Helper function to extract email address
  const extractEmail = (from) => {
    if (!from) return '';
    
    // Try to extract email from <email> format
    const emailMatch = from.match(/<([^>]+)>/) || from.match(/([^\s]+@[^\s]+)/);
    return emailMatch ? emailMatch[1] : from;
  };

  // Initialize form with project, client, or email data if available
  const [form, setForm] = useState({
    client: client?.name || (email ? extractEmail(email.from) : ''),
    scope: project?.projectName || email?.subject || '',
    price: project?.price || '',
    currency: project?.currency || 'USD',
    timeline: project?.dueDate ? project.dueDate.toDate().toISOString().split('T')[0] : '',
    projectId: project?.id || null,
    clientId: client?.id || project?.clientId || null
  })

  // Update form when project, client, or email changes
  useEffect(() => {
    if (project || client || email) {
      setForm(prev => ({
        ...prev,
        client: client?.name || (email ? extractEmail(email.from) : prev.client),
        scope: project?.projectName || email?.subject || prev.scope,
        price: project?.price || prev.price,
        currency: project?.currency || prev.currency,
        timeline: project?.dueDate ? project.dueDate.toDate().toISOString().split('T')[0] : prev.timeline,
        projectId: project?.id || prev.projectId,
        clientId: client?.id || project?.clientId || prev.clientId
      }))
    }
  }, [project, client, email])

  const [isGeneratingAI, setIsGeneratingAI] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  if (!isOpen) return null

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm(prev => ({ ...prev, [name]: value }))
  }

  // Call FastAPI "/generate-contract" with optional fields
  const handleGenerateAI = async () => {
    setIsGeneratingAI(true)
    try {
      // Build a payload with empty strings if user left fields blank
      const payload = {
        freelancer_name: currentUser?.displayName || 'Freelancer',
        client_name: form.client || '',
        scope_of_work: form.scope || '',
        price: form.price || '',
        currency: form.currency,
        due_date: form.timeline || '',
      }

      const response = await fetch('https://klorah-fast-server.onrender.com/api/generate-contract', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      })

      const data = await response.json()

      if (data?.contract_text) {
        // Save directly to Firestore with generated text
        const db = getFirestore()
        const docRef = await addDoc(collection(db, 'agreements'), {
          title: form.client 
            ? `Agreement with ${form.client}` 
            : 'Untitled Agreement',
          text: data.contract_text,
          status: 'draft',
          createdAt: serverTimestamp(),
          userId: currentUser?.uid || null,
          projectId: form.projectId,
          clientId: form.clientId,
          price: form.price,
          currency: form.currency,
          dueDate: form.timeline ? new Date(form.timeline) : null
        })

        // If this is a project-specific agreement, update the project
        if (form.projectId) {
          const projectRef = doc(db, 'projects', form.projectId)
          await updateDoc(projectRef, {
            agreements: arrayUnion(docRef.id)
          })
        }

        setShowSuccess(true)
        toast.success('Agreement created!')

        // Cleanup & navigate to the new agreement
        setTimeout(() => {
          setShowSuccess(false)
          onClose()
          navigate(`/agreement/${docRef.id}`)
        }, 1500)
      } else {
        toast.error('No contract text returned by AI.')
      }
    } catch (err) {
      console.error(err)
      toast.error('Could not generate AI contract.')
    } finally {
      setIsGeneratingAI(false)
    }
  }

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-md flex items-center justify-center p-8 fade-in z-[90]">
      <motion.div
        className="bg-white dark:bg-black rounded-3xl shadow-2xl w-full max-w-xl"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
      >
        {/* Header */}
        <div className="px-8 py-6 border-b border-gray-100 dark:border-gray-900">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-medium">Create Agreement</h2>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-900 rounded-full transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        {/* Body */}
        <div className="px-8 py-6 space-y-6">
          {/* Client */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Client Name</label>
            <input
              type="text"
              name="client"
              placeholder="Enter client name"
              className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
              value={form.client}
              onChange={handleChange}
              readOnly={!!client}
            />
          </div>

          {/* Scope of Work */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Scope of Work</label>
            <input
              type="text"
              name="scope"
              placeholder="Brief description of services"
              className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
              value={form.scope}
              onChange={handleChange}
              readOnly={!!project}
            />
          </div>

          {/* Timeline */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Timeline</label>
            <input
              type="date"
              name="timeline"
              className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
              value={form.timeline}
              onChange={handleChange}
              readOnly={!!project}
            />
          </div>

          {/* Price and Currency */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium">Price</label>
              <input
                type="number"
                name="price"
                placeholder="0.00"
                className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
                value={form.price}
                onChange={handleChange}
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium">Currency</label>
              <select
                name="currency"
                value={form.currency}
                onChange={handleChange}
                className="w-full bg-gray-50 dark:bg-gray-900 rounded-2xl px-4 py-3 border-0 focus:ring-2"
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="CAD">CAD</option>
                <option value="AUD">AUD</option>
              </select>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="px-8 py-6 border-t border-gray-100 dark:border-gray-900 flex justify-end gap-4">
          <button
            onClick={onClose}
            disabled={isGeneratingAI}
            className="px-6 py-3 text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-900 rounded-2xl transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleGenerateAI}
            disabled={isGeneratingAI}
            className={`px-6 py-3 text-sm font-medium rounded-2xl transition-colors ${
              isGeneratingAI
                ? 'bg-gray-100 dark:bg-gray-900 opacity-50 cursor-not-allowed'
                : 'bg-gray-900 dark:bg-white text-white dark:text-black hover:opacity-90'
            }`}
          >
            {isGeneratingAI ? (
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 border-2 border-current border-t-transparent rounded-full animate-spin"></div>
                <span>Generating...</span>
              </div>
            ) : showSuccess ? (
              'Success!'
            ) : (
              'Generate Agreement'
            )}
          </button>
        </div>
      </motion.div>
    </div>
  )
}