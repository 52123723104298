import React, { useEffect, useState } from 'react';
import ComposeEmail from './ComposeEmail';
import EmailService from '../services/EmailService';
import DOMPurify from 'dompurify';

const EmailView = ({
  email,
  aiSummary,
  isAiLoading,
  onSummarizeEmail,
  onBack,
  user,
  setShowInvoiceModal,
  setShowAgreementModal,
  error,
  onRefresh
}) => {
  const [showComposeModal, setShowComposeModal] = useState(false);
  const [replyEmail, setReplyEmail] = useState(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [summary, setSummary] = useState(null);
  const [showRawHtml, setShowRawHtml] = useState(false);

  // Configure DOMPurify to allow safe email-specific tags and attributes
  const purifyConfig = {
    ADD_TAGS: ['style', 'meta'],
    ADD_ATTR: [
      'target',
      'cellpadding',
      'cellspacing',
      'border',
      'align',
      'valign',
      'bgcolor',
      'background'
    ],
    FORBID_TAGS: ['script', 'iframe', 'form', 'input', 'button'],
    FORBID_ATTR: ['onerror', 'onload', 'onclick', 'onmouseover'],
    ALLOW_DATA_ATTR: false
  };

  // Initialize DOMPurify with email-specific configuration
  useEffect(() => {
    DOMPurify.setConfig(purifyConfig);
  }, []);

  useEffect(() => {
    if (email) {
      if (email.body) {
      }
    }
  }, [email]);

  // Clear summary when a new email is selected
  useEffect(() => {
    setSummary(null);
  }, [email]);

  const handleSummarizeEmail = async () => {
    if (!email) return;
    
    setIsGeneratingSummary(true);
    try {
      const emailContent = email.body?.html || email.body?.text || email.content || email.snippet || '';
      const summaryResult = await EmailService.summarizeEmail(emailContent);
      setSummary(summaryResult.summary);
    } catch (error) {
      console.error('Failed to summarize email:', error);
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short'
      });
    } catch {
      return dateString;
    }
  };

  const getSenderEmail = () => {
    if (!email || !email.from) return '';
    const match = email.from.match(/<([^>]+)>/);
    if (match && match[1]) {
      return match[1];
    }
    return email.from;
  };

  const getSenderName = () => {
    if (!email) return '';
    if (email.sender) {
      return email.sender;
    }
    if (email.from) {
      const match = email.from.match(/^([^<]+)</);
      if (match && match[1]) {
        return match[1].trim();
      }
      const emailMatch = email.from.match(/([^@<]+)@/);
      if (emailMatch && emailMatch[1]) {
        return emailMatch[1].toUpperCase();
      }
    }
    return 'Unknown Sender';
  };

  const handleReply = () => {
    if (!email) return;
    if (user && email.id && !email.read) {
      EmailService.markEmailAsRead(user.uid, email.id, email.accountId).catch(() => {});
    }
    setReplyEmail(email);
    setShowComposeModal(true);
  };

  const handleEmailSent = (result) => {
    setShowComposeModal(false);
    setReplyEmail(null);
  };

  const renderEmailContent = () => {
    if (isAiLoading && !aiSummary) {
      return (
        <div className="flex flex-col space-y-4">
          <div className="h-4 bg-gray-200 dark:bg-[#1A1A1A] rounded w-full animate-pulse"></div>
          <div className="h-4 bg-gray-200 dark:bg-[#1A1A1A] rounded w-5/6 animate-pulse"></div>
          <div className="h-4 bg-gray-200 dark:bg-[#1A1A1A] rounded w-4/5 animate-pulse"></div>
          <div className="h-4 bg-gray-200 dark:bg-[#1A1A1A] rounded w-full animate-pulse"></div>
          <div className="h-4 bg-gray-200 dark:bg-[#1A1A1A] rounded w-3/4 animate-pulse"></div>
        </div>
      );
    }
    if (!email) {
      return <p className="text-gray-500 dark:text-[#666666]">No email selected.</p>;
    }
    if (error) {
      return (
        <div className="p-4 rounded-lg bg-red-50 dark:bg-red-900/20 border border-red-100 dark:border-red-500/30">
          <p className="text-red-600 dark:text-red-400">{error}</p>
        </div>
      );
    }
    
    let htmlContent = '';
    
    // Get HTML content from various possible sources
    if (email.content) {
      if (typeof email.content === 'string') {
        htmlContent = email.content;
      } else if (email.content.html) {
        htmlContent = email.content.html;
      } else if (email.content.text) {
        // Convert plain text to HTML with basic formatting
        htmlContent = email.content.text
          .split('\n')
          .map(line => {
            // Preserve quote formatting
            if (line.startsWith('>')) {
              return `<blockquote class="pl-4 border-l-4 border-gray-200 dark:border-gray-700 text-gray-600 dark:text-gray-400">${line.slice(1)}</blockquote>`;
            }
            // Convert URLs to links
            line = line.replace(/(https?:\/\/[^\s<>"]+|www\.[^\s<>"]+)/g, url => {
              const href = url.startsWith('www.') ? `https://${url}` : url;
              return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            });
            return `<div>${line}</div>`;
          })
          .join('');
      }
    } else if (email.body) {
      htmlContent = email.body.html || email.body.text || '';
    }

    // If no HTML content found, show snippet
    if (!htmlContent && email.snippet) {
      return <p className="text-black dark:text-[#CCCCCC]">{email.snippet}</p>;
    }

    // Add dark mode wrapper to HTML content
    const wrappedHtmlContent = `
      <div class="light-mode dark:text-white/90">
        ${htmlContent}
        <style>
          /* Dark mode styles */
          @media (prefers-color-scheme: dark) {
            .light-mode {
              background-color: transparent !important;
            }
            .light-mode * {
              background-color: transparent !important;
              color: inherit !important;
            }
            .light-mode a {
              color: #60A5FA !important;
            }
            .light-mode img {
              opacity: 0.8;
              filter: brightness(0.8) contrast(1.2);
            }
            .light-mode [style*="background"] {
              background-color: rgba(255, 255, 255, 0.05) !important;
            }
            .light-mode [style*="border"] {
              border-color: rgba(255, 255, 255, 0.1) !important;
            }
            .light-mode table {
              border-color: rgba(255, 255, 255, 0.1) !important;
            }
            .light-mode td, .light-mode th {
              border-color: rgba(255, 255, 255, 0.1) !important;
            }
            .light-mode hr {
              border-color: rgba(255, 255, 255, 0.1) !important;
            }
            .light-mode blockquote {
              border-color: rgba(255, 255, 255, 0.1) !important;
              color: rgba(255, 255, 255, 0.7) !important;
            }
          }
        </style>
      </div>
    `;

    // Sanitize HTML content
    const sanitizedHtml = DOMPurify.sanitize(wrappedHtmlContent, {
      ...purifyConfig,
      ADD_TAGS: [...purifyConfig.ADD_TAGS, 'link'],
      ADD_ATTR: [...purifyConfig.ADD_ATTR, 'media']
    });

    if (showRawHtml) {
      return (
        <div className="relative">
          <pre className="whitespace-pre-wrap break-words text-xs font-mono bg-gray-50 dark:bg-gray-900/20 p-4 rounded-lg overflow-x-auto">
            {htmlContent}
          </pre>
        </div>
      );
    }

    return (
      <div className="relative">
        <div className="flex justify-end mb-4">
          <button
            onClick={() => setShowRawHtml(!showRawHtml)}
            className="text-xs text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
          >
            {showRawHtml ? 'Show Rendered Email' : 'Show Raw HTML'}
          </button>
        </div>
        <div 
          dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
          className="email-content relative
            prose prose-sm max-w-none
            text-black dark:text-white/90
            [&_*]:max-w-full
            [&_img]:inline-block
            [&_table]:table-auto
            [&_td]:align-top
            preserve-whitespace
            overflow-x-auto
            dark:prose-invert
            dark:prose-a:text-blue-400
            dark:prose-strong:text-white
            dark:prose-headings:text-white
            [&_a]:text-blue-600 dark:[&_a]:text-blue-400
            [&_a:hover]:text-blue-800 dark:[&_a:hover]:text-blue-300
            [&_blockquote]:border-gray-200 dark:[&_blockquote]:border-gray-700
            [&_hr]:border-gray-200 dark:[&_hr]:border-gray-700
            [&_pre]:bg-gray-50 dark:[&_pre]:bg-gray-900
            [&_code]:text-gray-800 dark:[&_code]:text-gray-200
            [&_table]:border-gray-200 dark:[&_table]:border-gray-700
            [&_th]:border-gray-200 dark:[&_th]:border-gray-700
            [&_td]:border-gray-200 dark:[&_td]:border-gray-700
            [&_img]:dark:opacity-80 [&_img]:dark:contrast-more"
        />
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col bg-white dark:bg-black border-none">
      <div className="flex-none sticky top-0 z-10 flex items-center justify-between p-4 md:p-6 bg-white dark:bg-black border-none">
        <div className="flex items-center space-x-3">
          <button onClick={onBack} className="md:hidden text-gray-500 dark:text-[#666666] hover:text-gray-900 dark:hover:text-white transition-all">
            <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <span className="text-sm font-medium hidden md:block text-gray-500 dark:text-[#666666]">Back to inbox</span>
        </div>
        <div className="flex items-center">
          {email && !error && (
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowInvoiceModal(true)}
                className="hidden md:flex items-center space-x-2 text-gray-500 dark:text-[#999999] hover:text-gray-900 dark:hover:text-white"
                disabled={!email}
              >
                <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                <span>Invoice</span>
              </button>
              <button
                onClick={() => setShowAgreementModal(true)}
                className="hidden md:flex items-center space-x-2 text-gray-500 dark:text-[#999999] hover:text-gray-900 dark:hover:text-white"
                disabled={!email}
              >
                <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                <span>Agreement</span>
              </button>
              <button
                onClick={handleSummarizeEmail}
                className="hidden md:flex items-center space-x-2 text-gray-500 dark:text-[#999999] hover:text-gray-900 dark:hover:text-white"
                disabled={!email || isGeneratingSummary || Boolean(error)}
              >
                <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                <span>Summarize</span>
              </button>
              
              {/* Mobile buttons */}
              <div className="md:hidden flex items-center">
                <button
                  onClick={() => setShowInvoiceModal(true)}
                  className="p-2 text-gray-500 dark:text-[#999999] hover:text-gray-900 dark:hover:text-white"
                  disabled={!email || Boolean(error)}
                >
                  <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </button>
                <button
                  onClick={() => setShowAgreementModal(true)}
                  className="p-2 text-gray-500 dark:text-[#999999] hover:text-gray-900 dark:hover:text-white"
                  disabled={!email || Boolean(error)}
                >
                  <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </button>
                <button
                  onClick={handleSummarizeEmail}
                  className="p-2 text-gray-500 dark:text-[#999999] hover:text-gray-900 dark:hover:text-white"
                  disabled={!email || isGeneratingSummary || Boolean(error)}
                >
                  <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {email ? (
        <div className="flex-1 relative">
          <div className="absolute inset-0 p-3 sm:p-4 md:p-6 overflow-y-auto hide-scrollbar">
            <div className="max-w-4xl mx-auto">
              <h1 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4 sm:mb-6 text-gray-900 dark:text-white/90">
                {email.subject}
              </h1>
              <div className="mb-6">
                <div className="flex items-start justify-between gap-4">
                  <div className="flex items-start gap-3">
                    <div className="w-8 h-8 bg-gradient-to-b from-[#FFB27D]/80 to-[#FF9D5C]/80 rounded-full flex items-center justify-center text-sm font-medium text-black/80">
                      {getSenderName().charAt(0)}
                    </div>
                    <div className="flex flex-col min-w-0">
                      <div className="flex items-baseline gap-1.5">
                        <h2 className="text-base font-medium text-gray-900 dark:text-white truncate">
                          {getSenderName()}
                        </h2>
                        <button 
                          className="group flex items-center gap-1 hover:bg-gray-100 dark:hover:bg-[#1A1A1A] rounded px-1 py-0.5 transition-colors"
                          onClick={() => {/* Toggle recipient details */}}
                        >
                          <span className="text-xs text-gray-500 dark:text-[#666666] group-hover:text-gray-900 dark:group-hover:text-white transition-colors">
                            Details
                          </span>
                          <svg className="h-3 w-3 text-gray-400 dark:text-[#666666] group-hover:text-gray-600 dark:group-hover:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        </button>
                      </div>
                      <div className="text-xs text-gray-500 dark:text-[#666666] truncate">
                        to me
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 text-xs text-gray-500 dark:text-[#666666]">
                    <time>{formatDate(email.date)}</time>
                    <button 
                      className="hover:bg-gray-100 dark:hover:bg-[#1A1A1A] rounded p-1 transition-colors"
                      onClick={() => {/* Toggle more actions */}}
                    >
                      <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                      </svg>
                    </button>
                  </div>
                </div>
                {/* Recipient details - hidden by default */}
                <div className="hidden mt-3 ml-11 p-3 space-y-2 bg-gray-50 dark:bg-[#1A1A1A] rounded-lg text-xs">
                  <div className="flex items-baseline gap-2">
                    <span className="w-12 text-gray-500 dark:text-[#666666]">From:</span>
                    <div className="flex flex-col">
                      <span className="text-gray-900 dark:text-white">{getSenderName()}</span>
                      <span className="text-gray-500 dark:text-[#666666]">{`${getSenderEmail()}`}</span>
                    </div>
                  </div>
                  <div className="flex items-baseline gap-2">
                    <span className="w-12 text-gray-500 dark:text-[#666666]">To:</span>
                    <span className="text-gray-900 dark:text-white">me</span>
                  </div>
                  {email.cc && (
                    <div className="flex items-baseline gap-2">
                      <span className="w-12 text-gray-500 dark:text-[#666666]">Cc:</span>
                      <span className="text-gray-900 dark:text-white">{email.cc}</span>
                    </div>
                  )}
                  <div className="flex items-baseline gap-2">
                    <span className="w-12 text-gray-500 dark:text-[#666666]">Date:</span>
                    <span className="text-gray-900 dark:text-white">{formatDate(email.date)}</span>
                  </div>
                </div>
              </div>
              {!error && (
                <div className="flex flex-wrap items-center gap-1.5 sm:gap-2 mb-4 sm:mb-6 md:mb-8">
                  <button
                    onClick={handleReply}
                    className="flex items-center gap-1.5 sm:gap-2 px-2.5 sm:px-3 md:px-4 py-1.5 sm:py-2 md:py-2.5 bg-gray-100 dark:bg-[#1A1A1A] text-gray-900 dark:text-white text-xs sm:text-sm font-medium rounded-lg sm:rounded-xl hover:bg-gray-200 dark:hover:bg-[#222] transition-all ml-auto"
                    disabled={Boolean(error)}
                  >
                    <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                    </svg>
                    <span>Reply</span>
                  </button>
                  {/* <button
                    onClick={() => {}}
                    className="flex items-center gap-1.5 sm:gap-2 px-2.5 sm:px-3 md:px-4 py-1.5 sm:py-2 md:py-2.5 bg-gray-100 dark:bg-[#1A1A1A] text-gray-900 dark:text-white text-xs sm:text-sm font-medium rounded-lg sm:rounded-xl hover:bg-gray-200 dark:hover:bg-[#222] transition-all"
                    disabled={Boolean(error)}
                  >
                    <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                    </svg>
                    <span>Archive</span>
                  </button>
                  <button
                    onClick={() => {}}
                    className="flex items-center gap-1.5 sm:gap-2 px-2.5 sm:px-3 md:px-4 py-1.5 sm:py-2 md:py-2.5 bg-gray-100 dark:bg-[#1A1A1A] text-gray-900 dark:text-white text-xs sm:text-sm font-medium rounded-lg sm:rounded-xl hover:bg-gray-200 dark:hover:bg-[#222] transition-all"
                    disabled={Boolean(error)}
                  >
                    <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    <span>Delete</span>
                  </button> */}
                </div>
              )}
              {(summary || aiSummary) && !error && (
                <div className="mb-6 md:mb-8 p-6 rounded-2xl border border-gray-100 dark:border-[#2A2A2A] backdrop-blur-sm bg-gray-50/40 dark:bg-[#1A1A1A]/40">
                  <div className="flex items-center gap-3 mb-4">
                    <div className="h-px flex-1 bg-gradient-to-r from-transparent via-[#FFB27D]/20 to-transparent"></div>
                    <h3 className="text-sm font-medium text-[#FFB27D] tracking-wider uppercase">AI Summary</h3>
                    <div className="h-px flex-1 bg-gradient-to-r from-transparent via-[#FFB27D]/20 to-transparent"></div>
                  </div>
                  <div className="whitespace-pre-line text-sm text-gray-800 dark:text-white/80 leading-relaxed">{summary || aiSummary}</div>
                </div>
              )}
              {isGeneratingSummary && !summary && !error && (
                <div className="mb-6 md:mb-8 p-6 rounded-2xl border border-gray-100 dark:border-[#2A2A2A] backdrop-blur-sm bg-gray-50/40 dark:bg-[#1A1A1A]/40">
                  <div className="flex items-center gap-3 mb-4">
                    <div className="h-px flex-1 bg-gradient-to-r from-transparent via-[#FFB27D]/20 to-transparent"></div>
                    <h3 className="text-sm font-medium text-[#FFB27D] tracking-wider uppercase">AI Summary</h3>
                    <div className="h-px flex-1 bg-gradient-to-r from-transparent via-[#FFB27D]/20 to-transparent"></div>
                  </div>
                  <div className="animate-pulse space-y-3">
                    <div className="h-3 bg-gray-200 dark:bg-[#2A2A2A] rounded-full w-full"></div>
                    <div className="h-3 bg-gray-200 dark:bg-[#2A2A2A] rounded-full w-5/6"></div>
                    <div className="h-3 bg-gray-200 dark:bg-[#2A2A2A] rounded-full w-4/5"></div>
                  </div>
                </div>
              )}
              <div className="text-sm text-black dark:text-white/70 prose prose-gray dark:prose-invert max-w-none prose-headings:text-black prose-p:text-black prose-strong:text-black prose-a:text-[#FFB27D] dark:prose-headings:text-white dark:prose-p:text-white/70 dark:prose-strong:text-white [&_*]:text-black dark:[&_*]:text-white/70">{renderEmailContent()}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center text-gray-800 dark:text-[#666666]">
          <div className="text-center">
            <div className="mb-3">
              <svg className="h-12 w-12 mx-auto text-gray-400 dark:text-[#333333]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </div>
            <p className="text-lg">Select an email to view</p>
          </div>
        </div>
      )}
      <ComposeEmail
        isOpen={showComposeModal}
        onClose={() => {
          setShowComposeModal(false);
          setReplyEmail(null);
        }}
        replyToEmail={replyEmail}
        user={user}
        onEmailSent={handleEmailSent}
      />
    </div>
  );
};

export default EmailView;